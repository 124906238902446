import React from "react";
import Axios from "axios";
import log from "Lib/Log/log";
import Layout from "Layout/Layout";
import withContext from "Lib/WithContext/withContext";
import { Grid, Button, Tab, Tabs, Paper } from "@material-ui/core";
import { OrganizationRoleContext } from "OrganizationRole/OrganizationRoleProvider";
import { withStyles } from "@material-ui/core/styles";
import AppNavigation from "AppNavigation/AppNavigation";
import OpportunityModel from "../OpportunityModel";
import OpportunitiesTable from "./OpportunitiesTable";
import { Link as RouterLink } from "react-router-dom";

const tabs = [
  OpportunityModel.STATUS_PUBLISHED,
  OpportunityModel.STATUS_DRAFT,
  OpportunityModel.STATUS_ARCHIVED,
  OpportunityModel.STATUS_UNPUBLISHED,
];

class OrganizationOpportunities extends React.Component {
  state = {
    loading: true,
    opportunities: [],
    total: 0,
    tabIndex: 0,
    page: 0,
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    const { tabIndex } = this.state;
    const tab = this.getActiveTab(tabIndex);

    return Axios.get(
      `/api/tb/opportunities/organizations/${this.props.organization.uid}?status=${tab.toLowerCase()}&page=${
        this.state.page
      }`,
      { headers: this.props.getUserRoleAuthHeaders() },
    ).then((response) => {
      log("fetchData", response.data);


      const opportunities = response.data.opportunities.map((o) => 
        OpportunityModel.fromJSON(o)
      );
      const total = response.data.total;

      this.setState({ opportunities, total, loading: false });
    });
  };

  getActiveTab = (tabIndex) => {
    return tabs[tabIndex];
  };

  onClickTab = (_, tabIndex) => {
    this.setState({ tabIndex, page: 0 }, this.fetchData);
  };

  onChangePage = (page) => {
    this.setState({ page }, this.fetchData);
  };

  render() {
    return (
      <Layout title="Opportunities">
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <div style={{ padding: 16, textAlign: "right" }}>
              <Button
                to="/opportunities/new"
                component={RouterLink}
                variant="outlined"
                color="primary"
                size="small"
              >
                Create an Opportunity
              </Button>
            </div>
          </Grid>
          <Grid item sm={12}>
            <Paper>
              <Tabs value={this.state.tabIndex} onChange={this.onClickTab}>
                {tabs.map((t) => (
                  <Tab key={t} label={t} />
                ))}
              </Tabs>

              <OpportunitiesTable
                loading={this.state.loading}
                opportunities={this.state.opportunities}
                total={this.state.total}
                changePage={this.onChangePage}
                page={this.state.page}
              />
            </Paper>
          </Grid>
        </Grid>
      </Layout>
    );
  }
}

const styles = (theme) => ({
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: "center",
  },
});

export default withStyles(styles)(
  withContext(OrganizationRoleContext, OrganizationOpportunities),
);
