import React from "react";
import Axios from "axios";
import log from "Lib/Log/log";
import withContext from "Lib/WithContext/withContext";
import { CandidateRoleContext } from "CandidateRole/CandidateRoleProvider";
import { withStyles } from "@material-ui/core/styles";
import AppNavigation from "AppNavigation/AppNavigation";
import OpportunityModel from "../../Opportunities/OpportunityModel";
import OrganizationModel from "OrganizationRole/OrganizationModel";
import ApplicationModel from "Applications/ApplicationModel";
import { Grid, Button, Typography, LinearProgress } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ApplicationCard from "Applications/CandidateApplications/ApplicationCard";
import Alert from "Alert/Alert";

class DashboardHome extends React.Component {
  state = {
    loading: true,
    applications: [],
    total: 0,
    tabIndex: 0,
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    const { uid } = this.props.match.params;
    console.log("uid", uid);

    return Axios.get(`/api/tb/applications`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchData", response.data);

      const applications = response.data.applications.map((a) =>
        ApplicationModel.fromJSON(a),
      );

      this.setState({
        applications,
        total: response.data.total,
        loading: false,
      });
    });
  };

  render() {
    const { applications, organization, opportunity, loading } = this.state;
    const { classes } = this.props;

    if (loading) {
      // return "Loading...";
      return <LinearProgress color="primary" />;
    }

    const inProgress = applications.filter((a) => !a.applied);
    const applied = applications.filter((a) => a.applied && !a.withdrew);

    return (
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h6">In Progress</Typography>
        </Grid>
        {inProgress.length ? (
          inProgress.map((a, i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <ApplicationCard application={a} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Alert info>
              <strong>No In-progress Applications</strong>
              <p>
                <RouterLink to="/application_plans">Discover</RouterLink>{" "}
                current opportunities.
              </p>
            </Alert>
          </Grid>
        )}

        <Grid item sm={12}>
          <Typography variant="h6">Applied</Typography>
        </Grid>
        {applied.length ? (
          applied.map((a, i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <ApplicationCard application={a} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Alert warning>
              <Typography variant="caption">
                No applications submitted yet
              </Typography>
            </Alert>
          </Grid>
        )}
      </Grid>
    );
  }
}

const styles = (theme) => ({
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: "center",
  },
});

export default withStyles(styles)(
  withContext(CandidateRoleContext, DashboardHome),
);
