import React from "react";
import Axios from "axios";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  withStyles,
  Grid,
  Divider,
  LinearProgress,
} from "@material-ui/core";
import { Breadcrumbs } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import * as Ability from "Ability/Ability";
import AppNavigation from "AppNavigation/AppNavigation";
import ImageUpload from "Upload/ImageUpload";
import log from "Lib/Log/log";
import withContext from "Lib/WithContext/withContext";
import MaxWidth from "Lib/MaxWidth/MaxWidth";
import { SnackbarContext } from "Lib/Snackbar/SnackbarProvider";
import { UserRoleContext } from "UserRoles/UserRoleProvider";

import { fetchOrganizationProfile } from "./api";

import "./OrganizationProfile.css";

class OrganizationProfileDetail extends React.Component {
  state = {
    loading: true,
    organization: {},
    organizationProfile: {},
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    this.fetchOrganizationProfile();
  };

  fetchOrganizationProfile = () => {
    const { organizationUid } = this.props.match.params;
    return fetchOrganizationProfile(
      this.props.getUserRoleAuthHeaders(),
      organizationUid,
    ).then(({ organization, organizationProfile }) =>
      this.setState({
        organization,
        organizationProfile,
        loading: false,
      }),
    );
  };

  render() {
    if (this.state.loading) {
      // return "Loading...";
      return <LinearProgress color="primary" />;
    }

    const { classes } = this.props;
    const { organization, organizationProfile } = this.state;

    return (
      <AppNavigation title="Organization Profile">
        <MaxWidth maxWidth={1200} style={{ padding: 16 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Breadcrumbs>
                <Typography color="textPrimary">
                  Organization Profile
                </Typography>
              </Breadcrumbs>
            </Grid>

            <Grid item xs={12}>
              <Card className={classes.card}>
                <div style={{ position: "relative" }}>
                  <CardMedia
                    className={classes.media}
                    image={organizationProfile.bannerImageUrl}
                  >
                    <div className={classes.logoBox}>
                      <ImageUpload
                        id="profile-image"
                        src={organizationProfile.profileImageUrl}
                        className={classes.logoBoxImage}
                        disabled={true}
                      />
                    </div>
                  </CardMedia>
                </div>

                <CardContent style={{ marginTop: 20 }}>
                  <Typography gutterBottom variant="h2">
                    {organization.name}
                  </Typography>

                  {["tagline", "headcount", "industry", "headquarters"].map(
                    (attribute) => (
                      <React.Fragment key={attribute}>
                        <Divider />
                        <CardContent>
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                gutterBottom
                                className={classes.label}
                              >
                                {attribute.slice(0, 1).toUpperCase() +
                                  attribute.slice(1)}
                              </Typography>

                              <Typography
                                variant="body2"
                                className={classes.tagline}
                              >
                                {organizationProfile[attribute]}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </React.Fragment>
                    ),
                  )}
                  <Divider />
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          gutterBottom
                          className={classes.label}
                        >
                          Description
                        </Typography>

                        <Typography
                          variant="body2"
                          className={classes.tagline}
                          dangerouslySetInnerHTML={{
                            __html: organizationProfile.description,
                          }}
                        ></Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardContent>
              </Card>
            </Grid>
            {this.props.currentUserRole.roleHasAbility(
              Ability.EDIT_ORGANIZATION_PROFILE,
            ) ? (
              <Grid item sm={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  component={RouterLink}
                  to={`/organizations/${organization.uid}/profile/edit`}
                >
                  Edit
                </Button>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </MaxWidth>
      </AppNavigation>
    );
  }
}

const styles = {
  card: {
    overflow: "inherit",
  },
  media: {
    height: 200,
  },
  logoBox: {
    position: "absolute",
    height: 120,
    width: 120,
    backgroundColor: "white",
    bottom: -20,
    left: 50,
    padding: 5,
  },
  logoBoxImage: {
    width: "100%",
  },
  alsoAppliedCard: {
    marginTop: 32,
  },
  taglineTextField: {
    outline: "none",
    border: "none",
  },
};

export default withStyles(styles)(
  withContext(UserRoleContext, SnackbarContext, OrganizationProfileDetail),
);
