import { Typography, withStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableToolbar from "Lib/TableToolbar/TableToolbar";
import TableBody from "@material-ui/core/TableBody";
import format from "Lib/Format/format";
import log from "Lib/Log/log";
import React from "react";

class OpportunitiesTable extends React.Component {
  constructor(props) {
    super(props);
    this.tableHeadRef = React.createRef();
  }

  render() {
    const {
      total,
      page,
      opportunities,
      loading,
      rowsPerPage,
      rowsPerPageOptions,
    changePage,
      changeRowsPerPage,
      classes,
    } = this.props;

    return (
      <>
        <TableToolbar title={`${format.commas(total)} Opportunities`} />
        {loading ? <LinearProgress /> : null}
        {!loading && !opportunities.length ? (
          <Typography variant="caption" className={classes.noData}>
            No Opportunities.
          </Typography>
        ) : (
          <div className={classes.tableWrapper}>
            <Table
              className={classes.table}
              style={{ opacity: loading ? 0.5 : 1 }}
            >
              <TableHead id="opportunities-table-head">
                <TableRow>
                  <TableCell className={classes.cell}>Title</TableCell>
                  <TableCell className={classes.cell}>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {opportunities.map((o) => {
                  return (
                    <TableRow key={o.id}>
                      <TableCell>
                        <Link
                          component={RouterLink}
                          to={`/opportunities/${o.uid}`}
                        >
                          {o.title}
                        </Link>
                        <br />
                        <small>{o.organizationName}</small>
                      </TableCell>
                      <TableCell>
                        {o.startedCount || 0} started,&nbsp; 
                        {o.appliedCount || 0} applied,&nbsp;
                        {o.withdrewCount || 0} withdrew
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={total || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangeRowsPerPage={(e) =>
                      changeRowsPerPage(e.target.value)
                    }
                    onChangePage={(e, p) => {
                      changePage(p);
                    }}
                    rowsPerPageOptions={rowsPerPageOptions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        )}
      </>
    );
  }
}

const styles = (theme) => ({
  noData: {
    textAlign: "center",
    padding: 25,
    color: "#777",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

OpportunitiesTable.defaultProps = {
  total: 0,
  page: 0,
  opportunities: [],
  loading: true,
  rowsPerPage: 50,
  rowsPerPageOptions: [50],
  changePage: (p) => {
    log("page", p);
  },
  changeRowsPerPage: (rowsPerPage) => {
    log("rows per page", rowsPerPage);
  },
};

export default withStyles(styles)(OpportunitiesTable);
