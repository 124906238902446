import { withStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Button,
  CardContent,
  Typography,
  CardActions,
  Chip,
} from "@material-ui/core";
import { Clear as ClearIcon } from "@material-ui/icons";
import format from "Lib/Format/format";
import log from "Lib/Log/log";
import React from "react";

class ApplicationCard extends React.Component {
  render() {
    const { application, classes } = this.props;

    return (
      <>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Avatar className={classes.avatar} color="primary">
                {application.organizationName[0].toUpperCase()}
              </Avatar>
            }
            action={
              <IconButton aria-label="Remove from My Applications">
                <ClearIcon />
              </IconButton>
            }
            title={application.organizationName}
          />
          <CardContent>
            <Typography variant="h5">{application.title}</Typography>
            {/* <Typography variant="body1" gutterBottom>
              {application.description.slice(0, 100) + "..."}
            </Typography> */}

            <Typography variant="body2" color="textSecondary">
              Application Plan
            </Typography>
            <Chip
              label={application.applicationPlanName}
              color="primary"
              style={{ marginTop: 5, marginBottom: 5 }}
            />

            {/* <Typography variant="body2" color="textSecondary">
              Posted on {application.publishedAt.toLocaleString()}
            </Typography> */}

            <Typography variant="body1">Priority Deadline</Typography>
            {/* <Typography variant="body2">
              {application.priorityDeadlineAt.toLocaleString()}
            </Typography> */}

            <Button
              color="secondary"
              variant="contained"
              component={RouterLink}
              to={`/applications/${application.uid}`}
            >
              {application.applied ? "View" : "Apply"}
            </Button>
          </CardContent>
          <CardActions
            className={classes.actions}
            disableActionSpacing
          ></CardActions>
        </Card>
      </>
    );
  }
}

const styles = (theme) => ({
  noData: {
    textAlign: "center",
    padding: 25,
    color: "#777",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

ApplicationCard.defaultProps = {
  total: 0,
  page: 0,
  opportunities: [],
  loading: true,
  rowsPerPage: 50,
  rowsPerPageOptions: [50],
  changePage: (p) => {
    log("page", p);
  },
  changeRowsPerPage: (rowsPerPage) => {
    log("rows per page", rowsPerPage);
  },
};

export default withStyles(styles)(ApplicationCard);
