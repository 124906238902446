import React from "react";
import classnames from "classnames";
import { Toolbar, Typography, withStyles } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles/colorManipulator";

const tableToolbar = (props) => {
  const { title, caption, numSelected, classes, actions } = props;

  return (
    <Toolbar
      className={classnames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {title}
          </Typography>
        )}
        {caption ? <Typography variant="caption">{caption}</Typography> : null}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>{actions}</div>
    </Toolbar>
  );
};

tableToolbar.defaultProps = {
  title: "",
  caption: "",
  numSelected: 0,
  actions: null,
};

const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    // flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "1 1 auto",
  },
});

export default withStyles(styles)(tableToolbar);
