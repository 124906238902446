import React from "react";
import Axios from "axios";
import withContext from "Lib/WithContext/withContext";
import log from "Lib/Log/log";

import { LinearProgress } from "@material-ui/core";

import { UserRoleContext } from "UserRoles/UserRoleProvider";
import CandidateModel from "./CandidateModel";
import CandidateEducationModel from "./CandidateEducationModel";

const DEFAULT_STATE = {
  candidateRoleContextLoading: true,
  candidate: {},
  candidateEducations: [],
  plans: [],
  subscriptions: [],
};

export const CandidateRoleContext = React.createContext(DEFAULT_STATE);

// When you switch roles, we need to load the properties of the role you just signed in as...

class CandidateRoleProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchData();
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.currentUserRole.id !== this.props.currentUserRole.id) {
      this.setState({ candidateRoleContextLoading: true }, this.fetchData);
    }
  };

  fetchData = () => {
    this.fetchCandidate()
      .then(this.fetchCandidateEducations)
      .then(() => this.setState({ candidateRoleContextLoading: false }));
  };

  fetchCandidate = () => {
    return Axios.get(`/api/tb/candidates`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchCandidate response", response);

      this.setState({
        candidate: CandidateModel.fromJSON(response.data.candidate),
      });
    });
  };

  fetchCandidateEducations = () => {
    return Axios.get(`/api/tb/candidate_educations`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchCandidateEducations response", response);
      this.setState({
        candidateEducations: response.data.candidate_educations.map((e) =>
          CandidateEducationModel.fromJSON(e),
        ),
      });
    });
  };

  putCandidateEducations = (candidateEducations) => {
    log("putCandidateEducations", candidateEducations);
    return Axios.put(
      `/api/tb/candidate_educations`,
      { candidate_educations: candidateEducations },
      { headers: this.props.getUserRoleAuthHeaders() },
    ).then((response) => {
      log("putCandidateEducations response", response);
      this.setState({
        candidateEducations: response.data.candidate_educations.map((e) =>
          CandidateEducationModel.fromJSON(e),
        ),
      });
    });
  };

  putCandidateResume = (updatedResume) => {
    log("putCandidateResume", updatedResume);

    const formData = new FormData();
    formData.append("file", updatedResume);

    return Axios.post(
      `/api/tb/candidates/resume?name=${updatedResume.name}`,
      formData,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        "Content-Type": "multipart/form-data",
      },
    ).then((response) => {
      log("putCandidateResume response", response);
      // const candidate = CandidateModel.fromJSON(response.data.candidate);
      // this.setState({ candidate });
    });
  };

  putCandidateAttributes = (updatedAttributes) => {
    log("putCandidateAttributes", updatedAttributes);
    const candidate = new CandidateModel(updatedAttributes);

    return Axios.put(
      `/api/tb/candidates`,
      { candidate },
      { headers: this.props.getUserRoleAuthHeaders() },
    ).then((response) => {
      log("putCandidateAttributes response", response);
      const candidate = CandidateModel.fromJSON(response.data.candidate);
      this.setState({ candidate });
    });
  };

  render() {
    const { candidateRoleContextLoading } = this.state;

    if (candidateRoleContextLoading) {
      return <LinearProgress />;
    }

    return (
      <CandidateRoleContext.Provider
        value={{
          candidate: this.state.candidate,
          candidateEducations: this.state.candidateEducations,
          putCandidateAttributes: this.putCandidateAttributes,
          putCandidateResume: this.putCandidateResume,
          putCandidateEducations: this.putCandidateEducations,
          findOrCreateCustomer: this.findOrCreateCustomer,
          updatePaymentMethod: this.updatePaymentMethod,
          findOrCreateSubscription: this.findOrCreateSubscription,
          currentUserRole: this.props.currentUserRole,
          getUserRoleAuthHeaders: this.props.getUserRoleAuthHeaders,
          downloadFile: this.props.downloadFile,
        }}
      >
        {this.props.children}
      </CandidateRoleContext.Provider>
    );
  }
}

export default withContext(UserRoleContext, CandidateRoleProvider);
