export default function (location) {
  if (!location) {
    return { city: "", state: "" };
  }

  const lastIndexOfComma = location.lastIndexOf(",");

  if (lastIndexOfComma == -1) {
    if (location.length == 2 && location === location.toUpperCase()) {
      return { city: "", state: location };
    }

    return { city: location, state: "" };
  }

  return {
    city: location.slice(0, lastIndexOfComma),
    state: location.slice(lastIndexOfComma + 1).trim(),
  };
}
