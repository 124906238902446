import {
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  TextField,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";

import Location from "Lib/Location";
import { validateField } from "Lib/FormHelpers/formHelpers";
import {
  TECHNICAL_CATEGORIES,
  STEP_DESCRIPTION,
} from "Opportunities/OrganizationOpportunities/EditOpportunity/constants";
import {
  StepBase,
  DateTextField,
} from "Opportunities/OrganizationOpportunities/EditOpportunity/components";
import { WORKING_ARRANGEMENTS, WORKING_ARRANGEMENT_ONSITE } from "Opportunities/OpportunityModel";
import { toHeaderCase } from "Lib/JsConvertCase/JsConvertCase";


export const EDUCATION_LEVELS = [
  "HIGH_SCHOOL",
  "BOOTCAMP",
  "BACHELORS",
  "MASTERS",
  "PHD",
];

export default function StepBasic(props) {
  return (
    <>
      <StepBase
        goNextStep={() =>
          props.setNextStep(STEP_DESCRIPTION, {
            title: {
              presence: { message: `Please enter title of opportunity` },
            },
            applicationPlanId: {
              presence: {
                message: `Please select an application plan.`,
              },
            },
            technicalCategory: {
              presence: {
                message: `Please select a technical category.`,
              },
            },
            priorityDeadlineAt: {
              presence: {
                message: `Please enter a priority deadline`,
              },
            },
            headcount: {
              presence: {
                message: `Please set the headcount.`,
              },
            },
            workingArragement: {
              presence: {
                message: `Please select a working arragement.`,
              },
            },
          })
        }
      >

      <Grid item sm={12}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Opportunity Title</FormLabel>
            <FormHelperText>Candidates will see this name</FormHelperText>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              value={props.title}
              onChange={(e) => props.onChange({ title: e.target.value })}
            />
          </FormControl>
        </Grid>

        <Grid item sm={12}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Application Plan</FormLabel>
            <FormHelperText></FormHelperText>
            <TextField
              fullWidth
              select
              SelectProps={{ native: true }}
              variant="outlined"
              margin="dense"
              value={props.applicationPlanId}
              onChange={(e) =>
                props.onChange({ applicationPlanId: e.target.value })
              }
            >
              <option key={0} value=""/>
              {props.applicationPlans.map((applicationPlan) => (
                <option key={applicationPlan.id} value={applicationPlan.id}>
                  {applicationPlan.name}
                </option>
              ))}
            </TextField>
          </FormControl>
        </Grid>



        <Grid item sm={12}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Technical Category</FormLabel>
            <FormHelperText></FormHelperText>
            <TextField
              fullWidth
              select
              SelectProps={{ native: true }}
              variant="outlined"
              margin="dense"
              value={props.technicalCategory}
              onChange={(e) =>
                props.onChange({ technicalCategory: e.target.value })
              }
            >
              <option key={0} value=""/>
              {
                TECHNICAL_CATEGORIES.map(l => (
                  <option key={l} value={l}>{ toHeaderCase(l) }</option>
                ))
              }
            </TextField>
          </FormControl>
        </Grid>



        <Grid item sm={12}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Priority Deadline</FormLabel>
            <DateTextField
              fullWidth
              variant="outlined"
              margin="dense"
              value={props.priorityDeadlineAt}
              onChange={(priorityDeadlineAt) =>
                props.onChange({ priorityDeadlineAt })
              }
            />
          </FormControl>
        </Grid>

        <Grid item sm={12}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              Opportunity Headcount (Optional)
            </FormLabel>
            <FormHelperText>
              How many roles are you trying to fill?
            </FormHelperText>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              type="number"
              value={props.headcount}
              onChange={(e) => props.onChange({ headcount: e.target.value })}
            />
          </FormControl>
        </Grid>


        <Grid item sm={12}>
        <FormControl component="fieldset" fullWidth>
        <FormHelperText>
              What kind of working arrangement?
            </FormHelperText>

           <TextField
              fullWidth
              select
              SelectProps={{ native: true }}
              variant="outlined"
              margin="dense"
              value={  props.workingArragement}
              onChange={(e) =>
                props.onChange({ workingArragement: e.target.value })
              }
            >
              <option key={0} value=""/>
              {
                WORKING_ARRANGEMENTS.map(l => (
                  <option key={l} value={l}>{ toHeaderCase(l) }</option>
                ))
              }
            </TextField>
          </FormControl>

            {props.workingArragement == WORKING_ARRANGEMENT_ONSITE ? (
              <>
                <FormControl component="fieldset" fullWidth>
                  <Location
                    value={props.locations}
                    onChange={(location) =>
                      props.onChange({ locations: location })
                    }
                  />
                </FormControl>
              </>
            ) : (
              ""
            )}
          </Grid>


        <Grid item sm={12}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Education Level</FormLabel>
            <FormHelperText></FormHelperText>
            <TextField
              fullWidth
              select
              SelectProps={{ native: true }}
              variant="outlined"
              margin="dense"
              value={props.educationLevel}
              onChange={(e) =>
                props.onChange({ educationLevel: e.target.value })
              }
            >
              <option value="ANY" selected={true}>
                Any
              </option>
              {
                EDUCATION_LEVELS.map(l => (
                  <option key={l} value={l}>{ toHeaderCase(l) }</option>
                ))
              }
            </TextField>
          </FormControl>
        </Grid>

        <Grid item sm={12}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Salary (Optional)</FormLabel>
            <FormHelperText>
              What is the salary range for this opportunity?
            </FormHelperText>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="$106k – $160k"
              value={props.salary}
              onChange={(e) => props.onChange({ salary: e.target.value })}
            />
          </FormControl>
        </Grid>
      </StepBase>
    </>
  );
}
