import React, { useEffect, useState } from "react";
import {
  CardContent,
  Grid,
  Checkbox,
  withStyles,
  Button,
  Paper,
  Typography,
  LinearProgress,
} from "@material-ui/core";


import OpportunityModel from "Opportunities/OpportunityModel";

import { fetchOrganizationProfile } from "Settings/Organizations/OrganizationProfile/api";

import OrganizationOpportunityDetailContent, {
  styles,
} from "Opportunities/OrganizationOpportunities/OrganizationOpportunityDetailContent";

import { STEP_ASSESSMENT } from "Opportunities/OrganizationOpportunities/EditOpportunity/constants";
import { ChangeStepButtons } from "Opportunities/OrganizationOpportunities/EditOpportunity/components";


function StepReview({
  opportunity,
  classes,
  getUserRoleAuthHeaders,
  organization,
  ...props
}) {
  const [organizationProfile, setOrganizationProfile] = useState();

  useEffect(
    () =>
      fetchOrganizationProfile(getUserRoleAuthHeaders(), organization.uid).then(
        ({ organizationProfile }) =>
          setOrganizationProfile(organizationProfile),
      ),
    [getUserRoleAuthHeaders, organization],
  );

  if (!organizationProfile) return <LinearProgress />;

  const additionalFields = [
    ["Technical Category", opportunity.technicalCategory],
    ["Headcount", opportunity.headcount],
    [
      "Is a technical assessment required?",
      <Checkbox
        checked={opportunity.isAssessmentRequired}
        readOnly={true}
        style={{ padding: 0 }}
      />,
    ],
    ...(opportunity.isAssessmentRequired
      ? [
          [
            "Minimum Code Signal Score",
            OpportunityModel.SIGNAL_SCORES.filter(
              ([value]) => value == opportunity.minimumCodeSignalScore,
            )[0][1],
          ],
        ]
      : []),
    [
      "Is an additional technical assessment required?",
      <Checkbox
        checked={opportunity.isAdditionalAssessmentRequired}
        readOnly={true}
        style={{ padding: 0 }}
      />,
    ],
    ...(opportunity.isAdditionalAssessmentRequired
      ? [
          [
            "Additional Technical Assessment Vendor",
            opportunity.additionalAssessmentVendor,
          ],
          ["Assessment URL", opportunity.additionalAssessmentUrl],
        ]
      : []),
  ];

  return (
    <>
      <Typography style={{ marginBottom: 5 }}>Review:</Typography>
      <OrganizationOpportunityDetailContent
        applicationPlan={
          props.applicationPlans.filter(
            (applicationPlan) => applicationPlan.id === props.applicationPlanId,
          )[0]
        }
        organization={organization}
        organizationProfile={organizationProfile}
        opportunity={opportunity}
      ></OrganizationOpportunityDetailContent>

      <Paper style={{ marginTop: 16, marginBottom: 16, padding: 16 }}>
        <Grid item sm={12}>
          <CardContent>
            <Grid container spacing={2}>
              {additionalFields.map(([label, value], index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={4}>
                    {label}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {value}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </CardContent>
        </Grid>
      </Paper>
      <ChangeStepButtons
        goBackStep={() => props.onChange({ step: STEP_ASSESSMENT })}
        nextButton={
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={props.onSave}
            style={{ display: "block", marginLeft: "auto" }}
          >
            {props.uid ? "Update" : "Create"}
          </Button>
        }
      />
    </>
  );
}

export default withStyles(styles)(StepReview);
