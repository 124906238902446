import React from "react";
import withContext from "Lib/WithContext/withContext";
import { ThemeContext } from "Lib/Theme/ThemeProvider";
import { CandidateRoleContext } from "../CandidateRole/CandidateRoleProvider";
import { Button } from "@material-ui/core";

// appDrawerLinks
import AllInboxIcon from "@material-ui/icons/AllInbox";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExploreIcon from "@material-ui/icons/Explore";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import WorkIcon from "@material-ui/icons/Work";
import FavoriteIcon from "@material-ui/icons/Favorite";
import BusinessIcon from "@material-ui/icons/Business";
import ContactsIcon from "@material-ui/icons/Contacts";
import HistoryIcon from "@material-ui/icons/History";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import history from "history.js";
import appBarMenuItems from "./appBarMenuItems";
import * as Ability from "Ability/Ability";
import userRoleNavItem from "./userRoleNavItem";
import AppBarAndDrawer from "./AppBarAndDrawer/AppBarAndDrawer";

class CandidateNavigation extends React.PureComponent {
  render() {
    const { currentUserRole } = this.props;

    let appDrawerLinks = [userRoleNavItem(this.props), { divider: true }];

    appDrawerLinks.push({
      primaryText: "Dashboard",
      path: "/dashboard",
      icon: <DashboardIcon />,
    });

    // appDrawerLinks.push({
    //   primaryText: "Application Plans",
    //   path: "/application_plans",
    //   icon: <ExploreIcon />,
    // });

    appDrawerLinks.push({
      primaryText: "Opportunities",
      path: "/opportunities",
      icon: <ExploreIcon />,
    });



    appDrawerLinks.push({
      primaryText: "Companies",
      path: "/companies",
      icon: <BusinessIcon />,
    });

    // appDrawerLinks.push({ primaryText: 'My Applications', path: '/applications', icon: <AllInboxIcon />, exactPathMatch: true });

    // appDrawerLinks.push({ primaryText: 'My Companies', path: '/my-companies', icon: <FavoriteIcon />, exactPathMatch: true });

    // appDrawerLinks.push({ primaryText: 'Opportunties', path: '/opportunities', icon: <WorkIcon />, exactPathMatch: true });

    // appDrawerLinks.push({ primaryText: 'Companies', path: '/companies', icon: <BusinessIcon />, exactPathMatch: true });

    appDrawerLinks = [
      ...appDrawerLinks,
      {
        primaryText: "Candidate Profile",
        path: "/candidate-profile",
        icon: <ContactsIcon />,
      },
      { divider: true },
    ];

    appDrawerLinks.push({
      primaryText: "Account",
      path: "/account",
      icon: <LockOpenIcon />,
    });

    appDrawerLinks.push({
      primaryText: "Activity",
      path: "/activity",
      icon: <HistoryIcon />,
    });

    appDrawerLinks.push({
      primaryText: "Sign out",
      path: "/sign_out",
      icon: <ExitToAppIcon />,
    });

    let barItems;
    if (this.props.currentUserRole.id) {
      barItems = appBarMenuItems;
    } else {
      barItems = [
        {
          component: (
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              style={{ marginRight: 5 }}
              onClick={() => history.push("/sign_in")}
            >
              Sign In
            </Button>
          ),
        },
        {
          component: (
            <Button
              key="sign_up"
              color="primary"
              variant="contained"
              size="small"
              style={{ marginRight: 5 }}
              onClick={() => history.push("/sign_up")}
            >
              Sign Up
            </Button>
          ),
        },
      ];
    }

    return (
      <AppBarAndDrawer
        title={this.props.title}
        appDrawerLinks={appDrawerLinks}
        appBarMenuItems={barItems}
        inAnonymousMode={!this.props.currentUserRole.id}
      >
        {this.props.children}
      </AppBarAndDrawer>
    );
  }
}

export default withContext(
  ThemeContext,
  CandidateRoleContext,
  CandidateNavigation,
);
