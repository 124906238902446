export const EDIT_APPLICATION_PLANS = "EDIT_APPLICATION_PLANS";
export const VIEW_APPLICATION_PLANS = "VIEW_APPLICATION_PLANS";
export const EDIT_CANDIDATE_USERS = "EDIT_CANDIDATE_USERS";
export const VIEW_CANDIDATE_USERS = "VIEW_CANDIDATE_USERS";
export const VIEW_ORGANIZATION_ACTIVITY = "VIEW_ORGANIZATION_ACTIVITY";
export const IMPERSONATE = "IMPERSONATE";
export const SEARCH_USERS = "SEARCH_USERS";
export const EDIT_ORGANIZATION_USERS = "EDIT_ORGANIZATION_USERS";
export const VIEW_ORGANIZATION_USERS = "VIEW_ORGANIZATION_USERS";
export const EDIT_OPPORTUNITIES = "EDIT_OPPORTUNITIES";
export const VIEW_OPPORTUNITIES = "VIEW_OPPORTUNITIES";
export const EDIT_ORGANIZATION_PROFILE = "EDIT_ORGANIZATION_PROFILE";
export const VIEW_ORGANIZATION_PROFILE = "VIEW_ORGANIZATION_PROFILE";
export const EDIT_FAMILY_ORGANIZATIONS = "EDIT_FAMILY_ORGANIZATIONS";
export const VIEW_FAMILY_ORGANIZATIONS = "VIEW_FAMILY_ORGANIZATIONS";
export const EDIT_CANDIDATE_APPLICATIONS = "EDIT_CANDIDATE_APPLICATIONS";
export const VIEW_CANDIDATE_APPLICATIONS = "VIEW_CANDIDATE_APPLICATIONS";
export const VIEW_ORGANIZATION_ACTIVITIES = "VIEW_ORGANIZATION_ACTIVITIES";
