import React from "react";
import { Redirect } from "react-router-dom";
import withContext from "Lib/WithContext/withContext";
import log from "Lib/Log/log";
import { SnackbarContext } from "Lib/Snackbar/SnackbarProvider";
import { UserRoleContext } from "../UserRoles/UserRoleProvider";

const userRoleGuard = (props) => {
  log("userRoleGuard", props);
  log("isCandidateRole", props.currentUserRole.isCandidateRole());
  log("isOrganizationRole", props.currentUserRole.isOrganizationRole());
  const isAuthorized = props.authorizeRole(props.currentUserRole);
  log("userRoleGuard", isAuthorized);

  if (isAuthorized) {
    log("userRoleGuard Authorized");
    return props.children;
  } else {
    log("userRoleGuard Unauthorized");
    props.showSnackbar("Unauthorized.");
    return <Redirect to="/" />;
  }
};

export default withContext(UserRoleContext, SnackbarContext, userRoleGuard);
