import React, { useEffect, useState } from "react";
import Axios from "axios";
import {
  Grid,
  Typography,
  LinearProgress,
  Paper,
  Divider,
  List,
  withStyles,
} from "@material-ui/core";

import OpportunityModel from "Opportunities/OpportunityModel";
import OpportunityCard from "Opportunities/CandidateOpportunities/OpportunityCard";

function FeaturedOpportunities({
  applicationPlans,
  getUserRoleAuthHeaders,
  currentUserRole,
  classes,
}) {
  const [opportunities, setOpportunities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!applicationPlans.length) {
      setOpportunities([]);
      setLoading(false);
      return;
    }

    Axios.get("/api/tb/opportunities/published", {
      params: {
        limit: 3,
        application_plan_uids: applicationPlans.map(
          (applicationPlan) => applicationPlan.uid,
        ),
      },
      headers: getUserRoleAuthHeaders ? getUserRoleAuthHeaders() : {},
    }).then((response) => {
      setLoading(false);
      setOpportunities(
        (response.data.opportunities || []).map((p) =>
          OpportunityModel.fromJSON(p),
        ),
      );
    });
  }, [applicationPlans, getUserRoleAuthHeaders]);

  if (loading) {
    return <LinearProgress />;
  }

  if (!opportunities.length) {
    return (
      <Grid container>
        <Grid item xs={12} style={{ width: "100%" }}>
          <Grid container style={{ width: "100%" }} justifyContent="center">
            <Grid item>
              <Typography variant="body2" className={classes.noData}>
                There are no opportunities here yet
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5">Featured Opportunities</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <List>
            {opportunities.map((opportunity, index) => (
              <React.Fragment key={index}>
                <OpportunityCard
                  opportunity={opportunity}
                  currentUserRole={currentUserRole}
                />
                { index + 1 < opportunities.length && <Divider /> }
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
}

const styles = (theme) => ({
  noData: {
    padding: 50,
    backgroundColor: "#f9f9f9",
    borderRadius: 5,
    textAlign: "center",
  },
});

export default withStyles(styles)(FeaturedOpportunities);
