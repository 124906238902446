import { withStyles } from "@material-ui/core";
import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  CardContent,
  Typography,
  CardActions,
} from "@material-ui/core";
import {
  Room as RoomIcon,
  Business as BusinessIcon,
  People as PeopleIcon,
} from "@material-ui/icons";
import {
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,
} from "@material-ui/icons";
import format from "Lib/Format/format";
import log from "Lib/Log/log";
import React from "react";
import { UserRoleContext } from "UserRoles/UserRoleProvider";
import withContext from "Lib/WithContext/withContext";
import { Link as RouterLink } from "react-router-dom";
import SmallChip from "SmallChip/SmallChip";

class OrganizationCard extends React.Component {
  onClickFavorite = () => {
    this.props.onClickFavorite(this.props.organization);
  };

  render() {
    const { organization, isFavorite, classes } = this.props;

    return (
      <>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Avatar
                className={classes.avatar}
                color="primary"
                src={organization.profileImageUrl}
              />
            }
            action={
              <IconButton
                aria-label="Add to favorites"
                onClick={this.onClickFavorite}
              >
                {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>
            }
            title={
              <Typography variant="h5">
                <RouterLink
                  to={`/companies/${organization.uid}`}
                  className={classes.title}
                >
                  {organization.name}
                </RouterLink>
              </Typography>
            }
          />
          <CardContent className={classes.cardContent}>
            <div className={classes.chips}>
              <SmallChip
                icon={<RoomIcon />}
                label={organization.headquarters}
              />
              <SmallChip
                icon={<BusinessIcon />}
                label={organization.industry}
              />
              <SmallChip
                icon={<PeopleIcon />}
                label={
                  organization.headcount.toLocaleString("en-US") + " employees"
                }
              />
            </div>

            <Typography variant="body1" color="textSecondary" gutterBottom>
              {organization.tagline}
            </Typography>
            {/*
            <Typography variant="body2" color="textSecondary">
              Application Plan
            </Typography>
            <Chip
              label={opportunity.applicationPlanName}
              color="primary"
              style={{ marginTop: 5, marginBottom: 5 }}
            />

            <Typography variant="body2" color="textSecondary">
              Posted on {opportunity.publishedAt.toLocaleString()}
            </Typography>

            <Typography variant="body1">Priority Deadline</Typography>
            <Typography variant="body2">
              {opportunity.priorityDeadlineAt.toLocaleString()}
            </Typography>

            <Button
              color="secondary"
              variant="contained"
              onClick={this.onClickFavorite}
              // component={RouterLink}
              // to={`/opportunities/${opportunity.uid}/application`}
            >
              {opportunity.applicationUid
                ? opportunity.applicationApplied
                  ? "View"
                  : "Continue"
                : "Apply"}
            </Button> */}
          </CardContent>
          <CardActions
            className={classes.actions}
            disableActionSpacing
          ></CardActions>
        </Card>
      </>
    );
  }
}

const styles = (theme) => ({
  card: {
    minHeight: 270,
    height: "100%",
  },
  cardContent: {
    paddingTop: 0,
  },
  title: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  chips: {
    marginBottom: 10,
  },
  noData: {
    textAlign: "center",
    padding: 25,
    color: "#777",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

OrganizationCard.defaultProps = {
  total: 0,
  page: 0,
  opportunities: [],
  loading: true,
  rowsPerPage: 50,
  rowsPerPageOptions: [50],
  changePage: (p) => {
    log("page", p);
  },
  changeRowsPerPage: (rowsPerPage) => {
    log("rows per page", rowsPerPage);
  },
};

export default withStyles(styles)(
  withContext(UserRoleContext, OrganizationCard),
);
