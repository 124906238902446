import { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Breadcrumbs } from "@material-ui/core";
import Axios from "axios";

import Layout from "Layout/Layout";
import log from "Lib/Log/log";
import { formatDate } from "Lib/Format/format";
import MaxWidth from "Lib/MaxWidth/MaxWidth";
import withContext from "Lib/WithContext/withContext";
import PromiseButton from "Lib/PromiseButton/PromiseButton";
import { SnackbarContext } from "Lib/Snackbar/SnackbarProvider";
import { OrganizationRoleContext } from "OrganizationRole/OrganizationRoleProvider";
import CandidateModel from "CandidateRole/CandidateModel";
import ApplicationPlanModel from "ApplicationPlans/ApplicationPlanModel";

import { ApplicationPlanDetailForm, CancelButton } from "./hooks";

function AddOrganizationApplicationPlanDetail(props) {
  const [applicationPlan, setApplicationPlan] = useState(
    new ApplicationPlanModel({
      startedAt: formatDate(Date()),
      endedAt: formatDate(Date()),
      jobType: CandidateModel.POSITION_TYPES[0],
    }),
  );

  const onAdd = async () => {
    log("AddOrganizationApplicationPlanDetail.onAdd", applicationPlan);

    const response = await Axios.post(
      `/api/tb/application_plans/`,
      { application_plan: applicationPlan },
      { headers: props.getUserRoleAuthHeaders() },
    );

    props.showSnackbar("Application Plan created");
    window.location.href = `/application_plans/${response.data.application_plan.uid}/edit`;
  };

  return (
    <Layout>
      <MaxWidth maxWidth={1200}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs>
              <Typography color="textPrimary">
                Add Organization Application Plan
              </Typography>
            </Breadcrumbs>
          </Grid>

          <ApplicationPlanDetailForm
            applicationPlan={applicationPlan}
            setApplicationPlan={setApplicationPlan}
          />

          <Grid item sm={12}>
            <CancelButton returnTo={"/application_plans"} />

            <PromiseButton
              buttonProps={{ variant: "contained", color: "primary", size: "small" }}
              onProcess={onAdd}
            >
              Create
            </PromiseButton>
          </Grid>
        </Grid>
      </MaxWidth>
    </Layout>
  );
}

export default withContext(
  OrganizationRoleContext,
  SnackbarContext,
  AddOrganizationApplicationPlanDetail,
);
