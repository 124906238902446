import React from "react";
import withContext from "Lib/WithContext/withContext";
import { Grid, Typography, Link, Button, LinearProgress } from "@material-ui/core";
import { Breadcrumbs } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { OrganizationRoleContext } from "OrganizationRole/OrganizationRoleProvider";
import { withStyles } from "@material-ui/core/styles";
import Layout from "Layout/Layout";
import CandidateOpportunities from "Opportunities/CandidateOpportunities/CandidateOpportunities";
import joinHookToClassComponent from "Lib/ReactUtils/joinHookToClassComponent";
import { FetchApplicationPlan } from "./hooks";

class OrganizationApplicationPlanDetail extends React.Component {
  state = {
    loading: true,
    applicationPlan: [],
    opportunities: [],
    total: 0,
    rowsPerPage: 10,
    page: 0,
    tabIndex: 0,
    query: "",
    orderBy: "published_at",
  };

  render() {
    if (this.props.fetchApplicationPlan.isLoading) {
      // return "Loading...";
      return <LinearProgress color="primary" />;
    }

    const { classes } = this.props;
    const { applicationPlan } = this.props.fetchApplicationPlan;

    return (
      <Layout>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs style={{ marginBottom: 15 }}>
              <Link
                color="inherit"
                component={RouterLink}
                to="/application_plans"
              >
                Application Plans
              </Link>
              <Typography color="textPrimary">
                {applicationPlan.name}
              </Typography>
            </Breadcrumbs>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              {applicationPlan.name}
            </Typography>
            <Typography variant="body2">
              {applicationPlan.description}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid item sm={12}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                component={RouterLink}
                to={`/application_plans/${applicationPlan.uid}/edit`}
              >
                Edit
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <CandidateOpportunities applicationPlanUid={applicationPlan.uid} />
          </Grid>
        </Grid>
      </Layout>
    );
  }
}

const styles = (theme) => ({
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: "center",
  },
  noOpportunities: {
    padding: 50,
    backgroundColor: "#f9f9f9",
    borderRadius: 5,
  },
});

OrganizationApplicationPlanDetail.defaultProps = {
  showFilters: true,
};

export default withStyles(styles)(
  withContext(
    OrganizationRoleContext,
    joinHookToClassComponent(OrganizationApplicationPlanDetail, {
      fetchApplicationPlan: FetchApplicationPlan,
    }),
  ),
);
