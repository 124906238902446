import React from "react";
import withContext from "Lib/WithContext/withContext";

import { UserRoleContext } from "UserRoles/UserRoleProvider";
import Layout from "Layout/Layout";
import OrganizationUsers from "./OrganizationUsers/OrganizationUsers";

const users = (props) => (
  <Layout title="Users">
    <OrganizationUsers />
  </Layout>
);

export default withContext(UserRoleContext, users);
