import Footer from "Footer/Footer";
import React from "react";
import AppNavigation from "AppNavigation/AppNavigation";
import MaxWidth from "Lib/MaxWidth/MaxWidth";

export default (props) => {
  window.document.title = props.title
    ? `${props.title} | Talentboard`
    : "Talentboard";

  return (
    <AppNavigation title={props.title}>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div className="grid-wrapper" style={{ flex: 1 }}>
          <MaxWidth maxWidth={1200}>{props.children}</MaxWidth>
        </div>

        <Footer />
      </div>
    </AppNavigation>
  );
};
