import { withStyles } from "@material-ui/core";
import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Button,
  CardContent,
  Typography,
  CardActions,
  Chip,
} from "@material-ui/core";
import { Add as AddIcon, MoreVert } from "@material-ui/icons";
import {
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,
} from "@material-ui/icons";
import format from "Lib/Format/format";
import log from "Lib/Log/log";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { UserRoleContext } from "UserRoles/UserRoleProvider";
import withContext from "Lib/WithContext/withContext";

class CandidateOrganizationCard extends React.Component {
  render() {
    const { organization, isFavorite, classes } = this.props;

    return (
      <>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Avatar
                className={classes.avatar}
                color="primary"
                src={organization.profileImageUrl}
              />
            }
            action={
              <IconButton
                aria-label="Add to favorites"
                onClick={() => this.props.onClickFavorite(organization)}
              >
                <FavoriteIcon />
              </IconButton>
            }
            title={
              <RouterLink to={`/companies/${organization.uid}`}>
                {organization.name}
              </RouterLink>
            }
          />
          {/* <CardContent>
            <Typography variant="h5">{opportunity.title}</Typography>
            <Typography variant="body1" gutterBottom>
              {opportunity.description.slice(0, 100) + "..."}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              Application Plan
            </Typography>
            <Chip
              label={opportunity.applicationPlanName}
              color="primary"
              style={{ marginTop: 5, marginBottom: 5 }}
            />

            <Typography variant="body2" color="textSecondary">
              Posted on {opportunity.publishedAt.toLocaleString()}
            </Typography>

            <Typography variant="body1">Priority Deadline</Typography>
            <Typography variant="body2">
              {opportunity.priorityDeadlineAt.toLocaleString()}
            </Typography>

            <Button
              color="secondary"
              variant="contained"
              onClick={this.onClickFavorite}
              // component={RouterLink}
              // to={`/opportunities/${opportunity.uid}/application`}
            >
              {opportunity.applicationUid
                ? opportunity.applicationApplied
                  ? "View"
                  : "Continue"
                : "Apply"}
            </Button>
          </CardContent>
          <CardActions
            className={classes.actions}
            disableActionSpacing
          ></CardActions> */}
        </Card>
      </>
    );
  }
}

const styles = (theme) => ({
  card: {
    height: 200,
  },
  noData: {
    textAlign: "center",
    padding: 25,
    color: "#777",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

CandidateOrganizationCard.defaultProps = {
  total: 0,
  page: 0,
  opportunities: [],
  loading: true,
  rowsPerPage: 50,
  rowsPerPageOptions: [50],
  changePage: (p) => {
    log("page", p);
  },
  changeRowsPerPage: (rowsPerPage) => {
    log("rows per page", rowsPerPage);
  },
};

export default withStyles(styles)(CandidateOrganizationCard);
