import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Axios from "axios";
import { Link, Route } from "react-router-dom";
import withContext from "Lib/WithContext/withContext";
import log from "Lib/Log/log";
import { SnackbarContext } from "Lib/Snackbar/SnackbarProvider";
import TableToolbar from "Lib/TableToolbar/TableToolbar";
// Context
import { UserRoleContext } from "UserRoles/UserRoleProvider";
// Material
import { withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Tooltip,
  Typography,
  LinearProgress,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import SmallTablePagination from "Lib/SmallTablePagination/SmallTablePagination";
import { Remove } from "@material-ui/icons";
import { Add, Search, Clear, Link as LinkIcon } from "@material-ui/icons";

import * as Ability from "Ability/Ability";
import { PersonAdd } from "@material-ui/icons";
import NewUserInvitationDialog from "./NewUserInvitationDialog/NewUserInvitationDialog";
import UserInvitationModel from "../UserInvitationModel";
import CompanyModel from "Settings/Companies/CompanyModel";
import OrganizationModel from "Settings/Organizations/OrganizationModel";
import RoleModel from "Settings/Roles/RoleModel";
import InvitationLinkDialog from "./InvitationLinkDialog/InvitationLinkDialog";

import history from "history.js";

class UserInvitationTable extends React.Component {
  state = {
    userInvitations: [],
    page: 0,
    rowsPerPage: 10,
    count: 0,
    loading: true,
    refreshing: false,
    confirmDialogOpen: false,
    invitationToDelete: null,
    query: "",
    linkDialogOpen: false,
    focusedInvitation: "",
  };

  componentDidMount = () => {
    this.fetchUserInvitations();
  };

  componentDidUpdate = (newProps) => {
    if (this.props.roleTypeId !== newProps.roleTypeId) {
      this.fetchUserInvitations();
    }
  };

  onClickClear = () => {
    this.setState(
      { query: "", refreshing: true, loading: true, page: 0 },
      this.fetchUserInvitations,
    );
  };

  onSubmitSearch = (e) => {
    e.preventDefault();
    this.setState(
      { refreshing: true, loading: true, page: 0 },
      this.fetchUserInvitations,
    );
  };

  fetchUserInvitations = () => {
    log("fetchUserInvitations");
    const { roleTypePath, roleTypeId, showDescendantsInfo } = this.props;
    log("Show descendants", showDescendantsInfo);
    const { rowsPerPage, page, query } = this.state;

    return Axios.get(
      `/api/core/user_invitations/${roleTypePath}/${roleTypeId}`,
      {
        params: {
          limit: rowsPerPage,
          offset: rowsPerPage * page,
          is_pending: true,
          include_descendants: showDescendantsInfo
            ? showDescendantsInfo
            : undefined,
          query: query ? query : undefined,
        },
        headers: this.props.getUserRoleAuthHeaders(),
      },
    ).then((response) => {
      log("fetchUserInvitations response", response);
      const userInvitations = response.data.user_invitations.map((i) =>
        UserInvitationModel.fromJSON(i),
      );

      this.setState({
        userInvitations,
        count: response.data.count,
        loading: false,
        refreshing: false,
      });
    });
  };

  deleteInvitation = () => {
    const { invitationToDelete } = this.state;
    log("deleteInvitation", invitationToDelete);
    Axios.delete(`/api/core/user_invitations/${invitationToDelete.id}`, {
      headers: this.props.getUserRoleAuthHeaders(),
    })
      .then((response) => {
        log("deleteInvitation response", response);
        this.setState({ confirmDialogOpen: false }, this.fetchUserInvitations);
        this.props.showSnackbar("Invitation deleted.");
      })
      .catch((error) => {
        log("deleteInvitation error", error);
        this.props.showSnackbar("Something went wrong.");
      });
  };

  listItem = (invitation, canEdit) => {
    const roleTypeName = invitation.roleTypeName
      ? invitation.roleTypeName + ` -`
      : "";

    return (
      <ListItem
        key={invitation.id}
        name={`invitation_user-${invitation.email}`}
      >
        <ListItemText
          primary={invitation.email}
          secondary={
            <>
              {`${roleTypeName}${invitation.roleName}`}
              <br />
              <small>{`Invited ${moment(invitation.createdAt).format(
                "MMMM Do YYYY, h:mm:ss a",
              )}`}</small>
            </>
          }
        />
        <ListItemSecondaryAction>
          <Tooltip title="View Invitation Link">
            <IconButton
              onClick={() =>
                this.setState({
                  linkDialogOpen: true,
                  focusedInvitation: invitation,
                })
              }
            >
              <LinkIcon />
            </IconButton>
          </Tooltip>

          {canEdit ? (
            <Tooltip title="Delete Invitation">
              <IconButton onClick={() => this.confirmDelete(invitation)}>
                <Remove />
              </IconButton>
            </Tooltip>
          ) : null}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  changeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage, refreshing: true }, this.fetchUserInvitations);
  };

  changePage = (page) => {
    this.setState({ page, refreshing: true }, this.fetchUserInvitations);
  };

  confirmDelete = (invitationToDelete) => {
    this.setState({ invitationToDelete, confirmDialogOpen: true });
  };

  closeConfirmDialog = () => {
    this.setState({ confirmDialogOpen: false, invitationToDelete: null });
  };

  confirmDialog = () => (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      open={this.state.confirmDialogOpen}
    >
      <DialogTitle>Delete Invitation</DialogTitle>
      <DialogContent>
        Are you sure you want to delete this invitation?
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => this.setState({ confirmDialogOpen: false })}
          color="primary"
        >
          Cancel
        </Button>
        <Button onClick={this.deleteInvitation} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );

  onInviteUser = () => {
    this.fetchUserInvitations();
    this.closeNewUserInvitationDialog();
  };

  closeNewUserInvitationDialog = () => {
    const { pathname } = window.location;
    const to = pathname.replace("/user_invitations/new", "");
    history.push(to);
  };

  render() {
    const { classes, currentUserRole, roleTypeName, showDescendantsInfo } =
      this.props;

    const canEdit = currentUserRole.roleHasAtLeastOneAbility(
      Ability.EDIT_ORGANIZATION_USERS,
    );

    const { pathname } = window.location;

    const caption = showDescendantsInfo ? (
      <>
        Pending invitations for{" "}
        <span className={classes.primary}>{this.props.roleTypeName}</span> and
        suborganizations.
      </>
    ) : (
      <>
        Pending invitations for{" "}
        <span className={classes.primary}>{this.props.roleTypeName}.</span>
      </>
    );

    return (
      <>
        {this.confirmDialog()}

        <Route
          exact
          path={this.props.newInvitationPath}
          render={(props) =>
            this.state.loading ? null : (
              <NewUserInvitationDialog
                open={true}
                roleType={this.props.roleType}
                roleTypeId={this.props.roleTypeId}
                roleTypePath={this.props.roleTypePath}
                onInviteUser={this.onInviteUser}
                onClose={this.closeNewUserInvitationDialog}
              />
            )
          }
        />

        <Paper>
          <TableToolbar
            title="User Invitations"
            caption={caption}
            actions={
              canEdit ? (
                <Tooltip
                  title={`Invite a new user to ${this.props.roleTypeName}`}
                >
                  <IconButton
                    component={Link}
                    to={`${pathname}/user_invitations/new`}
                    color="secondary"
                    variant="contained"
                    name="Add user"
                  >
                    <PersonAdd />
                  </IconButton>
                </Tooltip>
              ) : null
            }
          />
          {this.state.loading ? <LinearProgress color="primary" /> : null}

          <form onSubmit={this.onSubmitSearch}>
            <TextField
              placeholder="Search User Invitations..."
              value={this.state.query}
              onChange={(e) => this.setState({ query: e.target.value })}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    className={classes.searchIcon}
                    position="start"
                  >
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: this.state.query ? (
                  <InputAdornment position="end">
                    <IconButton onClick={this.onClickClear}>
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
              inputProps={{
                className: classes.search,
              }}
              fullWidth
            />
            <button style={{ display: "none" }} />
          </form>

          {!this.state.loading && !this.state.userInvitations.length ? (
            <Typography variant="caption" paragraph className={classes.noData}>
              No pending user invitations.
            </Typography>
          ) : (
            <>
              <List style={{ opacity: this.state.refreshing ? 0.5 : 1 }}>
                {this.state.userInvitations.map((i) =>
                  this.listItem(i, canEdit),
                )}
              </List>
              <Divider />
              <SmallTablePagination
                count={this.state.count || 0}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangeRowsPerPage={(e) =>
                  this.changeRowsPerPage(e.target.value)
                }
                onChangePage={(e, p) => this.changePage(p)}
                rowsPerPageOptions={[5, 10, 25, 50]}
              />
            </>
          )}
        </Paper>

        <InvitationLinkDialog
          invitation={this.state.focusedInvitation}
          open={this.state.linkDialogOpen}
          onClose={() => this.setState({ linkDialogOpen: false })}
        />
      </>
    );
  }
}

UserInvitationTable.propTypes = {
  roleType: PropTypes.string.isRequired,
  roleTypeId: PropTypes.number.isRequired,
  roleTypeName: PropTypes.string.isRequired,
};

UserInvitationTable.defaultProps = {
  showDescendantsInfo: false,
};

const styles = (theme) => ({
  noData: {
    textAlign: "center",
    padding: 25,
    color: "#777",
  },
  primary: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  search: {
    paddingTop: 10,
    paddingBottom: 14,
  },
  searchIcon: {
    marginLeft: 24,
    color: "#A7A7A7",
  },
});

export default withStyles(styles)(
  withContext(SnackbarContext, UserRoleContext, UserInvitationTable),
);
