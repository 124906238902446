import React from "react";
import UserTable from "../../UserTable/UserTable";

class OrganizationUserCard extends React.Component {
  render() {
    const { organization, showDescendantsInfo } = this.props;

    return (
      <>
        <UserTable
          userRolesUrl={`/api/core/organizations/${organization.id}/user_roles`}
          roleTypeId={organization.id}
          roleTypeName={organization.name || ""}
          showDescendantsInfo={showDescendantsInfo}
        />
      </>
    );
  }
}

OrganizationUserCard.defaultProps = {
  showDescendantsInfo: false,
};

export default OrganizationUserCard;
