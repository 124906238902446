import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Paper, Typography, Divider, Link, LinearProgress, withStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import withContext from "Lib/WithContext/withContext";
import ApplicationModel from "Applications/ApplicationModel";
import { OrganizationRoleContext } from "OrganizationRole/OrganizationRoleProvider";
import CandidateModel from "CandidateRole/CandidateModel";

function RecentApplicationCandidates({ classes, getUserRoleAuthHeaders }) {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(
    () =>
      Axios.get("/api/tb/applications/recent/candidates", {
        headers: getUserRoleAuthHeaders(),
        params: { limit: 10 },
      }).then((response) => {
        console.log('applications/recent/candidates', response)
        setLoading(false);
        setCandidates(
          response.data.candidates.map((a) => CandidateModel.fromJSON(a)),
        );
      }),
    [],
  );

  if (loading) {
    return <LinearProgress />
  }

  if (!candidates.length) {
    return (
      <Typography variant="body2" className={classes.noData}>
        No recent candidates
      </Typography>
    )
  }

  return (
    <Paper style={{ padding: 15 }}>
      {candidates.map((candidate, index) => (
        <React.Fragment key={index}>
          <Link
            component={RouterLink}
            to={`/candidate-profile/${candidate.candidateUid}`}
          >
            <Typography>
              {candidate.firstName} {candidate.lastName}
            </Typography>
          </Link>
          <Divider />
        </React.Fragment>
      ))}
    </Paper>
  );
}

const styles = {
  noData: {
    padding: 50,
    backgroundColor: "#f9f9f9",
    borderRadius: 5,
    textAlign: "center"
  },
}

export default withStyles(styles)(withContext(OrganizationRoleContext, RecentApplicationCandidates));
