import React from "react";
import AppDrawerLink from "../AppDrawerLink/AppDrawerLink";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

class AppDrawerLinkGroup extends React.Component {
  state = {
    open: false,
  };

  componentDidMount = () => {
    this.setState({ open: this.props.childPathIsActive(this.props.links) });
  };

  toggle = () => {
    const open = this.state.open === null ? true : !this.state.open;
    this.setState({ open });
  };

  render() {
    const { open } = this.state;
    const { icon, primaryText, secondaryText } = this.props;
    return (
      <React.Fragment>
        <ListItem button onClick={this.toggle}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText inset primary={primaryText} secondary={secondaryText} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {this.props.links.map((p) => (
              <AppDrawerLink key={p.path} {...p} />
            ))}
          </List>
        </Collapse>
        <Divider />
      </React.Fragment>
    );
  }
}

AppDrawerLinkGroup.defaultProps = {
  childPathIsActive: (links) => {
    const paths = links.map((p) => p.path);

    return (
      paths.filter((p) => {
        return window.location.pathname.startsWith(p);
      }).length > 0
    );
  },
};

export default AppDrawerLinkGroup;
