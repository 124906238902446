import React from "react";
import Axios from "axios";
import log from "Lib/Log/log";
import withContext from "Lib/WithContext/withContext";
import {
  Grid,
  Typography,
  TablePagination,
  TextField,
  Paper,
  Divider,
  List,
  FormControl,
} from "@material-ui/core";
import { CandidateRoleContext } from "CandidateRole/CandidateRoleProvider";
import { OrganizationRoleContext } from "OrganizationRole/OrganizationRoleProvider";
import { withStyles } from "@material-ui/core/styles";
import OpportunityModel from "../OpportunityModel";
import OpportunityCard from "./OpportunityCard";
import ScrollTo from "Lib/ScrollTo/ScrollTo";

class CandidateOpportunities extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      refreshing: false,
      opportunities: [],
      total: 0,
      rowsPerPage: props.rowsPerPage || 6,
      page: 0,
      tabIndex: 0,
      query: "",
      orderBy: "published_at",
    };
  }


  componentDidMount = () => {
    this.fetchData();
  };

  componentDidUpdate = (prevProps) => {
    const filterableProps = [
      "applicationPlanJobTypes",
      "workingArrangements",
      "educationLevels",
      "technicalCategories",
    ]

    if (filterableProps.some(p => this.props[p] !== prevProps[p])) {
      this.setState({ refreshing: true, page: 0 }, this.fetchData);
      // ScrollTo.scroll("opportunity-count");
    }
  }

  fetchData = () => {
    const { organizationUid, applicationPlanUid, applicationPlanJobTypes, workingArrangements, educationLevels, technicalCategories } = this.props;

    let url, headers;
    if (this.props.publicOpportunities && this.props.organizationUid) {
      url = `/api/tb/opportunities/organizations/${organizationUid}/published`;
      headers = {};
    }
    else {
      url = `/api/tb/opportunities/published`;
      headers = this.props.getUserRoleAuthHeaders
        ? this.props.getUserRoleAuthHeaders()
        : {};
    }

    return Axios.get(url, {
      params: {
        limit: this.state.rowsPerPage,
        page: this.state.page,
        query: this.state.query || undefined,
        order_by: this.state.orderBy,
        organization_uid: organizationUid || undefined,
        application_plan_uids: applicationPlanUid ? [applicationPlanUid] : undefined,
        application_plan_job_types: applicationPlanJobTypes || undefined,
        working_arrangements: workingArrangements || undefined,
        education_levels: educationLevels || undefined,
        technical_categories: technicalCategories || undefined,
      },
      headers,
    }).then((response) => {
      log("fetchData", response.data);

      const opportunities = (response.data.opportunities || []).map((p) =>
        OpportunityModel.fromJSON(p),
      );
      const total = response.data.total;

      this.setState({ opportunities, total, loading: false, refreshing: false });
    });
  };

  onSubmitSearch = (e) => {
    e.preventDefault();
    const query = e.target.querySelector("input#query").value.trim();
    this.setState({ query }, this.fetchData);
  };

  onChangeOrderBy = (e) => {
    this.setState({ orderBy: e.target.value }, this.fetchData);
  };

  render() {
    const { classes, showTotal } = this.props;
    const { opportunities, loading, refreshing } = this.state;

    return (
      <Grid container spacing={2}  id="opportunity-count">
        {this.props.showFilters ? (
          <>
            <Grid item xs={12} sm={4} >
              <form onSubmit={this.onSubmitSearch}>
                <TextField
                  fullWidth
                  id="query"
                  label="Search"
                  placeholder=""
                  variant="outlined"
                  margin="dense"
                  style={{ backgroundColor: "white", marginTop: 16 }}
                />
              </form>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                select
                variant="outlined"
                margin="dense"
                label="Sort"
                SelectProps={{ native: true }}
                style={{ backgroundColor: "white", marginTop: 16 }}
                onChange={this.onChangeOrderBy}
                value={this.state.orderBy}
              >
                <option value="published_at">Most recent</option>
                <option value="priority_deadline_at">Closing soon</option>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4} />
          </>
        ) : null}

        <Grid item xs={12}>
          {showTotal && !loading && !refreshing && <Typography variant="caption"> {this.state.total} Opportunities </Typography>}
        </Grid>

        {opportunities.length ? (
          <>
            <Grid item xs={12} style={{ opacity: this.state.refreshing ? 0.5 : 1 }}>
              <Paper>
                <List>
                  {opportunities.map((opportunity, index) => (
                    <React.Fragment key={index}>
                      <OpportunityCard
                        opportunity={opportunity}
                        currentUserRole={this.props.currentUserRole}
                      />
                      {index + 1 < opportunities.length && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <table>
                <tbody>
                  <tr>
                    <TablePagination
                      style={{ borderBottom: 0 }}
                      page={this.state.page}
                      rowsPerPage={this.state.rowsPerPage}
                      rowsPerPageOptions={this.props.rowsPerPageOptions}
                      onRowsPerPageChange={(e) =>
                        this.setState(
                          { rowsPerPage: e.target.value },
                          this.fetchData,
                        )
                      }
                      count={this.state.total}
                      onChangePage={(_, page) => {
                        this.setState({ page }, this.fetchData);
                        ScrollTo.scroll("opportunity-count")
                      }}
                      labelRowsPerPage="Per page"
                    />
                  </tr>
                </tbody>
              </table>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <div className={classes.noOpportunities}>
              <Typography variant="body2">No current opportunities.</Typography>
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
}

const styles = (theme) => ({
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: "center",
  },
  noOpportunities: {
    padding: 50,
    backgroundColor: "#f9f9f9",
    borderRadius: 5,
  },
});

CandidateOpportunities.defaultProps = {
  showFilters: true,
  showTotal: true,
  rowsPerPageOptions: [6, 9, 12, 24]
};

export default withStyles(styles)(
  withContext(
    CandidateRoleContext,
    OrganizationRoleContext,
    CandidateOpportunities,
  ),
);
