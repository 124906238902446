import React, { useState, useEffect } from "react";
import {
  LinearProgress,
  Paper,
  Typography,
  withStyles,
  Link,
  Divider,
} from "@material-ui/core";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward, faLink, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { Link as RouterLink } from "react-router-dom";

import withContext from "Lib/WithContext/withContext";
import CandidateModel from "CandidateRole/CandidateModel";
import CandidateEducationModel from "CandidateRole/CandidateEducationModel";
import { OrganizationRoleContext } from "OrganizationRole/OrganizationRoleProvider";
import UserModel from "Settings/Users/UserModel";
import ApplicationModel from "Applications/ApplicationModel";
import Layout from "Layout/Layout";
import MaxWidth from "Lib/MaxWidth/MaxWidth";


class CandidateProfileDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      candidate: {},
      user: {},
      candidateEducations: [],
      applications: [],
      loadingApplications: true,
    };
  }

  componentDidMount() {
    this.fetchUserData();
    this.fetchApplications();
  }

  fetchUserData() {
    const { getUserRoleAuthHeaders, match: { params: { candidateUid } } } = this.props;
    
    Axios.get(`/api/tb/candidates/${candidateUid}`, {
      headers: getUserRoleAuthHeaders(),
      params: { uid: candidateUid },
    }).then((response) => {
      console.log('response', response);

      this.setState({
        user: UserModel.fromJSON(response.data.user),
        candidate: CandidateModel.fromJSON(response.data.candidate),
        candidateEducations: response.data.candidate_educations.map(ce => CandidateEducationModel.fromJSON(ce)),
        loading: false,
      });
    });
  }

  fetchApplications() {
    const { getUserRoleAuthHeaders, match: { params: { candidateUid } } } = this.props;
    
    Axios.get(`/api/tb/applications/candidates/${candidateUid}`, {
      headers: getUserRoleAuthHeaders(),
    }).then((response) => {
      console.log('response', response);
      this.setState({
        applications: response.data.applications.map(app => ApplicationModel.fromJSON(app)),
        loadingApplications: false,
      });
    });
  }

  renderCandidateApplications() {
    const { classes } = this.props;
    const { applications, loadingApplications } = this.state;

    if (loadingApplications) {
      return <LinearProgress />;
    }

    if (!applications.length) {
      return (
        <Typography variant="body2" className={classes.noData}>
          There are no applications here yet
        </Typography>
      );
    }

    return (
      <>
        {applications.map((application, index) => (
          <React.Fragment key={index}>
              <Link
                component={RouterLink}
                to={`/opportunities/${application.opportunityUid}`}
              >
                <Typography>
                  {application.opportunityTitle} - {application.organizationName} / {application.applicationPlanName}
                </Typography>
              </Link>
          </React.Fragment>
        ))}
      </>
    );
  }

  renderEducationSection() {
    const { classes } = this.props;
    const { candidateEducations } = this.state;

    if (candidateEducations.length) {
      const { universityName, yearMonth, field } = candidateEducations[0];
      return (
        <div className={classes.education}>
          <FontAwesomeIcon className={classes.educationIcon} icon={faAward} />
          <Typography className={classes.educationText + " " + classes.educationUniversityText}>{universityName}</Typography>
          <Typography className={classes.educationText + " " + classes.educationUniversityText}>{field}</Typography>
          <Typography className={classes.educationText + " " + classes.educationYear}>{String(yearMonth).slice(0, 4)}</Typography>
        </div>
      );
    }
    return null;
  }

  renderProfileInfo() {
    const { classes } = this.props;
    const { user, candidate } = this.state;

    return (
      <>
        <Typography component="h2" className={classes.userName}>
          {user.firstName} {user.lastName}
        </Typography>
        {this.renderEducationSection()}
        <div className={classes.email}>
          <FontAwesomeIcon icon={faEnvelope} />
          <Typography className={classes.linkText}>{user.email}</Typography>
        </div>
        <div>
          {[
            { icon: faLinkedin, text: "LinkedIn", url: candidate.linkedIn },
            { icon: faGithub, text: "GitHub", url: candidate.github },
            { icon: faLink, text: "Website", url: candidate.resumeUrl },
          ].map(({ icon, text, url }) => {
            if (!url?.length) return null;

            return (
              <a
                key={text}
                href={url}
                className={classes.link}
                style={{ marginLeft: 0 }}
                target="_blank"
              >
                <FontAwesomeIcon icon={icon} />
                <Typography className={classes.linkText}>{text}</Typography>
              </a>
            );
          })}
        </div>
        {[
          { name: "Preferred Name", value: candidate.prefferedName },
          // Include other fields as needed
        ].map(({ name, value }) => (
          <div key={name} className={classes.field}>
            <Typography className={classes.fieldName}>{name}:</Typography>
            <Typography className={classes.fieldValue}>{value}</Typography>
          </div>
        ))}
      </>
    );
  }

  render() {
    const { classes } = this.props;
    const { loading } = this.state;

    if (loading) {
      return (
        <Layout>
          <MaxWidth>
            <Paper>
              <LinearProgress />
            </Paper>
          </MaxWidth>
        </Layout>
      );
    }

    return (
      <Layout>
        <MaxWidth>
          <Paper style={{ padding: 26 }}>
            {this.renderProfileInfo()}
            <Divider style={{ marginBottom: 20, marginTop: 20 }} />
            <Typography variant="h6">Applications</Typography>
            {this.renderCandidateApplications()}
          </Paper>
        </MaxWidth>
      </Layout>
    );
  }
}

const styles = (theme) => ({
  userName: {
    fontSize: 32,
    fontWeight: 700,
    color: theme.palette.primary.main,
    lineHeight: "1",
    fontFamily: '"Montserrat", Sans-serif',
    marginBottom: 20,
  },
  education: { marginBottom: 20 },
  educationText: {
    fontFamily: '"Nunito", Sans-serif',
    fontSize: 16,
    fontWeight: "bold",
    display: "inline",
  },
  educationIcon: {
    color: theme.palette.primary.main,
  },
  educationUniversityText: {
    paddingLeft: 5,
    paddingRight: 5,
    borderRight: `1px solid ${theme.palette.secondary.main}`,
  },
  educationYear: {
    paddingLeft: 5,
  },
  email: {
    color: theme.palette.primary.main,
  },
  link: {
    color: theme.palette.primary.main,
    marginLeft: 8,
    marginRight: 8,
  },
  linkText: { display: "inline", marginLeft: 6 },
  field: { color: theme.palette.primary.main },
  fieldName: { display: "inline", fontWeight: 600 },
  fieldValue: { display: "inline", marginLeft: 6 },
  noData: {
    padding: 50,
    borderRadius: 5,
    textAlign: "center",
  },
});

export default withContext(
  OrganizationRoleContext,
  withStyles(styles)(CandidateProfileDetail),
);





// class CandidateProfileDetail extends React.Component {
//   render () {
//   }
// }


// function useUserData({ getUserRoleAuthHeaders, uid }) {
//   const [loading, setLoading] = useState(true);
//   const [candidate, setCandidate] = useState({});
//   const [user, setUser] = useState({});
//   const [candidateEducations, setCandidateEducations] = useState([]);

//   useEffect(() => {
//     Axios.get(`/api/tb/candidates/${uid}`, {
//       headers: getUserRoleAuthHeaders(),
//       params: { uid },
//     }).then((response) => {
//       console.log('response', response);
//       setUser(UserModel.fromJSON(response.data.user));
//       setCandidate(CandidateModel.fromJSON(response.data.candidate));
//       setCandidateEducations(
//         response.data.candidate_educations.map((candidateEducation) =>
//           CandidateEducationModel.fromJSON(candidateEducation),
//         ),
//       );
//       setLoading(false);
//     });
//   }, [getUserRoleAuthHeaders, uid]);

//   return { loading, candidate, user, candidateEducations };
// }

// function CandidateApplications({ getUserRoleAuthHeaders, classes, match: {
//   params: { candidateUid },
// }, }) {
//   const [loading, setLoading] = useState(true);
//   const [applications, setApplications] = useState([]);

//   useEffect(
//     () =>
//       Axios.get(`/api/tb/applications/candidates/${candidateUid}`, {
//         headers: getUserRoleAuthHeaders(),``
//       }).then((response) => {
//         setApplications(
//           response.data.applications.map((a) => ApplicationModel.fromJSON(a)),
//         );
//         setLoading(false);
//       }),
//     [getUserRoleAuthHeaders],
//   );

//   if (loading) {
//     return <LinearProgress />;
//   }

//   if (!applications.length) {
//     return (
//       <Typography variant="body2" className={classes.noData}>
//         There are no applications here yet
//       </Typography>
//     );
//   }

//   return (
//     <>
//       {applications.map((application, index) => (
//         <React.Fragment key={index}>
//           {application.haveAccess ? (
//             <Link
//               component={RouterLink}
//               to={`/opportunities/${application.opportunityUid}`}
//             >
//               <Typography>
//                 {application.opportunityTitle} -{" "}
//                 {application.applicationPlanName}
//               </Typography>
//             </Link>
//           ) : (
//             null
//             // <Typography>{application.organizationName}</Typography>
//           )}
//         </React.Fragment>
//       ))}
//     </>
//   );
// }

// function CandidateProfileDetail({
//   classes,
//   getUserRoleAuthHeaders,
//   match: {
//     params: { candidateUid },
//   },
// }) {
//   const { loading, user, candidate, candidateEducations } = useUserData({
//     getUserRoleAuthHeaders,
//     uid: candidateUid,
//   });

//   console.log('candidateUid', candidateUid);

//   let education = null;
//   if (candidateEducations.length) {
//     const { universityName, yearMonth, field } = candidateEducations[0];
//     education = (
//       <div className={classes.education}>
//         <FontAwesomeIcon className={classes.educationIcon} icon={faAward} />
//         <Typography
//           className={
//             classes.educationText + " " + classes.educationUniversityText
//           }
//         >
//           {universityName}
//         </Typography>
//         <Typography
//           className={
//             classes.educationText + " " + classes.educationUniversityText
//           }
//         >
//           {field}
//         </Typography>
//         <Typography
//           className={classes.educationText + " " + classes.educationYear}
//         >
//           {String(yearMonth).slice(0, 4)}
//         </Typography>
//       </div>
//     );
//   }

//   if (loading) {
//     return (
//       <Layout>
//         <MaxWidth>
//           <Paper>
//             <LinearProgress />
//           </Paper>
//         </MaxWidth>
//       </Layout>
//     );
//   }

//   return (
//     <Layout>
//       <MaxWidth>
//         <Paper style={{ padding: 26 }}>
//           <Typography component="h2" className={classes.userName}>
//             {user.firstName} {user.lastName}
//           </Typography>
//           {education}
//           <div className={classes.email}>
//             <FontAwesomeIcon icon={faEnvelope} />
//             <Typography className={classes.linkText}>{user.email}</Typography>
//           </div>
//           <div>
//             {[
//               { icon: faLinkedin, text: "LinkedIn", url: candidate.linkedIn },
//               { icon: faGithub, text: "GitHub", url: candidate.github },
//               { icon: faLink, text: "Website", url: candidate.resumeUrl },
//             ].map(({ icon, text, url }) => {
//               if (!url?.length) return null;

//               return (
//                 <a
//                   key={text}
//                   href={url}
//                   className={classes.link}
//                   style={{ marginLeft: 0 }}
//                 >
//                   <FontAwesomeIcon icon={icon} />
//                   <Typography className={classes.linkText}>{text}</Typography>
//                 </a>
//               );
//             })}
//           </div>
//           {[
//             { name: "Preferred Name", value: candidate.prefferedName },
//             { name: "Veteran Status", value: candidate.veteranStatus },
//             {
//               name: "Affiliations",
//               value: candidate.affiliationData?.join(", "),
//             },
//             {
//               name: "Opportunity Preferences",
//               value: candidate.positionTypeData?.join(", "),
//             },
//             {
//               name: "Job Roles",
//               value: candidate.jobRoleData?.join(", "),
//             },
//             {
//               name: "Affinities",
//               value: candidate.affinityGroupData?.join(", "),
//             },
//           ].map(({ name, value }) => (
//             <div key={name} className={classes.field}>
//               <Typography className={classes.fieldName}>{name}:</Typography>
//               <Typography className={classes.fieldValue}>{value}</Typography>
//             </div>
//           ))}

//           <Divider style={{ marginBottom: 20, marginTop: 20 }} />

//           <CandidateApplications
//             getUserRoleAuthHeaders={getUserRoleAuthHeaders}
//             user={user}
//             classes={classes}
//           />
//         </Paper>
//       </MaxWidth>
//     </Layout>
//   );
// }

// const styles = (theme) => ({
//   userName: {
//     fontSize: 32,
//     fontWeight: 700,
//     color: theme.palette.primary.main,
//     lineHeight: "1",
//     fontFamily: '"Montserrat", Sans-serif',
//     marginBottom: 20,
//   },
//   education: { marginBottom: 20 },
//   educationText: {
//     fontFamily: '"Nunito", Sans-serif',
//     fontSize: 16,
//     fontWeight: "bold",
//     display: "inline",
//   },
//   educationIcon: {
//     color: theme.palette.primary.main,
//   },
//   educationUniversityText: {
//     paddingLeft: 5,
//     paddingRight: 5,
//     borderRight: `1px solid ${theme.palette.secondary.main}`,
//   },
//   educationYear: {
//     paddingLeft: 5,
//   },
//   email: {
//     color: theme.palette.primary.main,
//   },
//   link: {
//     color: theme.palette.primary.main,
//     marginLeft: 8,
//     marginRight: 8,
//   },
//   linkText: { display: "inline", marginLeft: 6 },
//   field: { color: theme.palette.primary.main },
//   fieldName: { display: "inline", fontWeight: 600 },
//   fieldValue: { display: "inline", marginLeft: 6 },
//   noData: {
//     padding: 50,
//     borderRadius: 5,
//     textAlign: "center",
//   },
// });

// export default withContext(
//   OrganizationRoleContext,
//   withStyles(styles)(CandidateProfileDetail),
// );
