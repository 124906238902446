import React from "react";
import { Switch, Route } from "react-router-dom";

import * as Ability from "Ability/Ability";
import AppNavigation from "../../AppNavigation/AppNavigation";
import NewOrganization from "./NewOrganization/NewOrganization";
import OrganizationIndex from "./OrganizationIndex/OrganizationIndex";
import OrganizationDetails from "./OrganizationDetails/OrganizationDetails";
import OrganizationProfileDetails from "./OrganizationProfile/OrganizationProfileDetails";
import EditOrganizationProfile from "./OrganizationProfile/EditOrganizationProfile";

// import NewCompany from '../Companies/NewCompany/NewCompany';
import UserRoleGuard from "../../UserRoles/UserRoleGuard";

class Organizations extends React.Component {
  render() {
    return (
      <>
        <UserRoleGuard
          authorizeRole={(role) =>
            role.roleHasAbility(Ability.EDIT_ORGANIZATION_PROFILE)
          }
        >
          <Switch>
            <Route
              exact
              path="/organizations/:organizationUid/profile/edit"
              component={EditOrganizationProfile}
            />
            <Route
              exact
              path="/organizations/:id/new"
              component={NewOrganization}
            />
          </Switch>
        </UserRoleGuard>
        <UserRoleGuard
          authorizeRole={(role) =>
            role.roleHasAbility(Ability.VIEW_ORGANIZATION_PROFILE)
          }
        >
          <Switch>
            <Route
              exact
              path="/organizations/:organizationUid/profile"
              component={OrganizationProfileDetails}
            />
            <Route
              exact
              path="/organizations/:id"
              component={OrganizationDetails}
            />
            <Route
              path="/organizations/:id/user_invitations"
              component={OrganizationDetails}
            />
            <Route exact path="/organizations" component={OrganizationIndex} />
          </Switch>
        </UserRoleGuard>
      </>
    );
  }
}

export default Organizations;
