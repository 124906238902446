import React from "react";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles } from "@material-ui/core/styles";

const smallTablePagination = (props) => (
  <Table>
    <TableFooter>
      <TableRow>
        {props.children}

        <TablePagination
          classes={{
            selectRoot: props.classes.selectRoot,
            actions: props.classes.actions,
          }}
          labelRowsPerPage="Per Page:"
          {...props}
        />
      </TableRow>
    </TableFooter>
  </Table>
);

const styles = (theme) => ({
  actions: {
    marginLeft: 10,
  },
  selectRoot: {
    marginRight: 15,
  },
});

export default withStyles(styles)(smallTablePagination);
