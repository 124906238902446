import React from "react";

import Snackbar from "./Snackbar";

const clickAwayAutoHideProps = {
  mouseEvent: "onClick",
  touchEvent: "onTouchEnd",
};
const clickAwayNoAutoHideProps = { mouseEvent: false, touchEvent: false };

const defaultAutoHideDuration = 6000;

const DEFAULT_STATE = {
  snackbarOpen: false,
  snackbarMessage: false,
  autoHideDuration: defaultAutoHideDuration,
  ClickAwayListenerProps: clickAwayAutoHideProps,
};

export const SnackbarContext = React.createContext(DEFAULT_STATE);

class SnackbarProvider extends React.Component {
  state = DEFAULT_STATE;

  showSnackbar = (message, options = {}) => {
    options = { autoHide: true, autoHideDuration: null, ...options };

    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
      autoHideDuration: options.autoHide
        ? options.autoHideDuration || this.props.defaultAutoHideDuration
        : null,
      ClickAwayListenerProps: options.autoHide
        ? clickAwayAutoHideProps
        : clickAwayNoAutoHideProps,
    });
  };

  hideSnackbar = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    return (
      <SnackbarContext.Provider
        value={{
          ...this.state,
          showSnackbar: this.showSnackbar,
          hideSnackbar: this.hideSnackbar,
        }}
      >
        {this.props.children}
        <Snackbar
          open={this.state.snackbarOpen}
          message={this.state.snackbarMessage}
          onClose={() => this.setState({ snackbarOpen: false })}
          autoHideDuration={this.state.autoHideDuration}
          ClickAwayListenerProps={this.state.ClickAwayListenerProps}
        />
      </SnackbarContext.Provider>
    );
  }
}

SnackbarProvider.defaultProps = {
  defaultAutoHideDuration,
};

export default SnackbarProvider;
