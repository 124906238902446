import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1E4B45",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#338E8E",
      // main: "#218591",
      contrastText: "#FFFFFF",
    },
    // raisedButton: {
    //   textColor: '#ffffff', // this should work
    //   primaryTextColor: '#ffffff' // or this if using `primary` style
    // }
  },
  typography: {
    fontSize: 15,
    h1: {
      fontFamily: '"Montserrat", sans-serif',
    },
    h2: {
      fontFamily: '"Montserrat", sans-serif',
    },
    h3: {
      fontFamily: '"Montserrat", sans-serif',
    },
    h4: {
      fontFamily: '"Montserrat", sans-serif',
    },
    h5: {
      fontFamily: '"Montserrat", sans-serif',
    },
    h6: {
      fontFamily: '"Montserrat", sans-serif',
    },
    body1: {
      fontFamily: '"Nunito", sans-serif',
    },
    body2: {
      fontFamily: '"Nunito", sans-serif',
    },
    caption: {
      fontFamily: '"Nunito", sans-serif',
    },
  },
});



export default {
  ...theme,
  shadows: theme.shadows.map(() => 'none'),
}