import React from "react";
import withContext from "Lib/WithContext/withContext";
import log from "Lib/Log/log";
import { UserRoleContext } from "../UserRoles/UserRoleProvider";
import CandidateNavigation from "./CandidateNavigation";
import OrganizationNavigation from "./OrganizationNavigation";

const appNavigation = (props) => {
  return props.currentUserRole.isOrganizationRole &&
    props.currentUserRole.isOrganizationRole() ? (
    <OrganizationNavigation {...props} />
  ) : (
    <CandidateNavigation {...props} />
  );
};

export default withContext(UserRoleContext, appNavigation);
