import React from "react";
import Axios from "axios";
import { SnackbarContext } from "Lib/Snackbar/SnackbarProvider";
import { AuthContext } from "Lib/Auth/AuthProvider";
import CredentialsModel from "Lib/Auth/CredentialsModel";
import log from "Lib/Log/log";
import withContext from "Lib/WithContext/withContext";
import AutoError from "Lib/AutoError/AutoError";
import { LinearProgress } from "@material-ui/core";
import history from "../../history";
import UserModel from "Settings/Users/UserModel";
import UserInvitationModel from "Settings/UserInvitations/UserInvitationModel";
import AcceptExistingUserInvitation from "./AcceptExistingUserInvitation";
import AcceptNewUserInvitation from "./AcceptNewUserInvitation";

class AcceptUserInvitation extends React.Component {
  state = {
    loading: true,
    errorMessage: null,
    userInvitation: {},
    role: {},
    organization: {},
    company: {},
    existingUser: {},
    invitedByUser: {},
  };

  componentDidMount = () => {
    this.getToken();
  };

  getToken = () => {
    const { token } = this.props.match.params;

    return Axios.get(`/api/core/user_invitations/${token}`)
      .then((response) => {
        const userInvitation = UserInvitationModel.fromJSON(
          response.data.user_invitation,
        );

        log("getToken response", response);
        this.setState({
          userInvitation,
          role: response.data.role,
          organization: response.data.organization,
          company: response.data.company,
          existingUser: response.data.existing_user
            ? UserModel.fromJSON(response.data.existing_user)
            : {},
          invitedByUser: UserModel.fromJSON(response.data.invited_by_user),
          email: userInvitation.email,
          loading: false,
        });
      })
      .catch((error) => {
        log("getToken error", error);
        if (error.response && error.response.status === 404) {
          this.setState({
            errorMessage: "Invalid userInvitation",
            loading: false,
          });
        }
      });
  };

  onAcceptUserInvitation = (invitationData = {}) => {
    return this.acceptUserInvitation(invitationData)
      .then(this.signInAndRedirect)
      .catch(AutoError.catch.bind(this));
  };

  acceptUserInvitation = (invitationData) => {
    const { userInvitation } = this.state;
    return Axios.post(
      `/api/core/user_invitations/${userInvitation.token}/accept`,
      invitationData,
    )
      .then((response) => {
        log("postData", response);
        const credentials = CredentialsModel.fromJSON(
          response.data.credentials,
        );
        log("credentials", credentials);
        return credentials;
      })
      .catch((error) => {
        log(error);
        throw {
          name: "AcceptUserInvitationError",
          message: error.response.data.message,
        };
      });
  };

  onAcceptUserInvitationError = (error) => {
    this.props.showSnackbar(error.message);
  };

  signInAndRedirect = (credentials) => {
    this.props.setCredentials(credentials);
    history.push("/");
  };

  render() {
    const {
      loading,
      userInvitation,
      role,
      organization,
      company,
      existingUser,
      invitedByUser,
    } = this.state;

    if (loading) {
      return <LinearProgress />;
    }

    const AcceptUserInvitationComponent = existingUser.id
      ? AcceptExistingUserInvitation
      : AcceptNewUserInvitation;

    return (
      <AcceptUserInvitationComponent
        userInvitation={userInvitation}
        role={role}
        organization={organization}
        company={company}
        existingUser={existingUser}
        invitedByUser={invitedByUser}
        onAcceptUserInvitation={this.onAcceptUserInvitation}
      />
    );
  }
}

export default withContext(AuthContext, SnackbarContext, AcceptUserInvitation);
