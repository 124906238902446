import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default (props) => (
  <SvgIcon
    className={props.className || ""}
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
  >
    <g>
      <path d="M22.2,49.5c1.2,1.2,2.7,1.7,4.2,1.7c1.5,0,3.1-0.6,4.2-1.7c2.3-2.3,2.3-6.1,0-8.5l-7.8-7.8h68.6c3.3,0,6-2.7,6-6     s-2.7-6-6-6H22.9l7.8-7.8c2.3-2.3,2.3-6.1,0-8.5c-2.3-2.3-6.1-2.3-8.5,0l-18,18c-2.3,2.3-2.3,6.1,0,8.5L22.2,49.5z" />
      <path d="M95.7,68.5l-18-18c-2.3-2.3-6.1-2.3-8.5,0c-2.3,2.3-2.3,6.1,0,8.5l7.8,7.8H8.5c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6h68.6     l-7.8,7.8c-2.3,2.3-2.3,6.1,0,8.5c1.2,1.2,2.7,1.8,4.2,1.8c1.5,0,3.1-0.6,4.2-1.8l18-18C98.1,74.6,98.1,70.9,95.7,68.5z" />
    </g>
  </SvgIcon>
);
