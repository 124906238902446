import { useState } from "react";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";

import "./index.css";

export default function HtmlEditor({ value, onChange }) {
  const [editorState, setEditorState] = useState(() => {
    const contentBlock = htmlToDraft(value || "");
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    );
    return EditorState.createWithContent(contentState);
  });

  const onEditorStateChange = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    onChange(draftToHtml(rawContentState));
  };

  return (
    <Editor
      onBlur={onEditorStateChange}
      editorState={editorState}
      onEditorStateChange={setEditorState}
      wrapperClassName="HtmlEditor-editorWrapper"
      toolbar={{
        options: ["inline", "list", "link"],
      }}
    />
  );
}
