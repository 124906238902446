import React from "react";
import Axios from "axios";
import log from "Lib/Log/log";
import withContext from "Lib/WithContext/withContext";
import { CandidateRoleContext } from "CandidateRole/CandidateRoleProvider";
import { LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CandidateAssessmentModel from "CandidateRole/CandidateAssessmentModel";

class CandidateAssesmentStart extends React.Component {
  componentDidMount = () => {
    this.createAssesssmentAndRedirect();
  };

  createAssesssmentAndRedirect = () => {
    const { opportunityUid } = this.props.match.params;
    log("createAssesssmentAndRedirect", opportunityUid);

    return Axios.put(
      `/api/tb/candidate_assessments/opportunities/${opportunityUid}`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() },
    ).then((response) => {
      log("response.data", response.data);
      this.setState(
        {
          candidateAssessment: CandidateAssessmentModel.fromJSON(
            response.data.candidate_assessment,
          ),
        },
        this.redirectToAssessment,
      );
    });
  };

  redirectToAssessment = () => {
    window.location.href = this.state.candidateAssessment.invitationUrl;
  };

  render() {
    // return "Loading...";
    return <LinearProgress color="primary" />;
  }
}

const styles = (theme) => ({
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: "center",
  },
  label: {
    opacity: 0.7,
    textTransform: "uppercase",
  },
  description: {
    fontSize: "1em",
    marginBottom: 15,
  },
  chips: {
    marginBottom: 10,
  },
  avatar: {
    border: "1px solid whitesmoke",
    margin: 10,
    width: 60,
    height: 60,
  },
  organizationLink: {
    fontWeight: "bold",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  iconListItem: {
    paddingTop: 6,
    paddingBottom: 6,
  },
});

export default withStyles(styles)(
  withContext(CandidateRoleContext, CandidateAssesmentStart),
);
