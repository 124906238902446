import React from "react";
import Link from "react-router-dom/Link";
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import isLinkActive from "../../isLinkActive";
import { Avatar } from "@material-ui/core";

const appDrawerLink = (props) => {
  const {
    primaryText,
    onClick,
    path,
    classes,
    exactPathMatch,
    icon,
    secondaryText,
    secondaryAction,
    dense,
  } = props;

  const isActive = isLinkActive(props);

  let listItemProps = {
    dense,
  };
  if (onClick) {
    listItemProps = {
      ...listItemProps,
      button: true,
      onClick,
    };
  } else if (path) {
    listItemProps = {
      ...listItemProps,
      button: true,
      component: Link,
      to: path,
    };
  }

  return (
    <ListItem {...listItemProps}>
      {icon ? (
        <ListItemAvatar
          classes={isActive ? { root: classes.activeListItemIcon } : null}
        >
          <Avatar classes={isActive ? { root: classes.listItemAvatarActive } : {root: classes.listItemAvatar}}>
            {icon}
          </Avatar>
        </ListItemAvatar>
      ) : null}

      <ListItemText
        classes={
          isActive
            ? {
                primary: classes.activeListItemText,
                root: classes.rootListItemText,
              }
            : { root: classes.rootListItemText }
        }
        primary={primaryText}
        secondary={secondaryText}
      />
      {secondaryAction ? (
        <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
      ) : null}
    </ListItem>
  );
};

const styles = (theme) => ({
  activeListItemIcon: {
    color: theme.palette.primary.main,
    backgrondColor: "transparent",
    color: "inherit"
  },
  listItemAvatar: {
    backgroundColor: "transparent",
    color: "#777",
  },
  listItemAvatarActive: {
    backgroundColor: "whitesmoke",
    color: theme.palette.primary.main,
  },
  rootListItemText: {
    paddingLeft: 8,
    paddingRight: 8,
    color: "#333",
  },
  activeListItemText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    color: "black"
  },
});

export default withStyles(styles)(appDrawerLink);
