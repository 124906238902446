export const TECHNICAL_CATEGORIES = [
  "Engineering",
  "Data Science",
  "Design",
  "Product",
  "Marketing",
  "Sales",
  "Finance & Accounting",
  "Legal",
  "Consulting",
];
// steps
export const STEP_BASIC = "STEP_BASIC";
export const STEP_DESCRIPTION = "STEP_DESCRIPTION";
export const STEP_ASSESSMENT = "STEP_ASSESSMENT";
export const STEP_REVIEW = "STEP_REVIEW";
