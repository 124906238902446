import React from "react";
import Axios from "axios";
import log from "Lib/Log/log";
import formHelpers from "Lib/FormHelpers/formHelpers";
import withContext from "Lib/WithContext/withContext";
import AutoError from "Lib/AutoError/AutoError";
import PromiseButton from "Lib/PromiseButton/PromiseButton";
import { SnackbarContext } from "Lib/Snackbar/SnackbarProvider";
import { withStyles } from "@material-ui/core/styles";
import { OrganizationRoleContext } from "OrganizationRole/OrganizationRoleProvider";
import CompanyAutosuggest from "./CompanyAutosuggest/CompanyAutosuggest";

import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class NewCompanyUserRoleDialog extends React.Component {
  state = {
    loading: true,
    roleType: "Organization",
    roleTypeId: "",
    roleId: "",
    possibleOrganizations: [],
    possibleOrganizationRoles: [],
    loadingCompanyRoles: false,
    loadingCompanies: false,
    possibleCompanyRoles: [],
  };

  componentDidMount = () => {
    this.getPossibleOrganizations().then(this.getPossibleOrganizationRoles);
  };

  getPossibleOrganizations = () => {
    const { organization } = this.props;

    return Axios.get(`/api/core/organizations/${organization.id}/family`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("getPossibleOrganizations response", response);
      const possibleOrganizations = response.data.family;
      this.setState({ possibleOrganizations });
    });
  };

  getPossibleOrganizationRoles = () => {
    const { organization } = this.props;
    return Axios.get(`/api/core/organizations/${organization.id}/roles`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("getPossibleOrganizationRoles response", response);
      const possibleOrganizationRoles = response.data.roles;
      this.setState({ possibleOrganizationRoles });
    });
  };

  onSuggestionSelected = (suggestion) => {
    this.setState({ roleTypeId: suggestion.id }, this.getPossibleCompanyRoles);
  };

  getPossibleCompanyRoles = () => {
    log("getPossibleCompanyRoles");
    const { roleTypeId } = this.state;
    this.setState({ loadingCompanyRoles: true });
    return Axios.get(`/api/core/companies/${roleTypeId}/roles`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("getPossibleCompanyRoles response", response);
      const possibleCompanyRoles = response.data.roles;
      this.setState({ possibleCompanyRoles, loadingCompanyRoles: false });
    });
  };

  addUserRole = () => {
    const { userId } = this.props;
    const { roleId, roleType, roleTypeId } = this.state;

    return Axios.post(
      `/api/core/user_roles`,
      {
        user_id: userId,
        role_id: roleId,
        role_type: roleType,
        role_type_id: roleTypeId,
      },
      { headers: this.props.getUserRoleAuthHeaders() },
    ).then((response) => {
      log("addUserRole response", response);

      this.props.onUserRoleAdded();
      this.props.onClose();
    });
  };

  onProcess = () => {
    log("onProcess", this.state);
    return this.validate()
      .then(this.addUserRole)
      .catch(AutoError.catch.bind(this));
  };

  onValidationError = (error) => {
    log("error", error);
    this.props.showSnackbar(error.message);
  };

  onError = (error) => {
    log("error", error);
    this.props.showSnackbar(error.response.data.message);
  };

  validate = () => {
    const validations = {
      roleTypeId: {
        presence: {
          message: `Please select the user's ${this.state.roleType.toLowerCase()}.`,
        },
      },
      roleId: {
        presence: { message: "Please select the user's role." },
      },
    };
    return formHelpers.validate(this.state, validations);
  };

  render() {
    const { classes, organization } = this.props;
    const {
      roleId,
      roleType,
      roleTypeId,
      possibleOrganizations,
      possibleCompanyRoles,
      possibleOrganizationRoles,
      loadingCompanyRoles,
      loadingCompanies,
    } = this.state;

    return (
      <Dialog
        scroll="body"
        open={this.props.open}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle>Add a User Role</DialogTitle>
        {loadingCompanies ? (
          <LinearProgress color="secondary" />
        ) : (
          <div style={{ height: 4 }} />
        )}
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          <DialogContentText className={classes.dialogContentText}>
            Please enter the following information to add a new role for this
            user.
          </DialogContentText>

          <TextField
            select
            fullWidth
            label="Role Type"
            value={roleType}
            onChange={(e) => this.setState({ roleType: e.target.value })}
          >
            <MenuItem value="Organization">Organization</MenuItem>
            <MenuItem value="Company">Company</MenuItem>
          </TextField>

          {roleType === "Organization" ? (
            <>
              <TextField
                select
                fullWidth
                label="Select an Organization"
                value={roleTypeId}
                onChange={(e) => this.setState({ roleTypeId: e.target.value })}
                margin="normal"
              >
                {possibleOrganizations.map((o) => (
                  <MenuItem key={o.id} value={o.id}>
                    {o.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                select
                fullWidth
                label="Organization Role"
                value={roleId}
                onChange={(e) => this.setState({ roleId: e.target.value })}
                margin="normal"
              >
                {possibleOrganizationRoles.map((r) => (
                  <MenuItem key={r.id} value={r.id}>
                    {r.name}
                  </MenuItem>
                ))}
              </TextField>
            </>
          ) : null}

          {roleType === "Company" ? (
            <>
              <CompanyAutosuggest
                label="Search companies"
                suggestionsUrl={`/api/core/organizations/${organization.id}/companies`}
                onSuggestionSelected={this.onSuggestionSelected}
                onSuggestionsFetchRequested={() =>
                  this.setState({ loadingCompanies: true })
                }
                onSuggestionsFetchCompleted={() =>
                  this.setState({ loadingCompanies: false })
                }
              />
              <TextField
                select
                fullWidth
                label="Company Role"
                value={roleId}
                onChange={(e) => this.setState({ roleId: e.target.value })}
                disabled={loadingCompanyRoles || !roleTypeId ? true : false}
                margin="normal"
              >
                {possibleCompanyRoles.map((r) => (
                  <MenuItem key={r.id} value={r.id}>
                    {r.name}
                  </MenuItem>
                ))}
              </TextField>
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="outlined" color="primary">
            Cancel
          </Button>
          <PromiseButton onProcess={this.onProcess}>Add</PromiseButton>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = (theme) => ({
  dialogPaper: {
    overflowY: "initial",
  },
  dialogContentRoot: {
    overflowY: "initial",
  },
  dialogContentText: {
    marginTop: 10,
    marginBottom: 10,
  },
});

export default withStyles(styles)(
  withContext(
    OrganizationRoleContext,
    SnackbarContext,
    NewCompanyUserRoleDialog,
  ),
);
