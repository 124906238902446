import KnModel from "Lib/KnModel";

export default class CandidateAssessmentModel extends KnModel {
  isComplete() {
    return this.codingScore > 0;
  }

  isPending() {
    return this.status === "pending";
  }
}
