import Alert from "Alert/Alert";
import React from "react";
import Axios from "axios";
import log from "Lib/Log/log";
import withContext from "Lib/WithContext/withContext";
import { CandidateRoleContext } from "CandidateRole/CandidateRoleProvider";
import { withStyles } from "@material-ui/core/styles";
import AppNavigation from "AppNavigation/AppNavigation";
import OpportunityModel from "../../Opportunities/OpportunityModel";
import OrganizationModel from "OrganizationRole/OrganizationModel";
import ApplicationModel from "Applications/ApplicationModel";
import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Button,
  CardContent,
  Typography,
  CardActions,
  Chip,
  LinearProgress,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const tabs = ["Opportunities", "Companies"];

class EditCandidateApplication extends React.Component {
  state = {
    loading: true,
    application: {},
    opportunity: {},
    organization: {},
    total: 0,
    tabIndex: 0,
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    const { uid } = this.props.match.params;
    console.log("uid", uid);

    return Axios.get(`/api/tb/applications/${uid}`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchData", response.data);

      const application = ApplicationModel.fromJSON(response.data.application);
      const opportunity = OpportunityModel.fromJSON(response.data.opportunity);
      const organization = OrganizationModel.fromJSON(
        response.data.organization,
      );

      this.setState({ application, opportunity, organization, loading: false });
    });
  };

  render() {
    const { application, organization, opportunity, loading } = this.state;
    const { classes, candidate } = this.props;

    if (loading) {
      // return "Loading...";
      return <LinearProgress color="primary" />;
    }

    return (
      <AppNavigation title="Discover">
        <div style={{ padding: 16 }}>
          <h4>Thank you for Applying</h4>
          <Button
            component={RouterLink}
            color="secondary"
            variant="contained"
            to="/applications"
          >
            My Applications
          </Button>
        </div>
      </AppNavigation>
    );
  }
}

const styles = (theme) => ({
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: "center",
  },
});

export default withStyles(styles)(
  withContext(CandidateRoleContext, EditCandidateApplication),
);
