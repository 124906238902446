import { Grid, Typography } from "@material-ui/core";
import Layout from "Layout/Layout";

export default function Insights(props) {
  return (
    <Layout title="Insights">
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h4" gutterBottom>
            Insights
          </Typography>
          <Typography variant="subtitle1" color="textSecondary"></Typography>
        </Grid>
      </Grid>
    </Layout>
  );
}
