import KnModel from "Lib/KnModel";

export default class CandidateEducationModel extends KnModel {
  constructor(props = {}) {
    super(props);

    this.yearMonth =
      props.yearMonth || CandidateEducationModel.DEFAULT_YEAR + "01";
    this.isCurrentSchool = props.isCurrentSchool || false;
  }

  static getYears() {
    const d = new Date();
    const years = [];

    const start = d.getFullYear() - 10;
    for (let i = start; i < start + 20; i++) {
      years.push(i);
    }

    return years;
  }

  getYear() {
    return (this.yearMonth || "").toString().slice(0, 4);
  }

  getMonth() {
    return (this.yearMonth || "").toString().slice(4);
  }
}

CandidateEducationModel.DEGREE_BACHELORS = "Bachelors";
CandidateEducationModel.DEGREE_MASTERS = "Masters";
CandidateEducationModel.DEGREE_MBA = "MBA";
CandidateEducationModel.DEGREE_PHD = "PhD";

CandidateEducationModel.DEGREES = [
  CandidateEducationModel.DEGREE_BACHELORS,
  CandidateEducationModel.DEGREE_MASTERS,
  CandidateEducationModel.DEGREE_MBA,
  CandidateEducationModel.DEGREE_PHD,
];

CandidateEducationModel.DEFAULT_YEAR = CandidateEducationModel.getYears()[0];
