import React from "react";
import Axios from "axios";
import { Link as RouterLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import withContext from "Lib/WithContext/withContext";
import log from "Lib/Log/log";
import {
  IconButton,
  Tooltip,
  Typography,
  LinearProgress,
  TextField,
  InputAdornment,
} from "@material-ui/core";

import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import SmallTablePagination from "Lib/SmallTablePagination/SmallTablePagination";
import { Add, Search, Clear } from "@material-ui/icons";

import history from "history.js";
import { UserRoleContext } from "UserRoles/UserRoleProvider";
import OrganizationModel from "../OrganizationModel";
import TableToolbar from "Lib/TableToolbar/TableToolbar";

class OrganizationTable extends React.Component {
  state = {
    organizations: [],
    parentsByChildOrganizationId: {},
    page: 0,
    rowsPerPage: 10,
    count: 0,
    loading: true,
    refreshing: false,
    query: "",
  };

  componentDidMount = () => {
    this.fetchOrganizations();
  };

  componentDidUpdate = (newProps) => {
    if (
      this.props.organization &&
      this.props.organization.id !== newProps.organization.id
    ) {
      this.fetchOrganizations();
    }
  };

  onClickClear = () => {
    this.setState(
      { query: "", refreshing: true, loading: true, page: 0 },
      this.fetchOrganizations,
    );
  };

  onSubmitSearch = (e) => {
    e.preventDefault();
    this.setState(
      { refreshing: true, loading: true, page: 0 },
      this.fetchOrganizations,
    );
  };

  collateParentsByChildren = (parents) => {
    const children = {};

    parents.forEach((p) => {
      const childParents = children[p.childOrganizationId] || [];
      childParents.push(p);
      children[p.childOrganizationId] = childParents;
    });

    return children;
  };

  fetchOrganizations = () => {
    const { rowsPerPage, page, query } = this.state;
    const showDescendantsInfo = this.props.showDescendantsInfo;
    log("fetchOrganizations", rowsPerPage, page);

    const url = showDescendantsInfo
      ? `/api/core/organizations/${this.props.organization.id}/descendants`
      : `/api/core/organizations/${this.props.organization.id}/children`;

    return Axios.get(url, {
      params: {
        limit: rowsPerPage,
        page,
        query: query ? query : undefined,
      },
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchOrganizations response", response);
      let organizations;
      let parentsByChildOrganizationId = {};

      if (showDescendantsInfo) {
        organizations = response.data.descendants.map((o) =>
          OrganizationModel.fromJSON(o),
        );

        const parents = response.data.parents.map((p) =>
          OrganizationModel.fromJSON(p),
        );
        parentsByChildOrganizationId = this.collateParentsByChildren(parents);
      } else {
        organizations = response.data.organizations.map((o) =>
          OrganizationModel.fromJSON(o),
        );
      }

      let count = response.data.count;

      log("organizations", organizations);

      // I am not sure why the following works but it prevents a bug
      // where any repeated organizations will stay on the page on
      // the number of times it appears at the top of the table increments
      // by 1 every time the table is paginated
      this.setState(
        {
          organizations: [],
        },
        () =>
          this.setState({
            organizations,
            parentsByChildOrganizationId,
            count,
            loading: false,
            refreshing: false,
          }),
      );
    });
  };

  onClickAddOrganization = () => {
    history.push(`/organizations/${this.props.organization.id}/new`);
  };

  changeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage, refreshing: true }, this.fetchOrganizations);
  };

  changePage = (page) => {
    this.setState({ page, refreshing: true }, this.fetchOrganizations);
  };

  listItem = (organization) => {
    const parents = this.state.parentsByChildOrganizationId[organization.id];
    const parentNames = parents
      ? parents.map((p) => p.parentName).join(", ")
      : null;
    return (
      <ListItem
        button
        key={organization.id}
        component={RouterLink}
        to={`/organizations/${organization.id}`}
        name={`organization-${organization.id}`}
      >
        <ListItemText
          primary={organization.name || "No Name Provided"}
          secondary={this.props.showDescendantsInfo ? parentNames : null}
        />
      </ListItem>
    );
  };

  createListItems = () => {
    log("organization before", this.state.organizations);
    return this.state.organizations.map((o) => this.listItem(o));
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper>
        <TableToolbar
          title="Organizations"
          caption={
            <>
              Suborganizations of{" "}
              <span className={classes.primary}>
                {this.props.organization.name}
              </span>
              .
            </>
          }
          actions={
            <Tooltip title="Add a new organization">
              <IconButton
                onClick={this.onClickAddOrganization}
                color="primary"
                variant="contained"
              >
                <Add />
              </IconButton>
            </Tooltip>
          }
        />

        {this.state.loading ? <LinearProgress /> : null}

        <form onSubmit={this.onSubmitSearch}>
          <TextField
            placeholder="Search Organizations..."
            value={this.state.query}
            onChange={(e) => this.setState({ query: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment className={classes.searchIcon} position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: this.state.query ? (
                <InputAdornment position="end">
                  <IconButton onClick={this.onClickClear}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            inputProps={{
              className: classes.search,
            }}
            fullWidth
          />
          <button style={{ display: "none" }} />
        </form>

        {!this.state.loading && !this.state.organizations.length ? (
          <Typography
            color="textSecondary"
            paragraph
            variant="caption"
            className={this.props.classes.noOrganizations}
          >
            Organizations can be used to segment companies. For example, you
            could set up 'Engineering' as an organization, and then invite users
            to it. This would allow your Engineering team to see only the data
            relevant to them. Note: using organizations is not required.
          </Typography>
        ) : (
          <>
            <List style={{ opacity: this.state.refreshing ? 0.5 : 1 }}>
              {this.createListItems()}
            </List>
            <Divider />
            <SmallTablePagination
              count={this.state.count || 0}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangeRowsPerPage={(e) =>
                this.changeRowsPerPage(e.target.value)
              }
              onChangePage={(e, p) => this.changePage(p)}
              rowsPerPageOptions={[5, 10, 25, 50]}
            />
          </>
        )}
      </Paper>
    );
  }
}

OrganizationTable.defaultProps = {
  showDescendantsInfo: false,
};

const styles = (theme) => ({
  noOrganizations: {
    textAlign: "left",
    padding: 25,
  },
  search: {
    paddingTop: 10,
    paddingBottom: 14,
  },
  searchIcon: {
    marginLeft: 24,
    color: "#A7A7A7",
  },
});

export default withStyles(styles)(
  withContext(UserRoleContext, OrganizationTable),
);
