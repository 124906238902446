import KnModel from "Lib/KnModel";

export default class CandidateModel extends KnModel {
  isProfileComplete() {
    const truthyValues = [
      "gender",
      "raceData",
      // 'codeSignalScore'
    ];

    const incompletes = truthyValues
      .map((k) => {
        const v = this[k];
        if (!v) {
          console.log("isProfileComplete: missing", k);
          return false;
        }
        if (Array.isArray(v) && v.length === 0) {
          console.log("isProfileComplete: missing", k);
          return false;
        }
      })
      .filter((v) => v === false);

    return incompletes.length === 0;
  }
}

CandidateModel.RACE_AMERICAN_INDIAN_OR_ALASKA_NATIVE =
  "American Indian or Alaska Native";
CandidateModel.RACE_ASIAN = "Asian";
CandidateModel.RACE_BLACK_OR_AFRICAN_AMERICAN = "Black or African American";
CandidateModel.RACE_HISPANIC_OR_LATINO = "Hispanic or Latino";
CandidateModel.RACE_NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER =
  "Native Hawaiian or Other Pacific Islander";
CandidateModel.RACE_WHITE = "White";
CandidateModel.RACE_UNDISCLOSED = "I prefer not to say";

CandidateModel.RACES = [
  CandidateModel.RACE_AMERICAN_INDIAN_OR_ALASKA_NATIVE,
  CandidateModel.RACE_ASIAN,
  CandidateModel.RACE_BLACK_OR_AFRICAN_AMERICAN,
  CandidateModel.RACE_HISPANIC_OR_LATINO,
  CandidateModel.RACE_NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER,
  CandidateModel.RACE_WHITE,
  CandidateModel.RACE_UNDISCLOSED,
];

CandidateModel.RACES_WITHOUT_UNDISCLOSED = CandidateModel.RACES.filter(
  (r) => r !== CandidateModel.RACE_UNDISCLOSED,
);

CandidateModel.GENDER_MALE = "Male";
CandidateModel.GENDER_FEMALE = "Female";
CandidateModel.GENDER_NONBINARY = "Non-Binary";
CandidateModel.GENDER_UNDISCLOSED = "I prefer not to say";

CandidateModel.GENDERS = [
  CandidateModel.GENDER_MALE,
  CandidateModel.GENDER_FEMALE,
  CandidateModel.GENDER_NONBINARY,
  CandidateModel.GENDER_UNDISCLOSED,
];

CandidateModel.AFFILIATION_COLOR_STACK = "ColorStack";
CandidateModel.AFFILIATION_NSBE = "NBSE";
CandidateModel.AFFILIATION_WICS = "WiCS";
CandidateModel.AFFILIATION_MLT = "MLT";

CandidateModel.AFFILIATIONS = [
  CandidateModel.AFFILIATION_COLOR_STACK,
  CandidateModel.AFFILIATION_NSBE,
  CandidateModel.AFFILIATION_WICS,
  CandidateModel.AFFILIATION_MLT,
];

CandidateModel.POSITION_TYPE_FULL_TIME = "Full Time";
CandidateModel.POSITION_TYPE_INTERNSHIP = "Internship";
CandidateModel.POSITION_TYPE_APPRENTICESHIP = "Apprenticeship";
CandidateModel.POSITION_TYPE_PROGRAM = "Program";

CandidateModel.POSITION_TYPES = [
  CandidateModel.POSITION_TYPE_FULL_TIME,
  CandidateModel.POSITION_TYPE_INTERNSHIP,
  CandidateModel.POSITION_TYPE_APPRENTICESHIP,
  CandidateModel.POSITION_TYPE_PROGRAM,
];

CandidateModel.JOB_ROLES_FULL_STACK = "Full Stack";
CandidateModel.JOB_ROLES_BACKEND = "Backend";
CandidateModel.JOB_ROLES_FRONTEND = "Frontend";
CandidateModel.JOB_ROLES_INFRASTRUCTURE = "Infrastructure";
CandidateModel.JOB_ROLES_MOBILE = "Mobile";
CandidateModel.JOB_ROLES_DATA_ENGINEERING = "Data Engineering";
CandidateModel.JOB_ROLES_SECURITY = "Security";

CandidateModel.JOB_ROLES = [
  CandidateModel.JOB_ROLES_FULL_STACK,
  CandidateModel.JOB_ROLES_BACKEND,
  CandidateModel.JOB_ROLES_FRONTEND,
  CandidateModel.JOB_ROLES_INFRASTRUCTURE,
  CandidateModel.JOB_ROLES_MOBILE,
  CandidateModel.JOB_ROLES_DATA_ENGINEERING,
  CandidateModel.JOB_ROLES_SECURITY,
];
