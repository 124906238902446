import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const textEllipsis = (props) => (
  <span className={props.classes.textEllipsis}>{props.children}</span>
);

const styles = {
  textEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    whiteSpace: "nowrap",
  },
};

export default withStyles(styles)(textEllipsis);
