import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

class Alert extends React.PureComponent {
  render() {
    const { classes, children, component, className, icon, ...other } =
      this.props;

    const classOptions = [
      "primary",
      "primaryLight",
      "secondary",
      "secondaryLight",
      "bootstrapPrimary",
      "bootstrapSecondary",
      "success",
      "danger",
      "warning",
      "info",
      "light",
      "dark",
    ];

    const optionClassName = classOptions.find((o) => this.props[o]);
    const Component = component || "div";
    const Icon = icon;

    return (
      <Component
        {...other}
        className={[
          classes.alert,
          this.props.dense ? classes.dense : null,
          classes[optionClassName],
          className,
        ].join(" ")}
      >
        {Icon ? <Icon className={classes.icon} /> : null}
        {children}
      </Component>
    );
  }
}

const styles = (theme) => ({
  alert: {
    position: "relative",
    padding: ".75rem 1.25rem",
    marginBottom: "1rem",
    border: "1px solid transparent",
    borderRadius: ".25rem",
    display: "flex",
    alignContent: "center",
  },
  dense: {
    padding: ".375rem .625rem",
    fontSize: "0.85rem",
  },
  primary: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.light,
  },
  primaryLight: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.light,
  },
  secondaryLight: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.main,
  },
  bootstrapPrimary: {
    color: "#004085",
    backgroundColor: "#cce5ff",
    borderColor: "#b8daff",
  },
  bootstrapSecondary: {
    color: "#383d41",
    backgroundColor: "#e2e3e5",
    borderColor: "#d6d8db",
  },
  success: {
    color: "#155724",
    backgroundColor: "#d4edda",
    borderColor: "#c3e6cb",
  },
  danger: {
    color: "#721c24",
    backgroundColor: "#f8d7da",
    borderColor: "#f5c6cb",
  },
  warning: {
    color: "#856404",
    backgroundColor: "#fff3cd",
    borderColor: "#ffeeba",
  },
  info: {
    color: "#0c5460",
    backgroundColor: "#d1ecf1",
    borderColor: "#bee5eb",
  },
  light: {
    color: "#818182",
    backgroundColor: "#fefefe",
    borderColor: "#fdfdfe",
  },
  dark: {
    color: "#1b1e21",
    backgroundColor: "#d6d8d9",
    borderColor: "#c6c8ca",
  },
  icon: {
    marginRight: 10,
  },
});

export default withStyles(styles)(Alert);
