import React from "react";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import withContext from "Lib/WithContext/withContext";
import { AuthContext } from "Lib/Auth/AuthProvider";
import { removeStoredCurrentUserRoleId } from "../UserRoles/UserRoleProvider";

const sign_out = (props) => {
  if (props.credentials) {
    Axios.post(
      "/api/core/auth/sign_out",
      {},
      { headers: props.getAuthHeaders() },
    );
  }

  props.removeCredentials();
  removeStoredCurrentUserRoleId();

  return <Redirect to="/" />;
};

export default withContext(AuthContext, sign_out);
