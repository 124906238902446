import React from "react";
import Axios from "axios";
import log from "Lib/Log/log";
import MaxWidth from "Lib/MaxWidth/MaxWidth";
import withContext from "Lib/WithContext/withContext";
import { Grid, Button, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  FormControl,
  FormLabel,
  FormHelperText,
  TextField,
  MenuItem,
} from "@material-ui/core";
import CandidateEducationModel from "CandidateRole/CandidateEducationModel";
import UniversityAutosuggest from "./UniversityAutosuggest";

export default class EditCandidateEducation extends React.Component {
  render() {
    const ce = this.props.candidateEducation;

    return (
      <>
        <Grid item sm={12}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Degree Type</FormLabel>
            <FormHelperText></FormHelperText>
            <TextField
              fullWidth
              select
              variant="outlined"
              margin="dense"
              value={ce.degree}
              onChange={(e) => {
                ce.degree = e.target.value;
                this.props.onChange(ce, this.props.index);
              }}
            >
              {CandidateEducationModel.DEGREES.map((d) => (
                <MenuItem key={d} value={d}>
                  {d}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>

        <Grid item sm={12}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Field</FormLabel>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              value={ce.field}
              onChange={(e) => {
                ce.field = e.target.value;
                this.props.onChange(ce, this.props.index);
              }}
            />
          </FormControl>
        </Grid>

        <Grid item sm={12}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">University</FormLabel>
            <FormHelperText></FormHelperText>
            <UniversityAutosuggest
              label=""
              suggestionsUrl={`/api/tb/candidate_educations/universities`}
              onSuggestionSelected={(s) => {
                ce.universityName = s.name;
                ce.universityId = s.id;
              }}
              initialInputValue={ce.universityName}
              onSuggestionsFetchRequested={
                () => {}
                // this.props.onChange({ loadingUniversities: true })
              }
              onSuggestionsFetchCompleted={
                () => {}
                // this.props.onChange({ loadingUniversities: false })
              }
            />
          </FormControl>
        </Grid>

        <Grid item sm={6}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Start date</FormLabel>
            <FormHelperText></FormHelperText>
            <TextField
              select
              native
              variant="outlined"
              margin="dense"
              value={ce.startDate}
              onChange={(e) => {
                ce.startDate = e.target.value;
                this.props.onChange(ce, this.props.index);
              }}
            >
              {CandidateEducationModel.getYears().map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>

        <Grid item sm={6}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">End date</FormLabel>
            <FormHelperText></FormHelperText>
            <TextField
              select
              native
              variant="outlined"
              margin="dense"
              value={ce.getYear()}
              onChange={(e) => {
                ce.yearMonth = +(e.target.value + ce.getMonth());
                this.props.onChange(ce, this.props.index);
              }}
            >
              {CandidateEducationModel.getYears().map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      </>
    );
  }
}
