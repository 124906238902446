import KnModel from "Lib/KnModel";
import Axios from "axios";

export default class ApplicationModel extends KnModel {
  constructor(props) {
    super(props);
  }

  // displayName = () => {
  //   return `${this.lastName}, ${this.firstName}`;
  // };

  appliedFormatted() {
    return this.appliedAt.toLocaleString().split(",")[0];
  }

  getState() {
    if (this.withdrew) {
      return ApplicationModel.WITHDRAWN;
    } else if (this.applied) {
      return ApplicationModel.APPLIED;
    } else {
      return ApplicationModel.NONE;
    }
  }

  isNoneState() {
    return this.getState() === ApplicationModel.NONE;
  }

  isAppliedState() {
    return this.getState() === ApplicationModel.APPLIED;
  }

  isWithdrawnState() {
    return this.getState() === ApplicationModel.WITHDRAWN;
  }
}

ApplicationModel.WITHDRAWN = "WITHDRAWN";
ApplicationModel.APPLIED = "APPLIED";
ApplicationModel.NONE = "";
