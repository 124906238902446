import React from "react";
import Axios from "axios";
import log from "Lib/Log/log";
import withContext from "Lib/WithContext/withContext";
import { OrganizationRoleContext } from "OrganizationRole/OrganizationRoleProvider";
import { withStyles } from "@material-ui/core/styles";
import AppNavigation from "AppNavigation/AppNavigation";
import ApplicationTable from "./ApplicationTable";
import { Link as RouterLink } from "react-router-dom";
import { Breadcrumbs, Paper, Toolbar } from "@material-ui/core";
import MaxWidth from "Lib/MaxWidth/MaxWidth";
import ApplicationPlanModel from "ApplicationPlans/ApplicationPlanModel";
import OpportunityModel from "../../Opportunities/OpportunityModel";
import OrganizationModel from "OrganizationRole/OrganizationModel";
import OrganizationProfileModel from "Settings/Organizations/OrganizationProfile/OrganizationProfileModel";
import { Button, Typography, Grid, Link, LinearProgress } from "@material-ui/core";

import OrganizationOpportunityDetailContent from "Opportunities/OrganizationOpportunities/OrganizationOpportunityDetailContent";

class OrganizationOpportunityDetail extends React.Component {
  state = {
    loading: true,
    opportunity: new OpportunityModel(),
    applications: [],
    applicationTotal: 0,
    applicationPage: 0,
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    this.fetchOpportunity()
      .then(() => {
        this.setState({ loading: false });
      });
  };

  fetchOpportunity = () => {
    const { uid } = this.props.match.params;

    return Axios.get(`/api/tb/opportunities/${uid}`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchOpportunity", response.data);

      const { data } = response;

      const opportunity = OpportunityModel.fromJSON(data.opportunity);
      const organization = OrganizationModel.fromJSON(data.organization);
      const organizationProfile = OrganizationProfileModel.fromJSON(
        data.organization_profile,
      );
      const applicationPlan = ApplicationPlanModel.fromJSON(
        data.application_plan,
      );

      this.setState({
        opportunity,
        organization,
        organizationProfile,
        applicationPlan,
      });

      this.setState({ opportunity });
    });
  };



  putOpportunity = (opportunity) => {
    return Axios.put(
      `/api/tb/opportunities/${opportunity.uid}`,
      { opportunity },
      { headers: this.props.getUserRoleAuthHeaders() },
    ).then((response) => {
      log("putOpportunity", response.data);

      const opportunity = OpportunityModel.fromJSON(response.data.opportunity);
      this.setState({ opportunity, loading: false });
    });
  };

  onChangeStatus = (status) => {
    const { opportunity } = this.state;
    opportunity.status = status;
    if (status === OpportunityModel.STATUS_PUBLISHED && !opportunity.publishedAt) {
      opportunity.publishedAt = new Date();
    }

    this.putOpportunity(opportunity);
  };

  render() {
    const { classes, match } = this.props;
    const {
      organization,
      organizationProfile,
      opportunity,
      applicationPlan,
      applicationTotal,
      applications,
      loading,
    } = this.state;

    if (loading) {
      // return "Loading...";
      return <LinearProgress color="primary" />;
    }

    
    return (
      <AppNavigation title="Opportunities">
        <MaxWidth maxWidth={1200} style={{ padding: 16 }}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Button
                className={classes.toolbarButton}
                variant="contained"
                color="primary"
                size="small"
                component={RouterLink}
                to={`/opportunities/${opportunity.uid}/edit`}
              >
                Edit
              </Button>

              {opportunity.isDraft() || opportunity.isUnpublished() ? (
                <Button
                className={classes.toolbarButton}
                variant="outlined"
                  size="small"
                  onClick={() =>
                    this.onChangeStatus(OpportunityModel.STATUS_PUBLISHED)
                  }
                >
                  Publish
                </Button>
              ) : null}

              {opportunity.isPublished() ? (
                <Button
                className={classes.toolbarButton}
                variant="outlined"
                  size="small"
                  onClick={() =>
                    this.onChangeStatus(OpportunityModel.STATUS_UNPUBLISHED)
                  }
                >
                  Unpublish
                </Button>
              ) : null}

              {opportunity.isPublished() || opportunity.isUnpublished() ? (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    this.onChangeStatus(OpportunityModel.STATUS_ARCHIVED)
                  }
                >
                  Archive
                </Button>
              ) : null}

              {opportunity.isArchived() ? (
                <Button
                className={classes.toolbarButton}
                variant="outlined"
                  size="small"
                  onClick={() =>
                    this.onChangeStatus(OpportunityModel.STATUS_PUBLISHED)
                  }
                >
                  Unarchive
                </Button>
              ) : null}
            </Grid>

            <Grid item sm={12}>
              <Typography variant="h4">{opportunity.title}</Typography>
              <Typography variant="h6">
                {opportunity.organizationName}
              </Typography>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Breadcrumbs style={{ marginBottom: 15 }}>
                  <Link
                    color="inherit"
                    component={RouterLink}
                    to="/opportunities"
                  >
                    Opportunities
                  </Link>
                  <Typography color="textPrimary">
                    {opportunity.title} - {organization.name}
                  </Typography>
                </Breadcrumbs>
              </Grid>

              <Grid item xs={12}>
                <OrganizationOpportunityDetailContent
                  organization={organization}
                  organizationProfile={organizationProfile}
                  applicationPlan={applicationPlan}
                  opportunity={opportunity}
                />
              </Grid>
            </Grid>

            <Paper style={{ marginTop: 20, width: "100%" }}>
             <ApplicationTable opportunityUid={match.params.uid}/>
            </Paper>
          </Grid>
        </MaxWidth>
      </AppNavigation>
    );
  }
}

const styles = (theme) => ({
  toolbarButton: {
    marginRight: 8,
  },
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: "center",
  },
  chips: {
    marginBottom: 10,
  },
});

export default withStyles(styles)(
  withContext(OrganizationRoleContext, OrganizationOpportunityDetail),
);
