import { useEffect } from "react";
import {
  Checkbox,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  TextField,
  MenuItem,
  Grid,
} from "@material-ui/core";

import OpportunityModel from "Opportunities/OpportunityModel";
import { validateField } from "Lib/FormHelpers/formHelpers";

import {
  STEP_DESCRIPTION,
  STEP_REVIEW,
} from "Opportunities/OrganizationOpportunities/EditOpportunity/constants";
import { StepBase } from "Opportunities/OrganizationOpportunities/EditOpportunity/components";

export default function StepAssessment(props) {
  useEffect(() => {
    if (
      props.isAssessmentRequired &&
      !props.minimumCodeSignalScore &&
      props.minimumCodeSignalScore !== 0
    ) {
      props.onChange({
        minimumCodeSignalScore: 0,
      });
    }
  }, [props.isAssessmentRequired]);

  return (
    <StepBase
      goBackStep={() => props.onChange({ step: STEP_DESCRIPTION })}
      goNextStep={() =>
        props.setNextStep(STEP_REVIEW, {
          additionalAssessmentUrl: {
            custom: async ({ state, invalid }) => {
              if (state.isAdditionalAssessmentRequired) {
                await validateField(
                  state,
                  {
                    presence: {
                      message: `Please set additional assessment url`,
                    },
                    URL: {
                      message: `Please set valid additional assessment url`,
                    },
                  },
                  state.additionalAssessmentUrl,
                  invalid,
                );
              }
            },
          },
        })
      }
    >
      <Grid item sm={12}>
        Assessment
      </Grid>

      <Grid item sm={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Opportunity Criteria/Requirements
          </FormLabel>
          <FormHelperText></FormHelperText>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.isAssessmentRequired}
                onChange={(e) => {
                  props.onChange({
                    isAssessmentRequired: e.target.checked,
                  });
                }}
                name="is_assessment_required"
              />
            }
            label="Is a technical assessment required?"
          />
        </FormControl>
      </Grid>

      {props.isAssessmentRequired ? (
        <Grid item sm={12}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Minimum Code Signal Score</FormLabel>
            <FormHelperText>Will not be displayed publicly</FormHelperText>
            <TextField
              fullWidth
              select
              variant="outlined"
              margin="dense"
              value={props.minimumCodeSignalScore}
              onChange={(e) =>
                props.onChange({ minimumCodeSignalScore: e.target.value })
              }
            >
              {OpportunityModel.SIGNAL_SCORES.map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      ) : (
        ""
      )}

      <Grid item sm={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Opportunity Criteria/Requirements
          </FormLabel>
          <FormHelperText></FormHelperText>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.isAdditionalAssessmentRequired}
                onChange={(e) => {
                  props.onChange({
                    isAdditionalAssessmentRequired: e.target.checked,
                  });
                  if (e.target.checked && !props.additionalAssessmentVendor) {
                    props.onChange({
                      additionalAssessmentVendor:
                        OpportunityModel.ASSESSMENT_VENDORS[
                          OpportunityModel.ASSESSMENT_VENDORS.length - 1
                        ][0],
                    });
                  }
                }}
                name="is_additional_assessment_required"
              />
            }
            label="Is an additional technical assessment required?"
          />
        </FormControl>
      </Grid>

      {props.isAdditionalAssessmentRequired ? (
        <>
          <Grid item sm={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Additional Technical Assessment Vendor
              </FormLabel>
              <FormHelperText>Be careful</FormHelperText>
              <RadioGroup
                aria-label="opportunity_type"
                name="opportunity_type"
                value={props.additionalAssessmentVendor}
                onChange={(e) =>
                  props.onChange({ additionalAssessmentVendor: e.target.value })
                }
              >
                {OpportunityModel.ASSESSMENT_VENDORS.map(([value, name]) => (
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={<Radio />}
                    label={name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item sm={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Assessment URL</FormLabel>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                value={props.additionalAssessmentUrl}
                onChange={(e) =>
                  props.onChange({ additionalAssessmentUrl: e.target.value })
                }
              />
            </FormControl>
          </Grid>
        </>
      ) : (
        ""
      )}
    </StepBase>
  );
}
