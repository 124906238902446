import { withStyles } from "@material-ui/core";
import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Button,
  CardContent,
  Typography,
  CardActions,
  Chip,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Link,
} from "@material-ui/core";
import { CalendarToday as CalendarTodayIcon } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import format from "Lib/Format/format";
import log from "Lib/Log/log";
import React from "react";
import Axios from "axios";
import history from "history.js";
import { UserRoleContext } from "UserRoles/UserRoleProvider";
import withContext from "Lib/WithContext/withContext";
import SmallChip from "SmallChip/SmallChip";

class OpportunityCard extends React.Component {
  // onClickApply = (e) => {
  //   e.preventDefault();

  //   console.log("onClickApply");
  //   const { opportunity } = this.props;
  //   if (opportunity.applicationUid) {
  //     history.push(`/applications/${opportunity.applicationUid}`);
  //     return;
  //   }

  //   return Axios.post(
  //     `/api/tb/applications/opportunities/${opportunity.uid}`,
  //     {},
  //     { headers: this.props.getUserRoleAuthHeaders() }
  //   ).then((response) => {
  //     const { application } = response.data;
  //     history.push(`/applications/${application.uid}`);
  //   });
  // };

  render() {
    const { opportunity, classes } = this.props;

    return (
      <ListItem>
        <ListItemAvatar>
          <Avatar
            className={classes.avatar}
            color="primary"
            src={opportunity.organizationProfileImageUrl}
          />
        </ListItemAvatar>
        <ListItemText disableTypography>
          <Typography variant="h5" className={classes.title}>
            <Link
              component={RouterLink}
              to={`/opportunities/${opportunity.uid}`}
            >
              {opportunity.title}
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link
              component={RouterLink}
              className={classes.organizationLink}
              to={`/companies/${opportunity.organizationUid}`}
            >
              {opportunity.organizationName}
            </Link>{" "}
            -&nbsp;
            {opportunity.locationsText()}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {opportunity.applicationPlanName}
          </Typography>
          {/* <Typography variant="caption" gutterBottom>
            {opportunity.description.slice(0, 150) + "..."}
          </Typography> */}

          <Typography variant="caption" color="primary">
            Posted:{" "}
            {opportunity.publishedAt
              ? opportunity.publishedAt.toLocaleString()
              : ""}
          </Typography>
        </ListItemText>
      </ListItem>
    );

    return (
      <>
        <div>
          {/* <Card className={classes.card}> */}
          <CardHeader
            avatar={
              <Avatar
                className={classes.avatar}
                color="primary"
                src={opportunity.organizationProfileImageUrl}
              />
            }
            title={
              <>
                <Typography variant="h5" className={classes.title}>
                  <Link
                    component={RouterLink}
                    to={`/opportunities/${opportunity.uid}`}
                  >
                    {opportunity.title}
                  </Link>
                </Typography>
                <Typography variant="body2">
                  <Link
                    component={RouterLink}
                    className={classes.organizationLink}
                    to={`/companies/${opportunity.organizationUid}`}
                  >
                    {opportunity.organizationName}
                  </Link>{" "}
                  -&nbsp;
                  {opportunity.locationsText()}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {opportunity.applicationPlanName}
                </Typography>
                <Typography variant="caption" gutterBottom>
                  {opportunity.description.slice(0, 150) + "..."}
                </Typography>

                <Typography variant="caption" color="primary">
                  Posted: {opportunity.publishedAt.toLocaleString()}
                </Typography>
              </>
            }
            // action={
            //   <IconButton aria-label="Add to favorites">
            //     <AddIcon />
            //   </IconButton>
            // }
          />
          {/* <CardContent className={classes.cardContent}>
            <Typography variant="h6" gutterBottom>
              {opportunity.organizationName}
            </Typography>
            <div className={classes.chips}>
              <SmallChip
                variant="contained"
                icon={<CalendarTodayIcon />}
                label={opportunity.applicationPlanName}
              />
              <SmallChip
                icon={<BusinessIcon />}
                label={organization.industry}
              />
              <SmallChip
                icon={<PeopleIcon />}
                label={organization.headcount.toLocaleString('en-US') + " employees"}
              />
            </div>
            <Typography variant="body1" gutterBottom>
              {opportunity.description.slice(0, 100) + "..."}
            </Typography>

            <Typography variant="body1" gutterBottom>
              Posted: {opportunity.publishedAt.toLocaleString()}
            </Typography>

            <Typography variant="body1" gutterBottom>
              Application Plan: {opportunity.applicationPlanName}
            </Typography>

            <Typography variant="body1" gutterBottom>
              Minimum Education:{" "}
              <span style={{ textTransform: "capitalize" }}>
                {opportunity.educationLevel.replace(/_/g, " ").toLowerCase()}
              </span>
            </Typography>

            <div style={{ textAlign: "right" }}>
              <Button
                variant="outlined"
                onClick={this.onClickApply}
              >
                {opportunity.applicationUid
                  ? opportunity.applicationApplied
                    ? "View"
                    : "Continue"
                  : "Apply"}
              </Button>
            </div>
          </CardContent> */}
          <CardActions
            className={classes.actions}
            disableActionSpacing
          ></CardActions>
          {/* </Card> */}
        </div>
      </>
    );
  }
}

const styles = (theme) => ({
  cardContent: {
    paddingTop: 0,
  },
  chips: {
    marginBottom: 10,
  },
  noData: {
    textAlign: "center",
    padding: 25,
    color: "#777",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  avatar: {
    border: "1px solid whitesmoke",
    margin: 10,
    width: 60,
    height: 60,
  },
  title: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  organizationLink: {
    fontWeight: "bold",
    textDecoration: "none",
    // '&:hover': {
    //   textDecoration: 'underline',
    // },
    // '&:active': {
    //   textDecoration: 'underline',
    // },
  },
});

OpportunityCard.defaultProps = {
  total: 0,
  page: 0,
  opportunities: [],
  loading: true,
  rowsPerPage: 50,
  rowsPerPageOptions: [50],
  changePage: (p) => {
    log("page", p);
  },
  changeRowsPerPage: (rowsPerPage) => {
    log("rows per page", rowsPerPage);
  },
};

export default withStyles(styles)(
  withContext(UserRoleContext, OpportunityCard),
);
