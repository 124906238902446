import { Chip as MuiChip, withStyles } from "@material-ui/core";

export default withStyles({
  root: {
    height: 24,
    marginRight: 10,
    marginBottom: 10,
  },
  icon: {
    fontSize: 16,
  },
  label: {
    fontSize: "0.7625rem",
  },
})((props) => (
  <MuiChip
    icon={props.icon}
    label={props.label}
    variant={props.variant || "outlined"}
    color={props.color || "textSecondary"}
    classes={{
      root: props.classes.root,
      icon: props.classes.icon,
      label: props.classes.label,
    }}
  />
));
