import React from "react";
import withContext from "Lib/WithContext/withContext";
import { UserRoleContext } from "../UserRoles/UserRoleProvider";
import { Redirect } from "react-router";
import PublishedOpportunities from "Opportunities/PublishedOpportunities";

function Home({ currentUserRole }) {
  if (currentUserRole.id) {
    return <Redirect to="/dashboard" />;
  }

  return <PublishedOpportunities/>;
}

export default withContext(UserRoleContext, Home);
