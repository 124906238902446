import { Route } from "react-router-dom";
import { UserRoleContext } from "UserRoles/UserRoleProvider";
import withContext from "Lib/WithContext/withContext";
import React from "react";
import CandidateDashboard from "./CandidateDashboard/CandidateDashboard";
import OrganizationDashboard from "./OrganizationDashboard/OrganizationDashboard";

const dashboard = (props) =>
  props.currentUserRole.isCandidateRole() ? (
    <Route path="/dashboard" component={CandidateDashboard} />
  ) : (
    <Route path="/dashboard" component={OrganizationDashboard} />
  );

export default withContext(UserRoleContext, dashboard);
