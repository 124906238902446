import KnModel from "Lib/KnModel";
import Axios from "axios";
import log from "Lib/Log/log";

export default class CandidateOrganizationModel extends KnModel {
  constructor(props) {
    super(props);
  }

  static delete = (organizationUid, userRoleAuthHeaders) => {
    log("deleteCandidateOrganization", organizationUid, userRoleAuthHeaders);
    return Axios.delete(
      `/api/tb/candidate_organizations/organizations/${organizationUid}`,
      { headers: userRoleAuthHeaders },
    );
  };
}
