import React from "react";
import log from "Lib/Log/log";
import HelpIcon from "@material-ui/icons/Help";
import HeadsetMic from "@material-ui/icons/HeadsetMic";
import supportPortalLink from "./supportPortalLink";

export default [
  {
    primaryText: "Support Portal",
    icon: <HelpIcon />,
    onClick: () => window.open(`talentboard.io`, "_blank"),
    tooltipTitle: "Support Portal",
  },
];
