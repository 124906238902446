import React from "react";
import { SnackbarContext } from "Lib/Snackbar/SnackbarProvider";
import { AuthContext } from "Lib/Auth/AuthProvider";
import PromiseButton from "Lib/PromiseButton/PromiseButton";
import { ThemeContext } from "Lib/Theme/ThemeProvider";
import log from "Lib/Log/log";
import MaxWidth from "Lib/MaxWidth/MaxWidth";
import formHelpers from "Lib/FormHelpers/formHelpers";
import withContext from "Lib/WithContext/withContext";
import AutoError from "Lib/AutoError/AutoError";

import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography, Grid, TextField, Button } from "@material-ui/core";

class AcceptNewUserInvitation extends React.Component {
  state = {
    errorMessage: null,
    firstName: "",
    lastName: "",
    email: this.props.userInvitation.email,
    password: "",
    confirmPassword: "",
  };

  onSubmit = (e) => {
    const invitationData = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      password: this.state.password,
      confirm_password: this.state.confirmPassword,
    };

    return this.validate()
      .then(() => this.props.onAcceptUserInvitation(invitationData))
      .catch(AutoError.catch.bind(this));
  };

  validate = () => {
    const { password } = this.state;

    let newUserValidations = {
      firstName: {
        presence: { message: "Please enter your first name." },
      },
      lastName: {
        presence: { message: "Please enter your last name." },
      },
      email: {
        presence: { message: "Please enter your email address." },
        email: { message: "Please enter a valid email address." },
      },
      password: {
        presence: { message: "Please enter a password." },
        length: {
          atLeast: 8,
          message: "Password must be at least 8 characters.",
        },
      },
      confirmPassword: {
        presence: { message: "Please confirm your password." },
        equalTo: { value: password, message: "Passwords must match." },
      },
    };

    return formHelpers.validate(this.state, newUserValidations);
  };

  onValidationError = (error) => {
    log("error", error);
    this.props.showSnackbar(error.message);
  };

  render() {
    const { classes, userInvitation, company, organization } = this.props;

    const entityName =
      userInvitation.roleType === "Company" ? company.name : organization.name;

    return (
      <MaxWidth maxWidth={600}>
        <Paper className={classes.paperRoot}>
          <div className={classes.headingWrapper}>
            <Typography variant="caption">JOIN</Typography>
            <Typography variant="h5" color="primary" gutterBottom>
              {this.props.theme.appTitle}
            </Typography>
            <Typography variant="body2" paragraph>
              Hi, {userInvitation.email}. You're invited to join the{" "}
              {entityName} team.
            </Typography>
          </div>

          <form>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <TextField
                  label="Email"
                  value={this.state.email}
                  type="email"
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item sm={6}>
                <TextField
                  label="First Name"
                  value={this.state.firstName}
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                  onBlur={() => formHelpers.trim.call(this, "firstName")}
                  margin="normal"
                  fullWidth
                />
              </Grid>

              <Grid item sm={6}>
                <TextField
                  label="Last Name"
                  value={this.state.lastName}
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                  onBlur={() => formHelpers.trim.call(this, "lastName")}
                  margin="normal"
                  fullWidth
                />
              </Grid>

              <Grid item sm={6}>
                <TextField
                  label="Password"
                  type="password"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  onBlur={() => formHelpers.trim.call(this, "password")}
                  margin="normal"
                  fullWidth
                />
                <Typography variant="caption">
                  At least eight characters.
                </Typography>
              </Grid>

              <Grid item sm={6}>
                <TextField
                  label="Confirm Password"
                  type="password"
                  value={this.state.confirmPassword}
                  onChange={(e) =>
                    this.setState({ confirmPassword: e.target.value })
                  }
                  onBlur={() => formHelpers.trim.call(this, "confirmPassword")}
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12}>
                <PromiseButton
                  onProcess={this.onSubmit}
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  Join
                </PromiseButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </MaxWidth>
    );
  }
}

const styles = (theme) => ({
  errorIcon: {
    fontSize: 100,
  },
  paperRoot: {
    marginTop: 100,
    paddingLeft: 45,
    paddingRight: 45,
    paddingTop: 35,
    paddingBottom: 35,
  },
  headingWrapper: {
    textAlign: "center",
  },
});

export default withStyles(styles)(
  withContext(
    AuthContext,
    SnackbarContext,
    ThemeContext,
    AcceptNewUserInvitation,
  ),
);
