import React from "react";
import { AuthContext } from "Lib/Auth/AuthProvider";
import { ThemeContext } from "Lib/Theme/ThemeProvider";
import MaxWidth from "Lib/MaxWidth/MaxWidth";
import withContext from "Lib/WithContext/withContext";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography, Grid, TextField, Button } from "@material-ui/core";
import UserModel from "Settings/Users/UserModel";
import { SnackbarContext } from "Lib/Snackbar/SnackbarProvider";

class AcceptExistingUserInvitation extends React.Component {
  onAcceptExistingUserInvitation = (e) => {
    e.preventDefault();
    return this.props.onAcceptUserInvitation();
  };

  render() {
    const { classes, userInvitation, company, organization, existingUser } =
      this.props;

    const entityName =
      userInvitation.roleType === "Company" ? company.name : organization.name;

    return (
      <MaxWidth maxWidth={600}>
        <Paper className={classes.paperRoot}>
          <div className={classes.headingWrapper}>
            <Typography variant="caption">JOIN</Typography>
            <Typography variant="h5" color="primary" gutterBottom>
              {this.props.theme.appTitle}
            </Typography>
            <Typography variant="body2" paragraph>
              Hi, {existingUser.firstName}{" "}
              {(existingUser.lastName || "").slice(0, 1)}. You're invited to
              join the {entityName} team.
            </Typography>
          </div>

          <form onSubmit={this.onAcceptExistingUserInvitation}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button variant="contained" color="primary" type="submit">
                  Join
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </MaxWidth>
    );
  }
}

const styles = (theme) => ({
  paperRoot: {
    marginTop: 100,
    paddingLeft: 45,
    paddingRight: 45,
    paddingTop: 35,
    paddingBottom: 35,
  },
  headingWrapper: {
    textAlign: "center",
  },
});

export default withStyles(styles)(
  withContext(
    AuthContext,
    SnackbarContext,
    ThemeContext,
    AcceptExistingUserInvitation,
  ),
);
