import { useEffect, useState } from "react";
import Axios from "axios";

export const useTalents = ({ getUserRoleAuthHeaders, page }) => {
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [talents, setTalents] = useState([]);

  useEffect(() => {
    setLoading(true);
    Axios.get("/api/tb/applications/recent/candidates", {
      headers: getUserRoleAuthHeaders(),
      params: { limit: 100, offset: page * 100 },
    }).then((response) => {
      setTalents(response.data.candidates);
      setTotal(response.data.total);
      setLoading(false);
    });
  }, [getUserRoleAuthHeaders, page]);

  return { loading, total, talents };
};
