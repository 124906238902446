import KnModel from "Lib/KnModel";
import moment from "moment";

export default class ApplicationPlanModel extends KnModel {
  constructor(props) {
    super(props);
  }

  startedAtFormatted = () => {
    return this.startedAt.toLocaleString().split(",")[0];
  };

  endedAtFormatted = () => {
    return this.endedAt.toLocaleString().split(",")[0];
  };

  timePeriod = () => {
    return this.startedAtFormatted() + " - " + this.endedAtFormatted();
  };
}
