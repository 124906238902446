import Axios from "axios";

import OrganizationProfileModel from "Settings/Organizations/OrganizationProfile/OrganizationProfileModel";
import OrganizationModel from "OrganizationRole/OrganizationModel";

export async function fetchOrganizationProfile(headers, organizationUid) {
  const { data } = await Axios.get(
    `/api/tb/organization_profiles/${organizationUid}`,
    {
      headers,
    },
  );
  const organization = OrganizationModel.fromJSON(data.organization);
  const organizationProfile = OrganizationProfileModel.fromJSON(
    data.organization_profile,
  );

  return {
    organization,
    organizationProfile,
  };
}
