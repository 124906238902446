import React from "react";
import AppDrawer from "./AppDrawer/AppDrawer";
import AppBar from "./AppBar";
import { withStyles } from "@material-ui/core";

class AppNavigation extends React.Component {
  state = {
    drawerOpen: false,
  };

  toggleDrawer = (drawerOpen) => {
    this.setState({ drawerOpen });
  };

  render() {
    const { appDrawerLinks, user, title, classes } = this.props;

    return (
      <div>
        <AppDrawer
          open={this.state.drawerOpen}
          toggleDrawer={this.toggleDrawer}
          user={user}
          links={appDrawerLinks}
        />
        <AppBar
          openDrawer={() => this.toggleDrawer(true)}
          title={title}
          menuItems={this.props.appBarMenuItems}
          collapseMobile={this.props.collapseMobile}
          inAnonymousMode={this.props.inAnonymousMode}
        />
        <div className={classes.contentWrapper}>{this.props.children}</div>
      </div>
    );
  }
}

const styles = {
  contentWrapper: {
    position: "fixed",
    top: 64,
    right: 0,
    bottom: 0,
    left: 0,
    overflowY: "scroll",
  },
};

AppNavigation.defaultProps = {
  user: {},
  appDrawerLinks: [],
  appBarMenuItems: [],
};

export default withStyles(styles)(AppNavigation);
