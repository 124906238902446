import React from "react";
import Axios from "axios";
import log from "Lib/Log/log";
import Layout from "Layout/Layout";
import { Link as RouterLink } from "react-router-dom";
import withContext from "Lib/WithContext/withContext";
import { UserRoleContext } from "UserRoles/UserRoleProvider";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  withStyles,
  Grid,
  Link,
  Tabs,
  LinearProgress,
} from "@material-ui/core";
import OrganizationModel from "Settings/Organizations/OrganizationModel";
import OrganizationProfileModel from "../Settings/Organizations/OrganizationProfile/OrganizationProfileModel";
import OpportunityModel from "Opportunities/OpportunityModel";
import CandidateOpportunities from "Opportunities/CandidateOpportunities/CandidateOpportunities";
import MaxWidth from "Lib/MaxWidth/MaxWidth";
import { Breadcrumbs } from "@material-ui/core";

class OrganizationDetails extends React.Component {
  state = {
    loading: true,
    organization: {},
    organizationProfile: {},
    opportunities: [],
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    this.fetchOrganizationProfile();
  };

  fetchOrganizationProfile = () => {
    const { organizationUid } = this.props.match.params;
    return Axios.get(`/api/tb/organization_profiles/${organizationUid}`).then(
      (response) => {
        log("fetchData", response.data);
        const { data } = response;
        const organization = OrganizationModel.fromJSON(data.organization);
        const organizationProfile = OrganizationProfileModel.fromJSON(
          data.organization_profile,
        );
        this.setState({ organization, organizationProfile, loading: false });
      },
    );
  };

  render() {
    const { classes } = this.props;
    const { loading, organization, organizationProfile } = this.state;

    if (loading) {
      // return "Loading...";
      return <LinearProgress color="primary" />;
    }

    return (
      <Layout>
        <MaxWidth maxWidth={1200}>
          {/* <Grid container spacing={32}>
          <Grid item sm={12} md={8}>

          </Grid>
          <Grid item sm={0} md={4}>
            <Card className={classes.card}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Another Organization
                </Typography>
                <Typography component="p"></Typography>
              </CardContent>
            </Card>

            <Card className={classes.alsoAppliedCard}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  People also Applied
                </Typography>
                <Typography component="p"></Typography>
              </CardContent>
            </Card>

          </Grid>
        </Grid> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Breadcrumbs>
                <Link color="inherit" component={RouterLink} to="/companies">
                  Companies
                </Link>
                <Typography color="textPrimary">{organization.name}</Typography>
              </Breadcrumbs>
            </Grid>

            <Grid item xs={12}>
              <Card className={classes.card}>
                <div style={{ position: "relative" }}>
                  <CardMedia
                    className={classes.media}
                    image={
                      organizationProfile.bannerImageUrl ||
                      "/banner_image_default.png"
                    }
                    title="Contemplative Reptile"
                  />
                  <div className={classes.logoBox}>
                    <img
                      src={organizationProfile.profileImageUrl}
                      className={classes.logoBoxImage}
                    />
                  </div>
                </div>
                <CardContent style={{ marginTop: 20 }}>
                  <Typography gutterBottom variant="h2">
                    {organization.name}
                  </Typography>

                  <Typography
                    component="div"
                    dangerouslySetInnerHTML={{
                      __html: organizationProfile.description,
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <CandidateOpportunities
            publicOpportunities
            organizationUid={organization.uid}
            showFilters={false}
          />
        </MaxWidth>
      </Layout>
    );
  }
}

const styles = {
  card: {},
  media: {
    height: 200,
  },
  logoBox: {
    position: "absolute",
    height: 120,
    width: 120,
    backgroundColor: "white",
    bottom: -20,
    left: 50,
    padding: 5,
  },
  logoBoxImage: {
    width: "100%",
  },
  alsoAppliedCard: {
    marginTop: 32,
  },
};

export default withStyles(styles)(
  withContext(UserRoleContext, OrganizationDetails),
);
