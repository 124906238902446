import { FormControl, FormLabel, Grid } from "@material-ui/core";

import HtmlEditor from "Lib/HtmlEditor";
import {
  STEP_BASIC,
  STEP_ASSESSMENT,
} from "Opportunities/OrganizationOpportunities/EditOpportunity/constants";
import { StepBase } from "Opportunities/OrganizationOpportunities/EditOpportunity/components";

export default function StepDescription(props) {
  return (
    <StepBase
      goBackStep={() => props.onChange({ step: STEP_BASIC })}
      goNextStep={() => props.setNextStep(STEP_ASSESSMENT)}
    >
      <Grid item sm={12}>
        Description
      </Grid>
      <Grid item sm={12}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">Opportunity Description</FormLabel>
          <HtmlEditor
            value={props.description}
            onChange={(description) => props.onChange({ description })}
          />
        </FormControl>
      </Grid>

      <Grid item sm={12}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">Interview Process</FormLabel>
          <HtmlEditor
            value={props.interviewProcessDescription}
            onChange={(interviewProcessDescription) =>
              props.onChange({ interviewProcessDescription })
            }
          />
        </FormControl>
      </Grid>
    </StepBase>
  );
}
