export default (Component, hooks) => {
  return function WrappedComponent(props) {
    const hookValues = Object.entries(hooks).reduce(
      (initalValue, hook) => ({
        ...initalValue,
        [hook[0]]: hook[1](props),
      }),
      {},
    );
    return <Component {...props} {...hookValues} />;
  };
};
