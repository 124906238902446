import { State } from "country-state-city";
import { TextField, Grid } from "@material-ui/core";

import splitLocationToCityAndState from "Lib/Location/splitLocationToCityAndState.js";
import replaceCityOrStateInLocation from "Lib/Location/replaceCityOrStateInLocation.js";

const STATES = State.getStatesOfCountry("US");

export default function Location({ value, onChange }) {
  const location = splitLocationToCityAndState(value);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="normal"
          value={location.city}
          label="City"
          placeholder="City"
          onChange={(e) =>
            onChange(
              replaceCityOrStateInLocation(value, {
                city: e.target.value,
              }),
            )
          }
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          margin="normal"
          select
          SelectProps={{ native: true }}
          value={location.state || STATES[0].isoCode}
          label="State"
          onChange={(e) =>
            onChange(
              replaceCityOrStateInLocation(value, {
                state: e.target.value,
              }),
            )
          }
          fullWidth
        >
          {STATES.map((state) => (
            <option key={state.isoCode} value={state.isoCode}>
              {state.name}
            </option>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
}
