import { Switch, Route } from "react-router-dom";
import { UserRoleContext } from "../UserRoles/UserRoleProvider";
import withContext from "Lib/WithContext/withContext";
import React from "react";
import EditCandidateApplication from "./CandidateApplications/EditCandidateApplication";
import CandidateApplications from "./CandidateApplications/CandidateApplications";
import ThankYouForApplying from "./CandidateApplications/ThankYouForApplying";

const opportunities = (props) =>
  props.currentUserRole.isCandidateRole() ? (
    <Switch>
      <Route exact path="/applications" component={CandidateApplications} />
      <Route
        exact
        path="/applications/:uid"
        component={EditCandidateApplication}
      />
      <Route
        exact
        path="/applications/:uid/thank-you"
        component={ThankYouForApplying}
      />
    </Switch>
  ) : (
    "OrganizationApplications"
  );

export default withContext(UserRoleContext, opportunities);
