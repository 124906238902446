import React from "react";

import withContext from "Lib/WithContext/withContext";

import { UserRoleContext } from "UserRoles/UserRoleProvider";

export const AnonymousRoleContext = React.createContext({});

class AnonymousRoleProvider extends React.Component {
  render() {
    return this.props.children;
  }
}

export default withContext(UserRoleContext, AnonymousRoleProvider);
