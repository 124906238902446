import KnModel from "Lib/KnModel";
import { toHeaderCase } from "Lib/JsConvertCase/JsConvertCase";

export const WORKING_ARRANGEMENT_REMOTE = 'REMOTE';
export const WORKING_ARRANGEMENT_HYBRID = 'HYBRID';
export const WORKING_ARRANGEMENT_ONSITE = 'ONSITE';

export const WORKING_ARRANGEMENTS = [
  WORKING_ARRANGEMENT_REMOTE,
  WORKING_ARRANGEMENT_HYBRID,
  WORKING_ARRANGEMENT_ONSITE,
];

export default class OpportunityModel extends KnModel {
  static SIGNAL_SCORES = [
    ["0", "Not specified"],
    ["825", "825-850 (Level 10)"],
    ["800", "800-824 (Level 9)"],
    ["775", "775-799 (Level 8)"],
    ["750", "750-774 (Level 7)"],
    ["700", "700-749 (Level 6)"],
    ["650", "650-699 (Level 5)"],
    ["600", "600-649 (Level 4)"],
    ["500", "500-599 (Level 3)"],
    ["400", "400-499 (Level 2)"],
    ["300", "300-399 (Level 1)"],
  ];

  static ASSESSMENT_VENDORS = [
    "CodeSignal",
    "HackerRank",
    "Byteboard",
    "Other",
  ].map((vendorName) => [
    vendorName.toUpperCase().replace(/\s/g, "_"),
    vendorName,
  ]);

  constructor(props) {
    super(props);
  }

  isPublished() {
    return this.status === OpportunityModel.STATUS_PUBLISHED;
  }
  isDraft() {
    return this.status === OpportunityModel.STATUS_DRAFT;
  }
  isUnpublished() {
    return this.status === OpportunityModel.STATUS_UNPUBLISHED;
  }
  isArchived() {
    return this.status === OpportunityModel.STATUS_ARCHIVED;
  }

  locationsText() {
    return (
      `${this.locations || "No location specified"} (${ toHeaderCase(this.workingArrangement) })`
    );
  }
}

OpportunityModel.STATUS_DRAFT = "DRAFT";
OpportunityModel.STATUS_PUBLISHED = "PUBLISHED";
OpportunityModel.STATUS_UNPUBLISHED = "UNPUBLISHED";
OpportunityModel.STATUS_ARCHIVED = "ARCHIVED";
