import React, { Component } from 'react';
import axios from 'axios';
import OpportunityModel, { WORKING_ARRANGEMENTS } from './OpportunityModel';
import OpportunityCard from './CandidateOpportunities/OpportunityCard';
import { Grid, withStyles, Button, Typography, Paper, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Layout from 'Layout/Layout';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { JOB_TYPES } from './PublishedOpportunities';
import CandidateOpportunities from './CandidateOpportunities/CandidateOpportunities';
import { EDUCATION_LEVELS } from './OrganizationOpportunities/EditOpportunity/StepBasic';
import qs from 'qs';
import { toHeaderCase } from 'Lib/JsConvertCase/JsConvertCase';
import { TECHNICAL_CATEGORIES } from './OrganizationOpportunities/EditOpportunity/constants';

class OpportunitySearch extends Component {
  constructor(props) {
    super(props);

    const queryParams = qs.parse(window.location.search.slice(1));
    console.log('queryParams', queryParams)

    let jobTypes = queryParams.jobTypes || [];
    if (jobTypes && !Array.isArray(jobTypes)) {
      jobTypes = [jobTypes];
    }

    this.state = {
      opportunities: [],
      loading: false,
      error: null,
      workingArrangements: [],
      educationLevels: [],
      technicalCategories: [],
      jobTypes,
    };
  }


  componentDidMount() {
    return;

  }

  onClickArrayFilter = (e, stateKey) => {
    const currentValues = this.state[stateKey];
    const { name, checked } = e.target;

    let newValues;
    if (checked) {
      newValues = [...currentValues, name];
    } else {
      newValues = currentValues.filter(j => j !== name);
    }

    this.setState({ [stateKey]: newValues });
  }


  render() {
    const { classes } = this.props;
    const { loading, error, jobTypes, educationLevels, workingArrangements, technicalCategories } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error: {error}</div>;
    }


    return (

      <Layout title="Explore Opportunities">
        <Grid container style={{position: 'relative'}}>
          <Grid item xs={9}>
            <CandidateOpportunities
              OpportunitySearch
              applicationPlanJobTypes={jobTypes.length ? jobTypes : undefined}
              workingArrangements={workingArrangements.length ? workingArrangements : undefined}
              educationLevels={educationLevels.length ? educationLevels : undefined}
              technicalCategories={technicalCategories.length ? technicalCategories : undefined}
              rowsPerPage={10}
              rowsPerPageOptions={[10, 25, 50]}
            // organizationUid={organization.uid}
            />
          </Grid>

          <Grid item xs={3} className={classes.stickySidebar}>

            <div className={classes.filterContainer}>
              <Typography variant='caption' paragraph>Technical Category</Typography>
              {
                TECHNICAL_CATEGORIES.map((j) => (
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={<Checkbox size="small" name={j} />}
                      label={toHeaderCase(j)}
                      onChange={e => this.onClickArrayFilter(e, 'technicalCategories')}
                    />
                  </FormControl>
                ))
              }
            </div>


            <div className={classes.filterContainer}>
              <Typography variant='caption' paragraph>Job Type</Typography>
              {
                JOB_TYPES.map((j) => (
                  <FormControl fullWidth>
                    <FormControlLabel
                      fullWidth
                      control={<Checkbox size="small" name={j} checked={jobTypes.includes(j)} />}
                      label={j}
                      onChange={e => this.onClickArrayFilter(e, 'jobTypes')}
                    />
                  </FormControl>
                ))
              }
            </div>

            <div className={classes.filterContainer}>
              <Typography variant='caption' paragraph>Working Arrangement</Typography>
              {
                WORKING_ARRANGEMENTS.map((j) => (
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={<Checkbox size="small" name={j} />}
                      label={toHeaderCase(j)}
                      onChange={e => this.onClickArrayFilter(e, 'workingArrangements')}
                    />
                  </FormControl>
                ))
              }
            </div>

            <div className={classes.filterContainer}>
              <Typography variant='caption' paragraph>Education</Typography>
              {
                EDUCATION_LEVELS.map((j) => (
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={<Checkbox size="small" name={j} />}
                      label={toHeaderCase(j)}
                      onChange={e => this.onClickArrayFilter(e, 'educationLevels')}
                    />
                  </FormControl>
                ))
              }
            </div>
          </Grid>
        </Grid>
      </Layout>

    );
  }
}

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center', // This will center the background image,
    minHeight: 300,
    backgroundOpacity: 0.5,
    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.80), rgba(255, 255, 255, 0.80)), url(${process.env.PUBLIC_URL + '/hero_unit.webp'})`,
  },
  filterContainer: {
    padding: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  stickySidebar: {
    position: 'sticky',
    top: 0, // Adjust this value as needed
    height: '100%',
    width: '100%',
    paddingBottom: 149 + 50,
    paddingLeft: 8,
    borderLeft: `1px solid rgba(0, 0, 0, 0.05)`
  },


});


export default withStyles(styles)(OpportunitySearch);


