import React from "react";
import Axios from "axios";
import log from "Lib/Log/log";
import MaxWidth from "Lib/MaxWidth/MaxWidth";
import withContext from "Lib/WithContext/withContext";
import { Grid, Paper, Typography, LinearProgress } from "@material-ui/core";
import { OrganizationRoleContext } from "OrganizationRole/OrganizationRoleProvider";
import { withStyles } from "@material-ui/core/styles";
import AppNavigation from "AppNavigation/AppNavigation";
import ApplicationPlanModel from "ApplicationPlans/ApplicationPlanModel";
import AutoError from "Lib/AutoError/AutoError";
import formHelpers from "Lib/FormHelpers/formHelpers";
import { SnackbarContext } from "Lib/Snackbar/SnackbarProvider";
import OpportunityModel from "../../OpportunityModel";
import history from "history.js";

import StepBasic from "Opportunities/OrganizationOpportunities/EditOpportunity/StepBasic";
import StepDescription from "Opportunities/OrganizationOpportunities/EditOpportunity/StepDescription";
import StepAssessment from "Opportunities/OrganizationOpportunities/EditOpportunity/StepAssessment";
import StepReview from "Opportunities/OrganizationOpportunities/EditOpportunity/StepReview";
import {
  STEP_BASIC,
  STEP_DESCRIPTION,
  STEP_ASSESSMENT,
  STEP_REVIEW,
} from "Opportunities/OrganizationOpportunities/EditOpportunity/constants";
import { timeThursday } from "d3";

class EditOpportunity extends React.Component {
  constructor(props) {
    super(props);
    const uid = props.match.params.uid;

    this.state = {
      uid,
      loading: Boolean(uid),
      step: STEP_BASIC,
      applicationPlans: null,
      applicationPlanId: "",
      title: "",
      description: "",
      interviewProcessDescription: "",
      priorityDeadlineAt: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
      headcount: 0,
      workingArrangement: "",
      // isFullyRemote: false,
      // isRemoteEligible: false,
      locations: "",
      isAssessmentRequired: false,
      minimumCodeSignalScore: "0",
      educationLevel: "ANY",
      additionalAssessmentUrl: "",
      technicalCategory: "",
      salary: "",
      ...this.getStateFromUrl(),
    };
  }

  getStateFromUrl() {
    const href = new URL(window.location.href);
    let state = href.searchParams.get("state");

    if (!state) {
      return {};
    }

    state = JSON.parse(state);
    state.priorityDeadlineAt = new Date(state.priorityDeadlineAt);
    return state;
  }

  saveStateToUrl() {
    const href = new URL(window.location.href);
    let { applicationPlans, ...state } = this.state;
    href.searchParams.set("state", JSON.stringify(state));
    window.history.replaceState({}, "", String(href));
  }

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = async () => {
    log("fetchData");
    if (this.state.applicationPlans === null) {
      await this.fetchApplicationPlans();
    }
    if (this.state.loading) {
      await this.fetchOpportunity();
    }

    // Set default application plan id if not set
    this.setState(
      (state) => ({
        ...state,
        applicationPlanId:
          state.applicationPlanId || state.applicationPlans[0].id,
      }),
      // this.saveStateToUrl,
    );
  };

  fetchOpportunity = () => {
    if (window.location.pathname.endsWith("/new")) {
      log("Creating new opportunity");
      return Promise.resolve();
    }

    log("fetchOpportunity", this.state.uid);

    return Axios.get(`/api/tb/opportunities/${this.state.uid}`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      const opportunity = OpportunityModel.fromJSON(response.data.opportunity);
      log("opportunity", opportunity);
      this.onChange({ ...opportunity, loading: false });
    });
  };

  fetchApplicationPlans = () => {
    return Axios.get(`/api/tb/application_plans/upcoming`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchData", response.data);

      const applicationPlans = response.data.application_plans.map((p) =>
        ApplicationPlanModel.fromJSON(p),
      );

      this.setState({ applicationPlans });
    });
  };

  onChange = (o) => {
    this.setState(o,
      // this.saveStateToUrl
    );
  };

  setNextStep = async (stepName, validations) => {
    try {
      await formHelpers.validate(this.state, validations);
    } catch (error) {
      AutoError.catch.bind(this)(error);
      return;
    }
    this.onChange({ step: stepName });
  };

  onValidationError = (error) => {
    this.props.showSnackbar(error.message);
  };

  getOpportunityFromState = () => {
    const { state } = this;

    return new OpportunityModel({
      applicationPlanId: state.applicationPlanId,
      title: state.title,
      description: state.description,
      status: OpportunityModel.STATUS_DRAFT,
      priorityDeadlineAt: state.priorityDeadlineAt,
      interviewProcessDescription: state.interviewProcessDescription,
      headcount: state.headcount,
      locations: state.locations,
      workingArrangement: state.workingArrangement,
      educationLevel: state.educationLevel,
      isAssessmentRequired: state.isAssessmentRequired,
      minimumCodeSignalScore: state.minimumCodeSignalScore,
      isAdditionalAssessmentRequired: state.isAdditionalAssessmentRequired,
      additionalAssessmentVendor: state.additionalAssessmentVendor,
      additionalAssessmentUrl: state.additionalAssessmentUrl,
      technicalCategory: state.technicalCategory,
      salary: state.salary,
    });
  };

  onSave = () => {
    const opportunity = this.getOpportunityFromState();

    Axios[this.state.uid ? "put" : "post"](
      `/api/tb/opportunities${this.state.uid ? "/" + this.state.uid : ""}`,
      {
        opportunity,
      },
      { headers: this.props.getUserRoleAuthHeaders() },
    ).then((response) => {
      log("onSave", response.data);
      history.push(`/opportunities/${response.data.opportunity.uid}`);
    });
  };

  render() {
    if (this.loading || this.state.applicationPlans === null) {
      // return "Loading...";
      return <LinearProgress color="primary" />;
    }

    const { currentUserRole, classes, theme } = this.props;

    return (
      <AppNavigation title="Opportunities">
        <MaxWidth maxWidth={800}>
          <Grid container spacing={16} style={{ marginBottom: "16px" }}>
            <Grid item sm={12}>
              <Typography variant="h6">
                {this.state.uid ? "Update" : "Post"} your opportunity on
                Talentboard
              </Typography>
            </Grid>
          </Grid>
          {this.state.step === STEP_BASIC ? (
            <StepBasic
              {...this.state}
              onChange={this.onChange}
              setNextStep={this.setNextStep}
            />
          ) : null}
          {this.state.step === STEP_DESCRIPTION ? (
            <StepDescription
              {...this.state}
              onChange={this.onChange}
              setNextStep={this.setNextStep}
            />
          ) : null}
          {this.state.step === STEP_ASSESSMENT ? (
            <StepAssessment
              {...this.state}
              onChange={this.onChange}
              setNextStep={this.setNextStep}
            />
          ) : null}
          {this.state.step === STEP_REVIEW ? (
            <StepReview
              {...this.state}
              onChange={this.onChange}
              setNextStep={this.setNextStep}
              onSave={this.onSave}
              opportunity={this.getOpportunityFromState()}
              organization={this.props.organization}
              getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
            />
          ) : null}

        </MaxWidth>
      </AppNavigation>
    );
  }
}

const styles = (theme) => ({
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: "center",
  },
});

export default withStyles(styles)(
  withContext(OrganizationRoleContext, SnackbarContext, EditOpportunity),
);
