import React from "react";
import withContext from "Lib/WithContext/withContext";
import { ThemeContext } from "Lib/Theme/ThemeProvider";
import { UserRoleContext } from "../UserRoles/UserRoleProvider";
import { OrganizationRoleContext } from "../OrganizationRole/OrganizationRoleProvider";
import AppBarAndDrawer from "./AppBarAndDrawer/AppBarAndDrawer";

// appDrawerLinks
import DateRangeIcon from "@material-ui/icons/DateRange";
import DashboardIcon from "@material-ui/icons/Dashboard";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublicIcon from "@material-ui/icons/Public";
import ListIcon from "@material-ui/icons/List";
import HistoryIcon from "@material-ui/icons/History";
import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/PeopleOutline";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import BarChartIcon from "@material-ui/icons/BarChart";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";

import userRoleNavItem from "./userRoleNavItem";
import appBarMenuItems from "./appBarMenuItems";
import * as Ability from "../Ability/Ability";

import history from "history.js";

class OrganizationNavigation extends React.PureComponent {
  render() {
    const { currentUserRole } = this.props;
    // log('currentUserRole', currentUserRole);

    let appDrawerLinks = [userRoleNavItem(this.props)];

    appDrawerLinks.push({
      primaryText: "Dashboard",
      path: "/",
      icon: <DashboardIcon />,
      exactPathMatch: true,
      isActive: (l) => window.location.pathname === "/dashboard",
    });

    appDrawerLinks.push({ divider: true });

    if (currentUserRole.roleHasAbility("VIEW_APPLICATION_PLANS")) {
      appDrawerLinks.push({
        primaryText: "Application Plans",
        path: "/application_plans",
        icon: <DateRangeIcon />,
      });
    }

    if (currentUserRole.roleHasAbility("VIEW_OPPORTUNITIES")) {
      appDrawerLinks.push({
        primaryText: "Opportunities",
        path: "/opportunities",
        icon: <BusinessCenterIcon />,
      });
    }

    appDrawerLinks = appDrawerLinks.concat([
      {
        primaryText: "Talent Pool",
        path: "/talent-pool",
        icon: <WbIncandescentIcon />,
      },
      { primaryText: "Insights", path: "/insights", icon: <BarChartIcon /> },
      { divider: true },
    ]);

    if (currentUserRole.roleHasAbility(Ability.VIEW_ORGANIZATION_PROFILE)) {
      // appDrawerLinks.push({ primaryText: 'Organization Profile', path: `/companies/${this.props.organization.uid}`, icon: <BusinessIcon/>  });
      appDrawerLinks.push({
        primaryText: "Company Profile",
        path: `/organizations/${this.props.organization.uid}/profile`,
        icon: <BusinessIcon />,
      });
    }

    if (currentUserRole.roleHasAbility(Ability.VIEW_FAMILY_ORGANIZATIONS)) {
      appDrawerLinks.push({
        primaryText: "Organizations",
        path: "/organizations",
        icon: <ListIcon />,
      });
    }

    if (
      currentUserRole.roleHasAtLeastOneAbility(
        Ability.VIEW_ORGANIZATION_USERS,
        Ability.EDIT_ORGANIZATION_USERS,
      )
    ) {
      appDrawerLinks.push({
        primaryText: "Users",
        path: "/users",
        icon: <PeopleIcon />,
      });
    }

    if (currentUserRole.roleHasAbility(Ability.VIEW_ORGANIZATION_ACTIVITY)) {
      appDrawerLinks.push({
        primaryText: "Activity",
        path: "/activity",
        icon: <HistoryIcon />,
      });
    }

    appDrawerLinks.push({
      primaryText: "Account",
      path: "/account",
      icon: <LockOpenIcon />,
    });

    // if (!isStandalone() && ( !this.props.theme || this.props.theme.shouldShowAddToHomeScreen !== false )) {
    //   appDrawerLinks.push({ primaryText: 'Add to Home Screen', path: '/add_to_home_screen', icon: <GetAppIcon /> });
    // }

    appDrawerLinks.push({ divider: true });
    appDrawerLinks.push({
      primaryText: "Sign out",
      path: "/sign_out",
      icon: <ExitToAppIcon />,
    });

    return (
      <AppBarAndDrawer
        title={this.props.title}
        appDrawerLinks={appDrawerLinks}
        appBarMenuItems={appBarMenuItems}
      >
        {this.props.children}
      </AppBarAndDrawer>
    );
  }
}

export default withContext(
  ThemeContext,
  OrganizationRoleContext,
  OrganizationNavigation,
);
