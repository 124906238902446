import React from "react";
import Axios from "axios";
import { Link as RouterLink } from "react-router-dom";
import withContext from "Lib/WithContext/withContext";
import log from "Lib/Log/log";
import BusinessIcon from "@material-ui/icons/Business";
import {
  withStyles,
  Grid,
  Typography,
  LinearProgress,
  TextField,
  Link,
} from "@material-ui/core";

import PromiseButton from "Lib/PromiseButton/PromiseButton";
import { UserRoleContext } from "UserRoles/UserRoleProvider";
import OrganizationUserCard from "Settings/Users/OrganizationUsers/OrganizationUserCard/OrganizationUserCard";
import OrganizationUserInvitationCard from "Settings/UserInvitations/OrganizationUserInvitations/OrganizationUserInvitationCard/OrganizationUserInvitationCard";
import OrganizationModel from "../OrganizationModel";
import OrganizationTable from "../OrganizationTable/OrganizationTable";
import Layout from "Layout/Layout";
import * as ability from "Ability/Ability";

class OrganizationDetails extends React.Component {
  state = {
    loading: true,
    organization: null,
    defaultCompanySignUpCode: null,
    lastUserInvitedAt: null,
    organizationName: "",
  };

  componentDidMount = () => {
    this.fetchOrganization();
  };

  componentDidUpdate = (oldProps) => {
    if (this.props.match.params.id !== oldProps.match.params.id) {
      this.fetchOrganization();
    }
  };

  fetchOrganization = () => {
    log(`OrganizationDetails.fetchOrganization ${this.props.match.params.id}`);
    return Axios.get(`/api/core/organizations/${this.props.match.params.id}`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log(
        `OrganizationDetails.fetchOrganization ${this.props.match.params.id} response`,
        response,
      );
      const organization = OrganizationModel.fromJSON(
        response.data.organization,
      );
      this.setState({
        organization,
        organizationName: organization.name,
        loading: false,
      });
    });
  };

  async saveOrganizationName() {
    this.setState({ loading: true });
    await Axios.put(
      `/api/tb/organization_profiles/${this.state.organization.uid}`,
      {
        organization: { name: this.state.organizationName },
      },
      {
        headers: this.props.getUserRoleAuthHeaders(),
      },
    );
    await this.fetchOrganization();
  }

  onInviteUser = () => {
    this.setState({ lastUserInvitedAt: Date.now().toString() });
  };

  render() {
    const { classes, currentUserRole } = this.props;
    const { loading, organization, lastUserInvitedAt, organizationName } =
      this.state;

    if (loading) {
      return <LinearProgress />;
    }

    return (
      <Layout title={organization.name}>
        <Grid spacing={2} container>
          <Grid item xs={12}>
            <Grid container>
              {currentUserRole.roleHasAbility(
                ability.EDIT_ORGANIZATION_PROFILE,
              ) ? (
                <>
                  <Grid item>
                    <TextField
                      value={organizationName}
                      onChange={(e) =>
                        this.setState({ organizationName: e.target.value })
                      }
                      InputProps={{
                        classes: { root: classes.nameTextField },
                      }}
                      placeholder="Organization Name"
                    />
                  </Grid>
                  {organizationName !== organization.name && (
                    <Grid item>
                      <PromiseButton
                        onProcess={() => this.saveOrganizationName()}
                      >
                        Save
                      </PromiseButton>
                    </Grid>
                  )}
                  <Grid
                    item
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to={`/organizations/${organization.uid}/profile/edit`}
                    >
                      <BusinessIcon />
                    </Link>
                  </Grid>
                </>
              ) : (
                <Typography variant="h5">{organization.name || ""}</Typography>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <OrganizationTable
              organization={organization}
              showDescendantsInfo={true}
            />
          </Grid>

          <Grid item xs={12}>
            <OrganizationUserCard
              organization={organization}
              onInviteUser={this.onInviteUser}
              showDescendantsInfo={true}
            />
          </Grid>

          <Grid item xs={12}>
            <OrganizationUserInvitationCard
              organization={organization}
              lastUserInvitedAt={lastUserInvitedAt}
              newInvitationPath="/organizations/:id/user_invitations/new"
              showDescendantsInfo={true}
            />
          </Grid>
        </Grid>
      </Layout>
    );
  }
}

const styles = (theme) => ({
  nameTextField: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1.5rem",
    fontFamily: '"Roboto Mono", sans-serif',
    fontWeight: 400,
    lineHeight: 1.33,
    letterSpacing: "0em",
  },
});

export default withStyles(styles)(
  withContext(UserRoleContext, OrganizationDetails),
);
