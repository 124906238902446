import React from "react";
import { Paper, Button, IconButton, withStyles } from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import paperPadding from "paperPadding";

class ImageUpload extends React.Component {
  state = {
    imagePreviewUrl: "",
  };

  componentDidMount = () => {
    this.setState({ imagePreviewUrl: this.props.existingImageUrl });
  };

  onChangeImage = (e) => {
    e.preventDefault();

    const reader = new FileReader();
    let file = e.target.files[0];

    if (!file) {
      return;
    }

    reader.onloadend = () => {
      const imagePreviewUrl = reader.result;

      this.setState({
        file: file,
        imagePreviewUrl,
      });

      this.props.onChangeImage(file, imagePreviewUrl);
    };

    reader.readAsDataURL(file);
  };

  render() {
    const { classes, src, className } = this.props;
    const { imagePreviewUrl } = this.state;

    return (
      <div style={this.props.style || {}}>
        <form id={`form-${this.props.inpudId}`}>
          <input
            accept="image/*"
            className={classes.input}
            id={this.props.id || undefined}
            type="file"
            disabled={this.props.disabled}
            onChange={(e) => this.onChangeImage(e)}
          />
          <label htmlFor={this.props.id}>
            <Button
              disabled={this.props.disabled}
              classes={{ root: classes.uploadButton }}
              component="span"
            >
              {this.props.children ||
                (imagePreviewUrl ? (
                  <img src={imagePreviewUrl} className={className} />
                ) : src ? (
                  <img src={src} className={className} />
                ) : (
                  <Add className={classes.uploadButtonIcon} />
                ))}
            </Button>
          </label>
        </form>
      </div>
    );
  }
}

const styles = (theme) => ({
  paper: {
    padding: paperPadding,
  },
  input: {
    display: "none",
  },
  uploadButton: {
    // position: 'absolute',
    // top: 0,
    // right: 0,
    // color: 'black'
    // padding: 30,
    // margin: 10,
    border: "1px dashed #ccc",
    backgroundColor: "transparent",
    width: "100%",
    // border: 0,
    height: "100%",
    padding: 0,
    margin: 0,
    "&:hover": {
      border: "1px solid #ccc",
    },
    "&:focus": {
      border: "1px solid #ccc",
    },
  },
  uploadButtonIcon: {
    fontSize: 50,
  },
  image: {
    maxWidth: "100%",
  },
});

ImageUpload.defaultProps = {
  inputId: "contained-button-file",
};

export default withStyles(styles)(ImageUpload);
