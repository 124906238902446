import React from "react";
import Axios from "axios";
import log from "Lib/Log/log";
import withContext from "Lib/WithContext/withContext";
import { Grid, TablePagination, Typography } from "@material-ui/core";
import { CandidateRoleContext } from "CandidateRole/CandidateRoleProvider";
import { withStyles } from "@material-ui/core/styles";
import AppNavigation from "AppNavigation/AppNavigation";
import OrganizationModel from "OrganizationRole/OrganizationModel";
import CandidateOrganizationModel from "CandidateRole/CandidateOrganizationModel";
import OrganizationCard from "./OrganizationCard";
import Layout from "Layout/Layout";

const tabs = ["Opportunities", "Companies"];

class OrganizationsWithOpportunities extends React.Component {
  state = {
    loading: true,
    organizations: [],
    candidateOrganizations: [],
    total: 0,
    rowsPerPage: 24,
    page: 0,
    tabIndex: 0,
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    return this.fetchOrganizations()
      .then(() => this.fetchCandidateOrganizations())
      .then(() => {
        this.setState({ loading: false });
      });
  };

  fetchOrganizations = () => {
    return Axios.get(`/api/tb/opportunities/published/organizations`, {
      params: {
        limit: this.state.rowsPerPage,
        page: this.state.page,
      },
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchData", response.data);

      const organizations = response.data.organizations.map((p) =>
        OrganizationModel.fromJSON(p),
      );
      const total = response.data.total;

      this.setState({ organizations, total, loading: false });
    });
  };

  fetchCandidateOrganizations = () => {
    return Axios.get(`/api/tb/candidate_organizations`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchData", response.data);

      const candidateOrganizations = response.data.candidate_organizations.map(
        (p) => CandidateOrganizationModel.fromJSON(p),
      );

      this.setState({ candidateOrganizations });
    });
  };

  onClickFavorite = (organization) => {
    log("onClickFavorite", organization.uid);

    const { candidateOrganizations } = this.state;

    const isFavorite =
      candidateOrganizations.findIndex((o) => o.uid === organization.uid) >= 0;
    const method = isFavorite
      ? this.deleteCandidateOrganization
      : this.createCandidateOrganization;

    return method(organization).then((response) => {
      log("onClickFavorite", response);
      return this.fetchCandidateOrganizations();
    });
  };

  createCandidateOrganization = (organization) => {
    log("createCandidateOrganization", organization.uid);
    return Axios.post(
      `/api/tb/candidate_organizations/organizations/${organization.uid}`,
      {},
      { headers: this.props.getUserRoleAuthHeaders() },
    );
  };

  deleteCandidateOrganization = (organization) => {
    return CandidateOrganizationModel.delete(
      organization.uid,
      this.props.getUserRoleAuthHeaders(),
    );
  };

  render() {
    const { organizations, candidateOrganizations } = this.state;
    const candidateOrganizationUids = candidateOrganizations.map((o) => o.uid);

    return (
      <Layout title="Companies">
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Typography variant="h4" gutterBottom>
              Companies
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              View the companies that have current or past opportunities on
              Talenboard.
            </Typography>
          </Grid>
          {[...Array(10).keys()].map((_, index) => (
            <Grid item key={index}>
              <Grid container spacing={2}>
                {organizations.slice(index * 3, index * 3 + 3).map((o, i) => (
                  <Grid item xs={12} sm={6} md={4} key={i}>
                    <OrganizationCard
                      organization={o}
                      onClickFavorite={this.onClickFavorite}
                      isFavorite={candidateOrganizationUids.includes(o.uid)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <table>
              <tbody>
                <tr>
                  <TablePagination
                    style={{ borderBottom: 0 }}
                    page={this.state.page}
                    rowsPerPage={this.state.rowsPerPage}
                    rowsPerPageOptions={[12, 24, 48]}
                    onChangeRowsPerPage={(e) =>
                      this.setState(
                        { rowsPerPage: e.target.value },
                        this.fetchData,
                      )
                    }
                    count={this.state.total}
                    onChangePage={(_, page) =>
                      this.setState({ page }, this.fetchData)
                    }
                    labelRowsPerPage="Per page"
                  />
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </Layout>
    );
  }
}

const styles = (theme) => ({
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: "center",
  },
});

export default withStyles(styles)(
  withContext(CandidateRoleContext, OrganizationsWithOpportunities),
);
