import React from "react";
import UserInvitationTable from "../../UserInvitationTable/UserInvitationTable";

class OrganizationInvitationCard extends React.Component {
  render() {
    const { organization, showDescendantsInfo } = this.props;

    return (
      <UserInvitationTable
        newInvitationPath={this.props.newInvitationPath}
        roleType="Organization"
        roleTypeId={organization.id}
        roleTypePath="organizations"
        roleTypeName={organization.name || ""}
        showDescendantsInfo={showDescendantsInfo}
      />
    );
  }
}

OrganizationInvitationCard.defaultProps = {
  showDescendantsInfo: false,
};

export default OrganizationInvitationCard;
