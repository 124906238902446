import JsConvertCase from "./JsConvertCase/JsConvertCase";

export default class KnModel {
  constructor(props) {
    for (let k in props) {
      this[k] = props[k];
    }
  }

  static fromJSON(json) {
    const props = {};

    Object.keys(json).forEach((k) => {
      let v = json[k];

      if (k.endsWith("_at") && v) {
        v = new Date(v + " UTC");
      }

      // if (k.endsWith('_data') && v) {
      //   v = JSON.parse(v);
      // }

      props[JsConvertCase.toCamelCase(k)] = v;
    });
    return new this(props);
  }

  toJSON() {
    const skipKeys = this.constructor.skipKeys || [];
    const json = {};

    Object.keys(this).forEach((k) => {
      if (skipKeys.includes(k)) {
        return;
      }

      if (typeof this === "function") {
        return;
      }

      let value = this[k];
      if (k.endsWith("At") && value?.toISOString) {
        value = value.toISOString();
        value = value.slice(0, value.length - 1);
      }

      json[JsConvertCase.toSnakeCase(k)] = value;
    });

    return json;
  }

  update(newValues) {
    return new this.constructor({
      ...this,
      ...newValues,
    });
  }
}
