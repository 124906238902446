import { Route, Switch } from "react-router-dom";
import { UserRoleContext } from "UserRoles/UserRoleProvider";
import withContext from "Lib/WithContext/withContext";
import React from "react";
import CandidateApplicationPlans from "./CandidateApplicationPlans/CandidateApplicationPlans";
import CandidateApplicationPlanDetail from "./CandidateApplicationPlans/CandidateApplicationPlanDetail";
import OrganizationApplicationPlanDetail from "./OrganizationApplicationPlans/OrganizationApplicationPlanDetail";
import EditOrganizationApplicationPlanDetail from "./OrganizationApplicationPlans/EditOrganizationApplicationPlanDetail";
import AddOrganizationApplicationPlanDetail from "./OrganizationApplicationPlans/AddOrganizationApplicationPlanDetail";
import OrganizationApplicationPlans from "./OrganizationApplicationPlans/OrganizationApplicationPlans";
import AppNavigation from "AppNavigation/AppNavigation";
import { Grid } from "@material-ui/core";

const applicationPlans = (props) => {
  if (!props.currentUserRole.id || props.currentUserRole.isCandidateRole()) {
    return (
      <Switch>
        <Route
          exact
          path="/application_plans"
          render={(props) => <CandidateApplicationPlans {...props} />}
        />

        <Route
          path="/application_plans/:uid"
          render={(props) => <CandidateApplicationPlanDetail {...props} />}
        />
      </Switch>
    );
  }

  return (
    <AppNavigation title="Application Plans">
      <Grid container spacing={2}>
        <Grid
          item
          sm={12}
        >
          <Switch>
            <Route
              exact
              path="/application_plans/new"
              render={(props) => (
                <AddOrganizationApplicationPlanDetail {...props} />
              )}
            />

            <Route
              exact
              path="/application_plans"
              render={(props) => (
                <OrganizationApplicationPlans
                  {...props}
                  applicationPlans={applicationPlans}
                />
              )}
            />

            <Route
              path="/application_plans/:uid/edit"
              render={(props) => (
                <EditOrganizationApplicationPlanDetail {...props} />
              )}
            />
            <Route
              path="/application_plans/:uid"
              render={(props) => (
                <OrganizationApplicationPlanDetail
                  {...props}
                  applicationPlans={applicationPlans}
                />
              )}
            />
          </Switch>
        </Grid>
      </Grid>
    </AppNavigation>
  );
};

export default withContext(UserRoleContext, applicationPlans);
