import React from "react";
import { Grid, Button, withStyles } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { InsertDriveFile } from "@material-ui/icons";
import "./FileUpload.css";

class FileUpload extends React.Component {
  state = {
    file: null,
    imagePreviewUrl: "",
  };

  componentDidUpdate = (oldProps) => {
    if (this.props.mediaItem != oldProps.mediaItem) {
      this.setState({ file: null, imagePreviewUrl: "" });
    }
  };

  onChangeFile = (e) => {
    e.preventDefault();

    const reader = new FileReader();
    let file = e.target.files[0];

    if (!file) {
      return;
    }

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: file.type.includes("image/") ? reader.result : "",
      });
    };

    reader.readAsDataURL(file);

    return this.props.onChangeFile(file);
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <form>
          <input
            accept="*"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={(e) => this.onChangeFile(e)}
            disabled={this.props.disabled ? true : undefined}
          />

          <label htmlFor="contained-button-file">
            <Button
              classes={{ root: classes.uploadButton }}
              component="div"
              variant="outlined"
              color="primary"
              disabled={this.props.disabled ? true : undefined}
            >
              {this.state.imagePreviewUrl ? (
                <Grid item xs={12}>
                  <img
                    src={this.state.imagePreviewUrl}
                    className={classes.image}
                  />
                </Grid>
              ) : this.state.file ? (
                <InsertDriveFile />
              ) : (
                this.props.message || `Select a file to upload`
                // <Add className={classes.uploadButtonIcon} />
              )}
            </Button>
          </label>
        </form>
      </div>
    );
  }
}

const styles = (theme) => ({
  input: {
    display: "none",
  },
  uploadButton: {
    padding: 30,
    margin: 10,
    border: "1px dashed #ccc",
  },
  uploadButtonIcon: {
    fontSize: 50,
  },
  image: {
    maxWidth: "100%",
  },
});

FileUpload.defaultProps = {
  onChangeFile: () => {},
  mediaItem: "",
  disbaled: false,
};

export default withStyles(styles)(FileUpload);
