import React from "react";
import Axios from "axios";
import log from "Lib/Log/log";
import formHelpers from "Lib/FormHelpers/formHelpers";
import AutoError from "Lib/AutoError/AutoError";
import withContext from "Lib/WithContext/withContext";
import PromiseButton from "Lib/PromiseButton/PromiseButton";
import { SnackbarContext } from "Lib/Snackbar/SnackbarProvider";
// Context
import { UserRoleContext } from "UserRoles/UserRoleProvider";
// Material
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const DEFAULT_STATE = {
  loading: true,
  email: "",
  possibleRoles: [],
  roleId: "",
};

class NewUserInvitationDialog extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.getPossibleRoles();
  };

  getPossibleRoles = () => {
    const { roleTypePath, roleTypeId } = this.props;
    log("getPossibleRoles", roleTypeId);

    return Axios.get(`/api/core/${roleTypePath}/${roleTypeId}/roles`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("getPossibleRoles response", response);
      const possibleRoles = response.data.roles;
      this.setState({ possibleRoles, loading: false });
    });
  };

  inviteUser = () => {
    const { roleTypeId, roleType } = this.props;
    const { email, roleId } = this.state;

    return Axios.post(
      "/api/core/user_invitations",
      {
        email,
        role_id: roleId,
        role_type: roleType,
        role_type_id: roleTypeId,
      },
      { headers: this.props.getUserRoleAuthHeaders() },
    ).then((response) => {
      log("inviteUser response", response);
      this.props.onInviteUser();
      this.onClose();
    });
  };

  onProcess = () => {
    log("onProcess", this.state);
    return this.validate()
      .then(this.inviteUser)
      .catch(AutoError.catch.bind(this));
  };

  onValidationError = (error) => {
    log("error", error);
    this.props.showSnackbar(error.message);
  };

  onError = (error) => {
    log("error", error);
    this.props.showSnackbar(error.response.data.message);
  };

  validate = () => {
    log(this.state.role_type_id);
    const baseValidations = {
      email: {
        presence: { message: "Please enter the email for the user." },
        email: { message: "Please enter a valid email for the user." },
      },
      roleId: {
        presence: { message: "Please select the user's role." },
      },
    };
    return formHelpers.validate(this.state, baseValidations);
  };

  onClose = () => {
    this.setState({ email: "", roleId: "" }, this.props.onClose);
  };

  render() {
    const { roleId, email, possibleRoles } = this.state;

    return (
      <Dialog open={this.props.open} fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title">Invite a New User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the following information to invite a new user.
          </DialogContentText>

          <TextField
            onChange={(e) => this.setState({ email: e.target.value })}
            fullWidth
            label="Email"
            margin="normal"
            value={email}
            name="email"
          />

          <TextField
            select
            fullWidth
            label={`${this.props.roleType} Role`}
            value={roleId}
            onChange={(e) => this.setState({ roleId: e.target.value })}
            name="role"
          >
            {possibleRoles.map((r) => (
              <MenuItem key={r.id} value={r.id} name={r.id}>
                {r.name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.onClose} variant="outlined" color="primary">
            Cancel
          </Button>
          <PromiseButton onProcess={this.onProcess}>Send</PromiseButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withContext(
  UserRoleContext,
  SnackbarContext,
  NewUserInvitationDialog,
);
