import KnModel from "Lib/KnModel";

export default class OrganizationProfileModel extends KnModel {
  static defaultBannerImageUrl =
    "https://storage.googleapis.com/talentboard-staging-public/organizations/orgranization-profile-image-banner-default";
  static defaultProfileImageUrl =
    "https://storage.googleapis.com/talentboard-staging-public/organizations/orgranization-profile-image-default";

  static fromJSON(json) {
    const self = super.fromJSON(json);
    return self.update({
      bannerImageUrl:
        self.bannerImageUrl || OrganizationProfileModel.defaultBannerImageUrl,
      profileImageUrl:
        self.profileImageUrl || OrganizationProfileModel.defaultProfileImageUrl,
    });
  }
}
