import React from "react";
import Axios from "axios";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import history from "../history";
import { Link as RouterLink } from "react-router-dom";
import withContext from "Lib/WithContext/withContext";
import formHelpers from "Lib/FormHelpers/formHelpers";
import AutoError from "Lib/AutoError/AutoError";
import MaxWidth from "Lib/MaxWidth/MaxWidth";
import log from "Lib/Log/log";
import PromiseButton from "Lib/PromiseButton/PromiseButton";
import { AuthContext } from "Lib/Auth/AuthProvider";
import { SnackbarContext } from "Lib/Snackbar/SnackbarProvider";
import CredentialsModel from "Lib/Auth/CredentialsModel";
import { Grid, Link, TextField, Typography } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AppTitleLogo from "AppNavigation/AppTitleLogo";

import netflixLogo from "images/brands/logo_netflix.png";
import rokuLogo from "images/brands/logo_roku.png";
import netAppLogo from "images/brands/logo_netapp.png";
import ebayLogo from "images/brands/logo_ebay.png";
import figmaLogo from "images/brands/logo_figma.png";
import googleLogo from "images/brands/logo_google.png";
import graylockLogo from "images/brands/logo_greylock.png";
import shopifyLogo from "images/brands/logo_shopify.png";

class SignUp extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  onProcess = () => {
    return this.validate()
      .then(this.postSignUpData)
      .then((credentials) => this.signInAndRedirect(credentials))
      .catch((error) => {
        console.log("error.response", error.response);
        if (error.response && error.response.status === 409) {
          this.props.showSnackbar(error.response.data.message);
          return;
        } else if (error.response && error.response.status === 400) {
          this.props.showSnackbar(error.response.data.message);
          return;
        }

        throw error;
      })
      .catch(AutoError.catch.bind(this));
  };

  validate = () => {
    const { password } = this.state;
    let validations = {
      firstName: {
        presence: { message: "Please enter your first name." },
      },
      lastName: {
        presence: { message: "Please enter your last name." },
      },
      email: {
        presence: { message: "Please enter your email address." },
        email: { message: "Please enter a valid email address." },
      },
      password: {
        presence: { message: "Please enter a password." },
        length: {
          atLeast: 8,
          message: "Password must be at least 8 characters.",
        },
      },
      confirmPassword: {
        presence: { message: "Please confirm your password." },
        equalTo: { value: password, message: "Passwords must match." },
      },
    };

    return formHelpers.validate(this.state, validations);
  };

  onValidationError = (error) => {
    log("error", error);
    this.props.showSnackbar(error.message);
  };

  postSignUpData = () => {
    console.log("this", this);
    return Axios.post("/api/core/sign_up", {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      confirm_password: this.state.confirmPassword,
    })
      .then((response) => {
        log("postData", response);
        const credentials = CredentialsModel.fromJSON(
          response.data.credentials,
        );
        log("credentials", credentials);
        return credentials;
      })
      .catch((error) => {
        throw new PostSignUpDataError(error);
      });
  };

  onPostSignUpDataError = (error) => {
    log("error.response", error.response);
    this.props.showSnackbar(
      error.response && error.response.data
        ? error.response.data.message
        : "Something went wrong.",
    );
  };

  signInAndRedirect = (credentials) => {
    this.props.setCredentials(credentials);
    history.push("/");
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Paper className={classes.paperRoot}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              style={{ padding: 0, flexBasis: "39%" }}
              className={classes.descriptionItem}
            >
              <AppTitleLogo
                textStyle={{
                  fill: "rgb(0, 76, 69)",
                }}
                svgStyle={{ width: "200px", marginBottom: "20px" }}
              />
              <Grid container>
                {[
                  [
                    "Get started quickly",
                    "Instantly apply to over 50 companies with a candidate-friendly process.",
                  ],
                  [
                    "Support any business model",
                    "Internships, New Grads, Apprenticeships, and more — all with one unified platform.",
                  ],
                  [
                    "Join millions of candidates",
                    "Talentboard is trusted by ambitious students & early career candidates and emerging talent from every background.",
                  ],
                ].map(([title, description], index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={1}>
                      <CheckCircleIcon
                        className={classes.checkCircleIcon}
                        style={{ width: 18 }}
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography className={classes.checkTitle}>
                        {title}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={11} className={classes.checkDescription}>
                      <Typography>{description}</Typography>
                    </Grid>
                  </React.Fragment>
                ))}
                <Grid item xs={12}>
                  <Typography className={classes.invitation}>
                    Connect with multiple Member Companies with One Click
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {[
                      netflixLogo,
                      rokuLogo,
                      netAppLogo,
                      ebayLogo,
                      figmaLogo,
                      googleLogo,
                      graylockLogo,
                      shopifyLogo,
                    ].map((image, index) => (
                      <Grid item xs={3} key={index}>
                        <img
                          src={image}
                          alt="logo"
                          style={{ maxWidth: 100, maxHeight: 100 }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={6}
              className={classes.formItem}
              style={{ padding: 32 }}
            >
              <Typography className={classes.formTitle}>
                Create your Talentboard Account
              </Typography>

              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <Typography>Email</Typography>
                  <TextField
                    className={classes.formTextField}
                    name="email"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    onBlur={() => formHelpers.trim.call(this, "email")}
                    type="email"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12}>
                  <Typography>First Name</Typography>
                  <TextField
                    className={classes.formTextField}
                    name="first-name"
                    value={this.state.firstName}
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                    onBlur={() => formHelpers.trim.call(this, "firstName")}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12}>
                  <Typography>Last Name</Typography>
                  <TextField
                    className={classes.formTextField}
                    name="last-name"
                    value={this.state.lastName}
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                    onBlur={() => formHelpers.trim.call(this, "lastName")}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12}>
                  <Typography>Password</Typography>
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    className={classes.formTextField}
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                    onBlur={() => formHelpers.trim.call(this, "password")}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    helperText="At least eight characters."
                  />
                </Grid>

                <Grid item sm={6}>
                  <TextField
                    className={classes.formTextField}
                    label="Confirm Password"
                    name="confirm-password"
                    type="password"
                    value={this.state.confirmPassword}
                    onChange={(e) =>
                      this.setState({ confirmPassword: e.target.value })
                    }
                    onBlur={() =>
                      formHelpers.trim.call(this, "confirmPassword")
                    }
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item sm={12}>
                  <PromiseButton onProcess={this.onProcess} fullWidth>
                    Create Account
                  </PromiseButton>
                </Grid>

                <Grid item sm={12}>
                  <Typography
                    className={classes.signInLinkRoot}
                    style={{ textAlign: "center" }}
                  >
                    Have an account?&nbsp;
                    <Link
                      component={RouterLink}
                      to="/sign_in"
                      className={classes.signInLink}
                    >
                      Sign in
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Typography className={classes.termsText}>
          By signing up, you agree to our{" "}
          <Link component="a" href="/terms-of-service" target="_blank">
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link component="a" href="/privacy-policy" target="_blank">
            Privacy Policy
          </Link>
        </Typography>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    padding: 64,
    paddingBottom: 50,
  },
  paperRoot: {
    padding: 50,
    borderRadius: 6,
  },
  heading: {
    textAlign: "center",
  },
  signInLinkRoot: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  signInLink: {
    textTransform: "uppercase",
    fontWeight: "bold",
    textAlign: "center",
  },
  termsText: {
    textAlign: "center",
    marginTop: 15,
  },
  formItem: {
    border: "1px solid rgba(0,0,0,0.15)",
  },
  formTitle: {
    fontFamily: '"Montserrat", Sans-serif',
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginBottom: 20,
    marginTop: 30,
  },
  formTextField: {
    marginTop: 0,
  },
  descriptionItem: {
    marginBottom: 32,
    marginTop: 32,
    marginLeft: 32,
    marginRight: 90,
  },
  checkCircleIcon: {
    color: theme.palette.primary.main,
  },
  checkTitle: {
    fontFamily: '"Nunito", Sans-serif',
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  checkDescription: {
    marginBottom: 20,
  },
  invitation: {
    textAlign: "center",
    fontFamily: '"Nunito", Sans-serif',
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 20,
  },
});

export default withStyles(styles)(
  withContext(AuthContext, SnackbarContext, SignUp),
);

class PostSignUpDataError extends Error {
  constructor(error) {
    super(error.message);
    this.name = "PostSignUpDataError";
    this.response = error.response;
  }
}
