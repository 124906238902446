import React from "react";
import AppNavigation from "../AppNavigation/AppNavigation";
import withContext from "Lib/WithContext/withContext";
import { UserRoleContext } from "UserRoles/UserRoleProvider";
import OrganizationActivityLog from "./OrganizationActivityLog";

const activityLog = (props) => (
  <AppNavigation title="Activity">
    <OrganizationActivityLog />
  </AppNavigation>
);

export default withContext(UserRoleContext, activityLog);
