import { useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment";
import {
  Grid,
  withStyles,
  Card,
  CardContent,
  TextField,
  Button,
} from "@material-ui/core";
// import { DateTimeField } from "@mui/x-date-pickers";
import { KeyboardDatePicker } from "@material-ui/pickers";

import history from "history.js";
import CandidateModel from "CandidateRole/CandidateModel";
import log from "Lib/Log/log";
import ApplicationPlanModel from "ApplicationPlans/ApplicationPlanModel";

export function FetchApplicationPlan(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [applicationPlan, setApplicationPlan] = useState(null);

  useEffect(
    () =>
      (async () => {
        const { uid } = props.match.params;
        log("fetchApplicationPlan.applicationPlanUid", uid);
        log(`/api/tb/application_plans/${uid}`);

        const response = await Axios.get(`/api/tb/application_plans/${uid}`, {
          headers: props.getUserRoleAuthHeaders(),
        });
        const applicationPlan = ApplicationPlanModel.fromJSON(
          response.data.application_plan,
        );

        setApplicationPlan(applicationPlan);
        setIsLoading(false);
      })(),
    [],
  );

  return { isLoading, applicationPlan };
}

function _ApplicationPlanDetailForm(props) {
  const { classes, applicationPlan, setApplicationPlan } = props;

  return (
    <Grid item xs={12}>
      <Card className={classes.card}>
        <CardContent style={{ marginTop: 20 }}>
          <TextField
            variant="outlined"
            margin="normal"
            value={applicationPlan.name}
            label="Name"
            placeholder="Name"
            onChange={(evt) =>
              setApplicationPlan(
                applicationPlan.update({ name: evt.target.value }),
              )
            }
            fullWidth
          />
          <TextField
            variant="outlined"
            margin="normal"
            value={applicationPlan.description}
            label="Description"
            placeholder="Description"
            onChange={(e) =>
              setApplicationPlan(
                applicationPlan.update({ description: e.target.value }),
              )
            }
            fullWidth
          />

          <TextField
            variant="outlined"
            margin="normal"
            select
            SelectProps={{ native: true }}
            value={applicationPlan.jobType}
            label="Job Type"
            placeholder="Job Type"
            onChange={(evt) =>
              setApplicationPlan(
                applicationPlan.update({ jobType: evt.target.value }),
              )
            }
            fullWidth
          >
            {CandidateModel.POSITION_TYPES.map((positionType) => {
              return (
                <option key={positionType} value={positionType}>
                  {positionType}
                </option>
              );
            })}
          </TextField>
          <TextField
            variant="outlined"
            margin="normal"
            value={applicationPlan.assessmentDescription}
            label="Assesment description"
            placeholder="Assesment description"
            onChange={(evt) =>
              setApplicationPlan(
                applicationPlan.update({
                  assessmentDescription: evt.target.value,
                }),
              )
            }
            fullWidth
          />
          <TextField
            variant="outlined"
            margin="normal"
            value={applicationPlan.windowDescription}
            label="Window description"
            placeholder="Window description"
            onChange={(evt) =>
              setApplicationPlan(
                applicationPlan.update({
                  windowDescription: evt.target.value,
                }),
              )
            }
            fullWidth
          />

          <KeyboardDatePicker
            disableToolbar
            variant="outlined"
            format="MM/DD/yyyy"
            margin="normal"
            label="Starts"
            value={applicationPlan.startedAt}
            onChange={(value) =>
              setApplicationPlan(applicationPlan.update({ startedAt: value }))
            }
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            fullWidth
          />

          <KeyboardDatePicker
            disableToolbar
            format="MM/DD/yyyy"
            margin="normal"
            label="Ends"
            value={applicationPlan.endedAt}
            onChange={(value) =>
              setApplicationPlan(applicationPlan.update({ endedAt: value }))
            }
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            fullWidth
          />
        </CardContent>
      </Card>
    </Grid>
  );
}

export const CancelButton = ({ returnTo }) => {
  const onClickCancel = () => {
    history.push(returnTo);
  };

  return (
    <Button
      variant="outlined"
      size="small"
      onClick={onClickCancel}
      style={{ marginRight: "10px" }}
    >
      Cancel
    </Button>
  );
};

const ApplicationPlanDetailFormStyles = {
  card: {
    overflow: "inherit",
  },
};

export const ApplicationPlanDetailForm = withStyles(
  ApplicationPlanDetailFormStyles,
)(_ApplicationPlanDetailForm);
