import { Switch, Route } from "react-router-dom";
import withContext from "Lib/WithContext/withContext";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Axios from "axios";
import log from "Lib/Log/log";
import { UserRoleContext } from "UserRoles/UserRoleProvider";
import {
  Grid,
  withStyles,
  Paper,
  List,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  IconButton,
  ListItemIcon,
} from "@material-ui/core";
import DashboardHome from "./DashboardHome";
import DashboardApplications from "./DashboardApplications";
import OrganizationModel from "Settings/Organizations/OrganizationModel";
import CandidateOrganizationCard from "./CandidateOrganizationCard";
import CandidateOrganizationModel from "CandidateRole/CandidateOrganizationModel";
import { Add, Business, Favorite, PlusOne } from "@material-ui/icons";

class MyCompanies extends React.Component {
  state = {
    loading: true,
    candidateOrganizations: [],
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    return Axios.get("/api/tb/candidate_organizations", {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchData", response.data);
      const candidateOrganizations = response.data.candidate_organizations.map(
        (o) => CandidateOrganizationModel.fromJSON(o),
      );
      this.setState({ candidateOrganizations, loading: false });
    });
  };

  deleteCandidateOrganization = (organization) => {
    log("deleteCandidateOrganization", organization);
    if (
      !window.confirm(`Are you sure you want to unfollow ${organization.name}?`)
    ) {
      return;
    }
    return CandidateOrganizationModel.delete(
      organization.uid,
      this.props.getUserRoleAuthHeaders(),
    ).then(this.fetchData);
  };

  render() {
    const { classes } = this.props;
    const { candidateOrganizations, loading } = this.state;

    if (loading) {
      return "";
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom color="primary">
            My Companies
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <List>
              {candidateOrganizations.length
                ? candidateOrganizations.map((o, i) => (
                    <ListItem
                      button
                      component={RouterLink}
                      to={`/companies/${o.uid}`}
                    >
                      <ListItemAvatar>
                        <Avatar
                          className={classes.avatar}
                          color="primary"
                          src={o.profileImageUrl}
                        />
                      </ListItemAvatar>
                      <ListItemText primary={o.name} />
                      <ListItemSecondaryAction>
                        <IconButton
                          color="textSecondary"
                          onClick={(e) => this.deleteCandidateOrganization(o)}
                        >
                          <Favorite />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                : null}
              <ListItem button component={RouterLink} to="/companies">
                <ListItemIcon>
                  <Add />
                </ListItemIcon>
                <ListItemText secondary="Follow more companies" />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
      // <Grid container spacing={2}>
      //   { candidateOrganizations.length ?
      //       candidateOrganizations.map((o,i) => (
      //         <Grid item xs={12} sm={4} md={3}>
      //           <CandidateOrganizationCard
      //             key={i}
      //             organization={o}
      //             onClickFavorite={o => this.deleteCandidateOrganization(o)}
      //           />
      //         </Grid>
      //       ))
      //     : <Typography variant="caption">No Companies Saved</Typography>
      //   }
      //   <Grid item xs={12} sm={4} md={3}>

      //     <Card className={classes.followMore}>
      // <Typography variant="body2" gutterBottom>
      //   Want to follow more?
      // </Typography>
      // <Button variant="outlined" color="primary" component={RouterLink} to="/companies">
      //   See more Companies
      // </Button>
      //     </Card>
      // </Grid>
      // </Grid>
    );

    //   return (
    //     <Grid container spacing={2}>
    //       { candidateOrganizations.length ?
    //           candidateOrganizations.map((o,i) => (
    //             <Grid item xs={12} sm={4} md={3}>
    //               <CandidateOrganizationCard
    //                 key={i}
    //                 organization={o}
    //                 onClickFavorite={o => this.deleteCandidateOrganization(o)}
    //               />
    //             </Grid>
    //           ))
    //         : <Typography variant="caption">No Companies Saved</Typography>
    //       }
    //       <Grid item xs={12} sm={4} md={3}>

    //         <Card className={classes.followMore}>
    //           <Typography variant="body2" gutterBottom>
    //             Want to follow more?
    //           </Typography>
    //             <Button variant="outlined" color="primary" component={RouterLink} to="/companies">
    //               See more Companies
    //             </Button>
    //         </Card>
    //     </Grid>
    //     </Grid>
    // );
  }
}

const styles = {
  followMore: {
    backgroundColor: "#f0fcec",
    height: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
};

export default withStyles(styles)(withContext(UserRoleContext, MyCompanies));
