/*global chrome*/
import React from "react";
import Redirect from "react-router-dom/Redirect";
import withContext from "Lib/WithContext/withContext";

import { AuthContext } from "./AuthProvider";
import { SnackbarContext } from "../Snackbar/SnackbarProvider";

class AuthRequiredGuardComponent extends React.Component {
  render() {
    if (this.props.hasToken()) {
      return this.props.children;
    } else {
      this.props.showSnackbar("Please sign in to continue.", {
        autoHide: false,
      });
      return (
        <Redirect
          to={{
            pathname: this.props.redirectTo,
            state: {
              referrer:
                typeof window === "undefined"
                  ? ""
                  : window.location.pathname + window.location.search,
            },
          }}
        />
      );
    }
  }
}

AuthRequiredGuardComponent.defaultProps = {
  redirectTo: "/sign_in",
  hasToken: () => {},
};

const AuthRequiredGuardComponentWithContext = withContext(
  AuthContext,
  SnackbarContext,
  AuthRequiredGuardComponent,
);

export default function AuthRequiredGuard(Children) {
  return (props) => (
    <AuthRequiredGuardComponentWithContext>
      <Children {...props} />
    </AuthRequiredGuardComponentWithContext>
  );
}
