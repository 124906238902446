import JsonModel from "Lib/JsonModel/JsonModel";

export default class CredentialsModel {
  constructor(props) {
    for (let k in props) {
      this[k] = props[k];
    }
  }

  static fromJSON(json) {
    const model = new CredentialsModel();
    return JsonModel.modelFromJSON(model, json);
  }

  toJSON() {
    return JsonModel.modelToJSON(this);
  }
}
