import {
  Card,
  Chip,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  Grid,
  Divider,
  ListItem,
  ListItemIcon,
  List,
  ListItemText,
} from "@material-ui/core";
import { School, CheckCircle, Room, Timer } from "@material-ui/icons";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PeopleIcon from "@material-ui/icons/People";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment/moment";
import { Link as RouterLink } from "react-router-dom";

import OpportunityModel from "Opportunities/OpportunityModel";

import { toHeaderCase } from "Lib/JsConvertCase/JsConvertCase";

function OrganizationOpportunityDetailContent({
  classes,
  organization,
  applicationPlan,
  opportunity,
  organizationProfile,
  children,
}) {
  const listItems = [
    {
      icon: <Timer />,
      text: `Priority Deadline on ${moment(
        opportunity.priorityDeadlineAt,
      ).format("MMMM DD, YYYY")}`,
    },
    {
      icon: <Room />,
      text: opportunity.locationsText(),
    },
    {
      icon: <School />,
      text: `${toHeaderCase(
        opportunity.educationLevel.replace(/_/g, " "),
      )} Level Education`,
    },
  ];

  if (opportunity.headcount) {
    listItems.splice(0, 0, {
      icon: <PeopleIcon />,
      text: opportunity.headcount,
    });
  }

  if (opportunity.isAssessmentRequired) {
    listItems.push({
      icon: <CheckCircle />,
      text: opportunity.isAdditionalAssessmentRequired
        ? "Multiple Assessments Required"
        : "Assessment Required",
    });
  }

  if (opportunity.salary) {
    listItems.push({
      icon: <MonetizationOnIcon />,
      text: opportunity.salary,
    });
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar
            className={classes.avatar}
            color="primary"
            src={organizationProfile?.profileImageUrl}
          />
        }
        title={
          <>
            <Typography variant="h5" className={classes.title}>
              {opportunity.title}

              <Chip
                style={{
                  height: 20,
                  fontSize: 10,
                  marginLeft: 5,
                  backgroundColor: opportunity.isPublished()
                    ? "#006CFF"
                    : "#F46E54",
                  color: "white",
                }}
                size="small"
                label={opportunity.status}
              />
            </Typography>
            <Typography
              className={classes.organizationLink}
              component={RouterLink}
              variant="body2"
              to={`/companies/${organization.uid}`}
            >
              {organization.name}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {opportunity.applicationPlanName}
            </Typography>
            <Typography variant="caption" gutterBottom>
              {opportunity.publishedAt
                ? `Posted ${moment(opportunity.publishedAt).fromNow()} `
                : "Ready to publish "}
              in {applicationPlan?.name}
            </Typography>
          </>
        }
        // action={
        //   <IconButton aria-label="Add to favorites">
        //     <AddIcon />
        //   </IconButton>
        // }
      />
      <Divider />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <List dense>
              {listItems.map((l, i) => (
                <ListItem key={i} className={classes.iconListItem}>
                  <ListItemIcon className={l.className}>{l.icon}</ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontWeight: "bold" }}
                      >
                        {l.text}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </CardContent>

      <Divider />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              color="textSecondary"
              gutterBottom
              className={classes.label}
            >
              Description
            </Typography>

            <Typography
              variant="body2"
              className={classes.description}
              dangerouslySetInnerHTML={{
                __html: opportunity.description,
              }}
            ></Typography>
          </Grid>
        </Grid>
      </CardContent>

      {opportunity.interviewProcessDescription ? (
        <>
          <Divider />
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  gutterBottom
                  className={classes.label}
                >
                  Interview Process
                </Typography>

                <Typography
                  variant="body2"
                  className={classes.description}
                  dangerouslySetInnerHTML={{
                    __html: opportunity.interviewProcessDescription,
                  }}
                ></Typography>
              </Grid>
            </Grid>
          </CardContent>
        </>
      ) : null}

      {opportunity.isAssessmentRequired && (
        <>
          <Divider />
          <CardContent>
            <Typography variant="body2" className={classes.description}>
              Requires Code Signal with minimal score{" "}
              {OpportunityModel.SIGNAL_SCORES.filter(
                ([value]) => value == opportunity.minimumCodeSignalScore,
              )
                .at(0)
                ?.at(1)}
            </Typography>
          </CardContent>
        </>
      )}

      {opportunity.isAdditionalAssessmentRequired && (
        <>
          <Divider />
          <CardContent>
            <Typography variant="body2" className={classes.description}>
              Requires additional assasment{" "}
              {opportunity.additionalAssessmentUrl} from vendor{" "}
              {OpportunityModel.ASSESSMENT_VENDORS.filter(
                ([value]) => value === opportunity.additionalAssessmentVendor,
              )
                .at(0)
                ?.at(1)}
            </Typography>
          </CardContent>
        </>
      )}

      <Divider />

      {children}
    </Card>
  );
}

export const styles = {
  avatar: {
    border: "1px solid whitesmoke",
    margin: 10,
    width: 60,
    height: 60,
  },
  organizationLink: {
    fontWeight: "bold",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  iconListItem: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  label: {
    opacity: 0.7,
    textTransform: "uppercase",
  },
  description: {
    fontSize: "1em",
    marginBottom: 15,
  },
};
export default withStyles(styles)(OrganizationOpportunityDetailContent);
