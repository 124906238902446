import KnModel from "Lib/KnModel";

export default class UserRoleModel extends KnModel {
  roleHasAbility = (ability) => {
    return this.roleAbilities.split(",").find((a) => a === ability)
      ? true
      : false;
  };

  roleHasAtLeastOneAbility = (...abilities) => {
    const roleAbilities = this.roleAbilities.split(",");

    return Boolean(
      abilities.map((a) => roleAbilities.indexOf(a) > -1).filter((a) => a)
        .length,
    );
  };

  isCandidateRole = () => {
    return this.roleType === "Candidate";
  };

  isOrganizationRole = () => {
    return this.roleType === "Organization";
  };

  isApplicationEditorRole = () => {
    return this.roleName === "Application Editor";
  };
}
