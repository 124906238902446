import React from "react";
import Axios from "axios";
import log from "Lib/Log/log";
import withContext from "Lib/WithContext/withContext";
import { CandidateRoleContext } from "CandidateRole/CandidateRoleProvider";
import { withStyles } from "@material-ui/core/styles";
import ApplicationModel from "Applications/ApplicationModel";
import { Grid, List, Paper, Typography, Chip, LinearProgress, Divider } from "@material-ui/core";
import Alert from "Alert/Alert";
import ApplicationListItem from "Applications/CandidateApplications/ApplicationListItem";

class DashboardApplications extends React.Component {
  state = {
    loading: true,
    applications: [],
    total: 0,
    tabIndex: 0,
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    return Axios.get(`/api/tb/applications`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchData", response.data);

      const applications = response.data.applications.map((a) =>
        ApplicationModel.fromJSON(a),
      );

      this.setState({
        applications,
        total: response.data.total,
        loading: false,
      });
    });
  };

  render() {
    const { applications, organization, opportunity, loading } = this.state;
    const { classes } = this.props;

    if (loading) {
      // return "Loading...";
      return <LinearProgress color="primary" />;
    }

    const inProgress = applications.filter((a) => !a.applied);
    const applied = applications.filter((a) => a.applied && !a.withdrew);

    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom color="primary">
            My Applications
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.chips}>
          {/* TODO: Implement Archived filtering here */}
          {/* <Chip
            className={classes.chip}
            color="primary"
            label="Active"
            onClick={() => {}}
          />
          <Chip className={classes.chip} label="Archived" onClick={() => {}} /> */}
        </Grid>

        <Grid item xs={12}>
          <Paper>
            {applied.length ? (
              <List>
                {applied.map((a, i) => (
                  <>
                    <ApplicationListItem application={a} key={i} />
                    { i + 1 < applied.length && <Divider/> }
                  </>
                ))}
              </List>
            ) : (
              <Alert warning>
                <Typography variant="caption">
                  No applications submitted yet
                </Typography>
              </Alert>
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: "center",
  },
  chips: {
    textAlign: "right",
  },
  chip: {
    marginRight: 15,
  },
});

export default withStyles(styles)(
  withContext(CandidateRoleContext, DashboardApplications),
);
