import React from "react";
import Axios from "axios";
import { State } from "country-state-city";

import log from "Lib/Log/log";
import Layout from "Layout/Layout";
import { Editor } from "react-draft-wysiwyg";
import formHelpers from "Lib/FormHelpers/formHelpers";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PromiseButton from "Lib/PromiseButton/PromiseButton";
import AutoError from "Lib/AutoError/AutoError";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import withContext from "Lib/WithContext/withContext";
import { UserRoleContext } from "UserRoles/UserRoleProvider";
import ImageUpload from "Upload/ImageUpload";
import { SnackbarContext } from "Lib/Snackbar/SnackbarProvider";

import qs from "qs";
import history from "history.js";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  withStyles,
  Grid,
  Link,
  Tabs,
  FormControl,
  TextField,
  IconButton,
  MenuItem,
  LinearProgress,
} from "@material-ui/core";
import OrganizationModel from "Settings/Organizations/OrganizationModel";
import OrganizationProfileModel from "./OrganizationProfileModel";
import OpportunityModel from "Opportunities/OpportunityModel";
import CandidateOpportunities from "Opportunities/CandidateOpportunities/CandidateOpportunities";
import MaxWidth from "Lib/MaxWidth/MaxWidth";
import splitLocationToCityAndState from "Lib/Location/splitLocationToCityAndState.js";
import replaceCityOrStateInLocation from "Lib/Location/replaceCityOrStateInLocation.js";
import { Breadcrumbs } from "@material-ui/core";
import "./OrganizationProfile.css";
import { Edit } from "@material-ui/icons";

class EditOrganizationProfile extends React.Component {
  state = {
    loading: true,
    organization: {},
    organizationProfile: {},
    opportunities: [],
    editorState: EditorState.createEmpty(),
    tagline: "",
    headcount: "",
    industry: "",
    headquarters: "",
    newProfileImageFile: "",
    newProfileImageUrl: "",
    newBannerImageUrl: "",
    newBannerImageFile: "",
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    this.fetchOrganizationProfile(); //.then(this.fetchOrganizationOpportunities);
  };

  fetchOrganizationProfile = () => {
    const { organizationUid } = this.props.match.params;
    return Axios.get(`/api/tb/organization_profiles/${organizationUid}`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchData", response.data);
      const { data } = response;
      const organization = OrganizationModel.fromJSON(data.organization);
      const organizationProfile = OrganizationProfileModel.fromJSON(
        data.organization_profile,
      );

      // https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
      let editorState = this.state.editorState;
      const contentBlock = htmlToDraft(organizationProfile.description || "");

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        editorState = EditorState.createWithContent(contentState);
      }

      this.setState({
        organization,
        organizationProfile,
        editorState,
        tagline: organizationProfile.tagline,
        headcount: organizationProfile.headcount,
        industry: organizationProfile.industry,
        headquarters: organizationProfile.headquarters,
        loading: false,
      });
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  getOrganizationProfileAttributesFromState = () => {
    const { state } = this;

    const rawContentState = convertToRaw(state.editorState.getCurrentContent());

    return {
      tagline: state.tagline,
      headcount: state.headcount,
      industry: state.industry,
      headquarters: state.headquarters,
      description: draftToHtml(rawContentState),
    };
  };

  onClickSave = async () => {
    const organizationProfileAttributes =
      this.getOrganizationProfileAttributesFromState();

    log("organizationProfileAttributes", organizationProfileAttributes);

    const { newProfileImageFile, newBannerImageFile } = this.state;

    try {
      await this.validate(organizationProfileAttributes);
    } catch (error) {
      AutoError.catch.bind(this)(error);
      return;
    }

    await this.putOrganizationProfileAttributes(organizationProfileAttributes)
      .then(() =>
        newProfileImageFile
          ? this.putOrganizationImage(newProfileImageFile, "profile")
          : Promise.resolve(),
      )
      .then(() =>
        newBannerImageFile
          ? this.putOrganizationImage(newBannerImageFile, "banner")
          : Promise.resolve(),
      )
      .then(() => {
        let message = "Organization profile updated.";
        this.props.showSnackbar(message);
        history.push(`/organizations/${this.state.organization.uid}/profile`);
      });
  };

  onValidationError = (error) => {
    log("onValidationError", error);
    this.props.showSnackbar(error.message);
  };

  putOrganizationImage = (updatedImage, position) => {
    log("putOrganizationImageResume", updatedImage);

    const formData = new FormData();
    formData.append("file", updatedImage);

    return Axios.post(
      `/api/tb/organization_profiles/${this.state.organization.uid}/image?name=${updatedImage.name}&position=${position}`,
      formData,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        "Content-Type": "multipart/form-data",
      },
    ).then((response) => {
      log("putOrganizationImage response", response);
      // const OrganizationImage = OrganizationImageModel.fromJSON(response.data.OrganizationImage);
      // this.setState({ OrganizationImage });
    });
  };

  putOrganizationProfileAttributes = (updatedAttributes) => {
    log("putOrganizationProfileAttributes", updatedAttributes);
    const organizationProfile = new OrganizationProfileModel(updatedAttributes);

    return Axios.put(
      `/api/tb/organization_profiles/${this.state.organization.uid}`,
      { organization_profile: organizationProfile },
      { headers: this.props.getUserRoleAuthHeaders() },
    ).then((response) => {
      log("putOrganizationProfileAttributes response", response);
      const organizationProfile = OrganizationProfileModel.fromJSON(
        response.data.organization_profile,
      );
      this.setState({ organizationProfile });
    });
  };

  validate = (organizationProfileAttributes) => {
    let validations = {
      description: {
        presence: { message: "Please enter a description." },
      },
      tagline: {
        presence: { message: "Please provide a tagline." },
      },
    };

    const { organizationProfile } = this.state;

    if (
      !(organizationProfile.profileImageUrl || this.state.newProfileImageUrl)
    ) {
      validations.profileImageUrl = {
        presence: { message: "Please upload a profile image." },
      };
    }

    if (!(organizationProfile.bannerImageUrl || this.state.newBannerImageUrl)) {
      validations.bannerImageUrl = {
        presence: { message: "Please upload a banner." },
      };
    }

    return formHelpers.validate(organizationProfileAttributes, validations);
  };

  render() {
    const { classes } = this.props;
    const {
      loading,
      organization,
      organizationProfile,
      editorState,
      tagline,
      headcount,
      industry,
      headquarters,
      newBannerImageUrl,
      newProfileImageUrl,
    } = this.state;

    if (loading) {
      // return "Loading...";
      return <LinearProgress color="primary" />;
    }

    return (
      <Layout>
        <MaxWidth maxWidth={1200}>
          {/* <Grid container spacing={32}>
          <Grid item sm={12} md={8}>

          </Grid>
          <Grid item sm={0} md={4}>
            <Card className={classes.card}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Another Organization
                </Typography>
                <Typography component="p"></Typography>
              </CardContent>
            </Card>

            <Card className={classes.alsoAppliedCard}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  People also Applied
                </Typography>
                <Typography component="p"></Typography>
              </CardContent>
            </Card>

          </Grid>
        </Grid> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Breadcrumbs>
                <Typography color="textPrimary">
                  Organization Profile
                </Typography>
              </Breadcrumbs>
            </Grid>

            <Grid item xs={12}>
              <Card className={classes.card}>
                <div style={{ position: "relative" }}>
                  <CardMedia
                    className={classes.media}
                    image={
                      newBannerImageUrl || organizationProfile.bannerImageUrl
                    }
                  />
                  <ImageUpload
                    id="banner-image"
                    onChangeImage={(file, url) =>
                      this.setState({
                        newBannerImageFile: file,
                        newBannerImageUrl: url,
                      })
                    }
                    style={{ position: "absolute", top: 0, right: 0 }}
                  >
                    <Edit />
                  </ImageUpload>

                  <div className={classes.logoBox}>
                    <ImageUpload
                      id="profile-image"
                      onChangeImage={(file, url) =>
                        this.setState({
                          newProfileImageFile: file,
                          newProfileImageUrl: url,
                        })
                      }
                      src={
                        newProfileImageUrl ||
                        organizationProfile.profileImageUrl
                      }
                      className={classes.logoBoxImage}
                    />
                  </div>
                </div>

                <CardContent style={{ marginTop: 20 }}>
                  <Typography gutterBottom variant="h2">
                    {organization.name}
                  </Typography>

                  <TextField
                    variant="outlined"
                    margin="normal"
                    value={tagline}
                    label="Tagline"
                    placeholder="Tagline"
                    onChange={(e) => this.setState({ tagline: e.target.value })}
                    fullWidth
                  />

                  <TextField
                    variant="outlined"
                    margin="normal"
                    value={headcount}
                    label="Headcount"
                    placeholder="Headcount"
                    onChange={(e) =>
                      this.setState({ headcount: e.target.value })
                    }
                    fullWidth
                  />

                  <TextField
                    variant="outlined"
                    margin="normal"
                    value={industry}
                    label="Industry"
                    placeholder="Industry"
                    onChange={(e) =>
                      this.setState({ industry: e.target.value })
                    }
                    fullWidth
                    multiline
                  />

                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      style={{ paddingTop: 15, paddingBottom: 0 }}
                    >
                      <Typography variant="h6">Headquarters</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        value={splitLocationToCityAndState(headquarters).city}
                        label="City"
                        placeholder="City"
                        onChange={(e) =>
                          this.setState({
                            headquarters: replaceCityOrStateInLocation(
                              headquarters,
                              { city: e.target.value },
                            ),
                          })
                        }
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        select
                        SelectProps={{ native: true }}
                        value={splitLocationToCityAndState(headquarters).state}
                        label="State"
                        placeholder="State"
                        onChange={(e) =>
                          this.setState({
                            headquarters: replaceCityOrStateInLocation(
                              headquarters,
                              { state: e.target.value },
                            ),
                          })
                        }
                        fullWidth
                      >
                        {State.getStatesOfCountry("US").map((state) => (
                          <option key={state.isoCode} value={state.isoCode}>
                            {state.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>

                  <Editor
                    editorState={editorState}
                    onEditorStateChange={this.onEditorStateChange}
                    wrapperClassName="OrganizationProfile-editorWrapper"
                    toolbar={{
                      options: ["inline", "list", "link"],
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item sm={12}>
              <Button
                variant="outlined"
                size="small"
                style={{ marginRight: 10 }}
                onClick={() =>
                  history.push(
                    `/organizations/${this.state.organization.uid}/profile`,
                  )
                }
              >
                Cancel
              </Button>
              <PromiseButton
                buttonProps={{ variant: "contained", color: "primary", size: "small" }}
                onProcess={this.onClickSave}
              >
                Save
              </PromiseButton>
            </Grid>
          </Grid>
          {/* <CandidateOpportunities
            organizationUid={organization.uid}
            showFilters={false}
          /> */}
        </MaxWidth>
      </Layout>
    );
  }
}

const styles = {
  card: {
    overflow: "inherit",
  },
  media: {
    height: 200,
  },
  logoBox: {
    position: "absolute",
    height: 120,
    width: 120,
    backgroundColor: "white",
    bottom: -20,
    left: 50,
    padding: 5,
  },
  logoBoxImage: {
    width: "100%",
  },
  alsoAppliedCard: {
    marginTop: 32,
  },
  taglineTextField: {
    outline: "none",
    border: "none",
  },
};

export default withStyles(styles)(
  withContext(UserRoleContext, SnackbarContext, EditOrganizationProfile),
);
