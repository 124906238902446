// import Companies from "Companies/Companies";
// import Discover from "Discover/Discover";
// import MyCompanies from "Companies/Companies";
import Users from "Settings/Users/Users";
import "./App.css";
import "App.css";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import ActivityLog from "Activity/ActivityLog";
import AcceptUserInvitation from "Settings/UserInvitations/AcceptUserInvitation";
import Insights from "Insights/Insights";
import TalentPool from "TalentPool/TalentPool";
import { Router, Route, Switch, Link as RouterLink } from "react-router-dom";
import Account from "Account/Account";
import ApplicationPlans from "ApplicationPlans/ApplicationPlans";
import Applications from "Applications/Applications";
import AuthProvider from "Lib/Auth/AuthProvider";
import AuthRequiredGuard from "Lib/Auth/AuthRequiredGuard";
import CandidateApplicationPlanDetail from "ApplicationPlans/CandidateApplicationPlans/CandidateApplicationPlanDetail";
import CandidateApplicationPlans from "ApplicationPlans/CandidateApplicationPlans/CandidateApplicationPlans";
import CandidateAssessmentStart from "CandidateAssessments/CandidateAssesmentStart";
import AnonymousRoleProvider from "AnonymousRoleProvider";
import CandidateRoleProvider from "CandidateRole/CandidateRoleProvider";
import Dashboard from "Dashboard/Dashboard";
import EditCandidateProfile from "CandidateProfile/EditCandidateProfile";
import CandidateProfileDetail from "CandidateProfile/CandidateProfileDetail";
import ErrorPage from "ErrorPage/ErrorPage";
import Forgot from "Forgot/Forgot";
import history from "./history.js";
import Home from "Home/Home";
import Opportunities from "Opportunities/Opportunities";
import OrganizationDetails from "OrganizationRole/OrganizationDetails";
import OrganizationRoleProvider from "OrganizationRole/OrganizationRoleProvider";
import OrganizationsWithOpportunities from "Opportunities/CandidateOpportunities/OrganizationsWithOpportunities";
import React from "react";
import Reset from "Reset/Reset";
import SignIn from "SignIn/SignIn";
import SignOut from "SignOut/SignOut";
import SignUp from "SignUp/SignUp";
import SnackbarProvider from "Lib/Snackbar/SnackbarProvider";
import UserRoleProvider from "UserRoles/UserRoleProvider";
import theme from "theme";
import Organizations from "Settings/Organizations/Organizations";

class App extends React.Component {
  providers = (children) => (
    // <LocalizationProvider dateAdapter={AdapterMoment}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider>
          <AuthProvider>{children}</AuthProvider>
        </SnackbarProvider>
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
    // </LocalizationProvider>
  );

  render() {
    return this.providers(
      <Router history={history}>
        <Switch>
          {/* Placed here to match routes like /user_invitations/new first */}
          {/* <Route exact path="/invitations/:token/accept" component={ AcceptUserInvitation }/> */}
          <Route
            exact
            path="/user_invitations/:token/accept"
            component={AcceptUserInvitation}
          />
          <Route exact path="/error" component={ErrorPage} />
          <Route exact path="/sign_out" component={SignOut} />
          <Route exact path="/sign_in" component={SignIn} />
          <Route exact path="/sign_up" component={SignUp} />
          <Route exact path="/forgot" component={Forgot} />
          <Route path="/reset/:reset_token" component={Reset} />

          <UserRoleProvider
            roleProvider={(currentUserRole) =>
              currentUserRole.id
                ? currentUserRole.isCandidateRole()
                  ? CandidateRoleProvider
                  : OrganizationRoleProvider
                : AnonymousRoleProvider
            }
            allowAnonymous={true}
          >
            <Route
              path="/companies/:organizationUid"
              component={OrganizationDetails}
            />
            <Route exact path="/" component={Home} />
            
            <Route path="/application_plans" component={ApplicationPlans} />
            <Route path="/opportunities" component={Opportunities} />

            <Route path="/account" component={AuthRequiredGuard(Account)} />
            <Route
              exact
              path="/companies"
              component={AuthRequiredGuard(OrganizationsWithOpportunities)}
            />
            {/* <Route
                  path="/opportunities/:opportunityUid"
                  component={CandidateOpportunityDetail}
                /> */}
            <Route
              path="/applications"
              component={AuthRequiredGuard(Applications)}
            />
            <Route path="/dashboard" component={AuthRequiredGuard(Dashboard)} />
            <Route
              path="/candidate-profile"
              exact
              component={AuthRequiredGuard(EditCandidateProfile)}
            />
            <Route
              path="/candidate-profile/:candidateUid"
              exact
              component={AuthRequiredGuard(CandidateProfileDetail)}
            />
            <Route
              path="/candidate_assessments/opportunities/:opportunityUid/start"
              component={AuthRequiredGuard(CandidateAssessmentStart)}
            />

            <Route
              path="/organizations"
              component={AuthRequiredGuard(Organizations)}
            />
            <Route path="/users" component={AuthRequiredGuard(Users)} />
            <Route
              path="/activity"
              component={AuthRequiredGuard(ActivityLog)}
            />
            <Route
              path="/talent-pool"
              component={AuthRequiredGuard(TalentPool)}
            />
            <Route path="/insights" component={AuthRequiredGuard(Insights)} />
          </UserRoleProvider>
        </Switch>
      </Router>,
    );
  }
}

export default App;
