import { useEffect, useState } from "react";
import { Grid, Typography, LinearProgress } from "@material-ui/core";
import { Breadcrumbs } from "@material-ui/core";
import Axios from "axios";
import history from "history.js";

import Layout from "Layout/Layout";
import log from "Lib/Log/log";
import MaxWidth from "Lib/MaxWidth/MaxWidth";
import withContext from "Lib/WithContext/withContext";
import PromiseButton from "Lib/PromiseButton/PromiseButton";
import { SnackbarContext } from "Lib/Snackbar/SnackbarProvider";
import { OrganizationRoleContext } from "OrganizationRole/OrganizationRoleProvider";

import {
  FetchApplicationPlan,
  ApplicationPlanDetailForm,
  CancelButton,
} from "./hooks";

function EditOrganizationApplicationPlanDetail(props) {
  const [applicationPlan, setApplicationPlan] = useState(null);
  const {
    isLoading: isLoadingApplicationPlanFromServer,
    applicationPlan: applicationPlanFromServer,
  } = FetchApplicationPlan(props);

  useEffect(
    () => setApplicationPlan(applicationPlanFromServer),
    [applicationPlanFromServer],
  );

  const applicationPlanURL = `/application_plans/${applicationPlan?.uid}`;
  const onSave = async () => {
    log("EditOrganizationApplicationPlanDetail.onSave", applicationPlan);

    await Axios.put(
      `/api/tb/application_plans/${applicationPlan.uid}`,
      { application_plan: applicationPlan },
      { headers: props.getUserRoleAuthHeaders() },
    );

    props.showSnackbar("Application Plan updated");
    history.push(applicationPlanURL);
  };

  if (isLoadingApplicationPlanFromServer) {
    // return "Loading...";
    return <LinearProgress color="primary" />;
  }

  return (
    <Layout>
      <MaxWidth maxWidth={1200}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs>
              <Typography color="textPrimary">
                Edit Organization Application Plan
              </Typography>
            </Breadcrumbs>
          </Grid>

          <ApplicationPlanDetailForm
            applicationPlan={applicationPlan}
            setApplicationPlan={setApplicationPlan}
          />

          <Grid item sm={12} xs={12}>
            <CancelButton returnTo={applicationPlanURL} />

            <PromiseButton
              onProcess={onSave}
              buttonProps={{ variant: "contained", color: "primary", size: "small" }}
            >
              Save
            </PromiseButton>
          </Grid>
        </Grid>
      </MaxWidth>
    </Layout>
  );
}

export default withContext(
  OrganizationRoleContext,
  SnackbarContext,
  EditOrganizationApplicationPlanDetail,
);
