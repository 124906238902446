import { Typography, withStyles } from "@material-ui/core";
import { OrganizationRoleContext } from "OrganizationRole/OrganizationRoleProvider";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import { DataGrid } from '@mui/x-data-grid';
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableToolbar from "Lib/TableToolbar/TableToolbar";
import log from "Lib/Log/log";
import format from "Lib/Format/format";
import React from "react";
import Axios from "axios";
import ApplicationModel from "Applications/ApplicationModel";

import withContext from "Lib/WithContext/withContext";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import { sort } from "d3";

const SORT_ASC = 'ASC';
const SORT_DESC = 'DESC';


const columns = [
  {
    title: 'Last Name, First Name',
    sortKey: 'display_name',
    renderCell: d => <Link
      component={RouterLink}
      to={`/candidate-profile/${d.candidateUid}`}
    >
      {d.displayName}
    </Link>
  },
  {
    title: 'Email',
    sortKey: 'email',
    renderCell: d => d.email,
  },
  {
    title: 'Applied',
    sortKey: 'applied_at',
    renderCell: d => d.appliedAt.toLocaleString(),
  },
  {
    title: 'Score',
    field: 'codeSignalScore',
    sortKey: 'code_signal_score',
    renderCell: d => d.codeSignalScore,
  }
];



class ApplicationTable extends React.Component {

  state = {
    loading: true,
    applications: [],
    total: 0,
    page: 0,
    rowsPerPage: 20,
    sortBy: `applied_at ${SORT_DESC}`,
  };

  componentDidMount = () => {
    this.fetchData();
  }

  fetchData = () => {
    this.fetchApplications()
      .then(() => {
        this.setState({ loading: false });
      });
  };


  fetchApplications = () => {
    const { opportunityUid } = this.props;

    console.log('fetchApplications', this.state.sortBy)
    return Axios.get(`/api/tb/opportunities/${opportunityUid}/applications`, {
      params: {
        page: this.state.page,
        limit: this.state.rowsPerPage,
        order_by: this.state.sortBy
      },
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchData", response.data);

      const applications = response.data.applications.map((a) =>
        ApplicationModel.fromJSON(a),
      );

      this.setState({ applications, total: response.data.total });
    });
  };

  onClickSortBy = (newSortKey) => {
    const { sortBy } = this.state;

    const split = sortBy.split(' ');
    const sortKey = split[0];
    const sortOrder = split[1];

    let newSortBy;

    if (sortKey === newSortKey && sortOrder === SORT_ASC) {
      newSortBy = `${sortKey} ${SORT_DESC}`;
    } else if (sortKey === newSortKey && sortOrder === SORT_DESC) {
      newSortBy = `${sortKey} ${SORT_ASC}`;
    } else {
      newSortBy = `${newSortKey} ${SORT_ASC}`;
    }

    this.setState({ sortBy: newSortBy, page: 0 }, this.fetchData);
  }


  onChangeApplicationPage = (applicationPage) => {
    this.setState({ applicationPage }, this.fetchApplications);
  };

  on



  renderTableHeaders() {

  }

  renderTable() {
    const {
      applications,
      loading,
    } = this.state;

    const { classes } = this.props;

    return (
      <>
        <TableToolbar title={`${format.commas(this.state.total)} Applications`} />
        {loading ? <LinearProgress /> : null}
        {!loading && !applications.length ? (
          <Typography variant="caption" className={classes.noData}>
            No Applications.
          </Typography>
        ) : (
          <div className={classes.tableWrapper}>
            <Table
              className={classes.table}
              fixedHeader={false}
              style={{ opacity: loading ? 0.5 : 1 }}
            >
              <TableHead>
                <TableRow>
                  {
                    columns.map((c, i) => (
                      <TableCell className={classes.cellHeader} onClick={() => this.onClickSortBy(c.sortKey)}>
                        {c.title}
                        {
                          this.state.sortBy.startsWith(c.sortKey) ? (
                            this.state.sortBy.endsWith('ASC') ? <ArrowUpward />
                              : <ArrowDownward />
                          )
                            : null
                        }
                      </TableCell>
                    ))
                  }

                </TableRow>
              </TableHead>
              {applications.map((a, i) => {
                return (
                  <TableRow key={i}>

                    {columns.map((c, i) => (
                      <TableCell>
                        {c.renderCell(a)}
                      </TableCell>
                    ))
                    }
                  </TableRow>
                );
              })}
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={this.state.total || 0}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onRowsPerPageChange={(e) =>
                      this.setState({ rowsPerPage: e.target.value }, this.fetchData)
                    }
                    onPageChange={(e, p) =>
                      this.setState({ page: p }, this.fetchData)
                    }
                    rowsPerPageOptions={this.props.rowsPerPageOptions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        )}
      </>
    );
  }

  render() {
    const { classes } = this.props;
    const {
      applications,
    } = this.state;

    return (
      applications.length ? (
        <Grid item sm={12}>
          {this.renderTable()}
        </Grid>
      ) : (
        <Typography
          variant="caption"
          className={classes.noData}
          style={{
            width: "100%",
            display: "block",
          }}
        >
          No Applications.
        </Typography>
      )
    );

  }
}

const styles = (theme) => ({
  cellHeader: {
    cursor: "pointer",
  },
  noData: {
    textAlign: "center",
    padding: 25,
    color: "#777",
  },
  tableWrapper: {
    overflowX: "auto",
    // height: 500,
  },
  table: {
    minWidth: 700,
  },
});

ApplicationTable.defaultProps = {
  total: 0,
  page: 0,
  applications: [],
  loading: true,
  rowsPerPage: 50,
  rowsPerPageOptions: [50],
  onChangePage: (p) => {
    log("page", p);
  },
  onChangeRowsPerPage: (rowsPerPage) => {
    log("rows per page", rowsPerPage);
  },
};

export default withStyles(styles)(
  withContext(OrganizationRoleContext, ApplicationTable),
);
