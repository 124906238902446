import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import AppLogo from "AppNavigation/AppLogo.js";
import AppTitleLogo from "AppNavigation/AppTitleLogo";

const appDrawerBrand = (props) => {
  const { classes } = props;
  return (
    <div className={classes.toolbar} style={{ backgroundColor: "#1E4B45" }}>
      <React.Fragment>
        <AppTitleLogo
          textStyle={{
            fill: "white",
          }}
        />
        {/* <div style={{ width: 50, height: 50, margin: 5 }}>
          <AppLogo />
        </div>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.appLogoText}
        >
          talentboard
        </Typography> */}
      </React.Fragment>
      <IconButton
        onClick={props.closeDrawer}
        style={{ position: "absolute", right: 5, color: "white" }}
      >
        <ChevronLeftIcon />
      </IconButton>
    </div>
  );
};

const styles = (theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appLogo: {
    maxWidth: 180,
    maxHeight: 62,
  },
  appLogoText: {
    flexGrow: 1,
    marginLeft: 10,
    fontWeight: "bold",
    color: "white",
  },
  email: {
    position: "absolute",
    top: 38,
    left: 58,
    fontSize: 10,
    color: "rgba(0, 0, 0, 0.54)",
    [theme.breakpoints.up("sm")]: {
      top: 45,
    },
  },
});

export default withStyles(styles)(appDrawerBrand);
