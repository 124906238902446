import React from "react";
import Axios from "axios";
import Layout from "Layout/Layout";
import log from "Lib/Log/log";
import withContext from "Lib/WithContext/withContext";
import { Link as RouterLink, Switch, Route } from "react-router-dom";
import { SnackbarContext } from "Lib/Snackbar/SnackbarProvider";
import { UserRoleContext } from "UserRoles/UserRoleProvider";
import OrganizationModel from "OrganizationRole/OrganizationModel";
import ApplicationPlanModel from "../ApplicationPlanModel";
import {
  Grid,
  Typography,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  Button,
  LinearProgress,
} from "@material-ui/core";
import Redirect from "react-router-dom/Redirect";
import * as Ability from "Ability/Ability";

class ApplicationPlans extends React.Component {
  state = {
    loading: true,
    applicationPlans: [],
    organizationsByApplicationUid: {},
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    return Axios.get(`/api/tb/application_plans`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchData", response.data);
      const applicationPlans = response.data.application_plans.map((p) =>
        ApplicationPlanModel.fromJSON(p),
      );

      const organizationsByApplicationUid = {};
      response.data.organizations.map((organization) => {
        organization = OrganizationModel.fromJSON(organization);
        organizationsByApplicationUid[organization.applicationPlanUid] =
          organizationsByApplicationUid[organization.applicationPlanUid] || [];
        organizationsByApplicationUid[organization.applicationPlanUid].push(
          organization,
        );
      });

      this.setState({
        applicationPlans,
        organizationsByApplicationUid,
        loading: false,
      });
    });
  };

  render() {
    const { currentUserRole } = this.props;
    const { loading, applicationPlans, organizationsByApplicationUid } =
      this.state;

    if (!currentUserRole.roleHasAbility(Ability.VIEW_APPLICATION_PLANS)) {
      return <Redirect to="/" />;
    }

    if (loading) {
      // return <div>Loading...</div>;
      return <LinearProgress color="primary" />;
    }

    const jobTypes = [
      ...new Set(
        applicationPlans.map((applicationPlan) => applicationPlan.jobType),
      ).values(),
    ];
    jobTypes.sort();

    return (
      <Layout title="">
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Typography variant="h4" gutterBottom>
              Organization Application Plans
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid item sm={12}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                component={RouterLink}
                to={`/application_plans/new`}
              >
                Create an Application Plan
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {jobTypes.map((jobType) => (
            <React.Fragment key={jobType}>
              <Grid item xs={12}>
                <Typography variant="h5"> {jobType} </Typography>
              </Grid>

              <Grid item xs={12}>
                <Paper>
                  <List>
                    {applicationPlans
                      .filter(
                        (applicationPlan) =>
                          applicationPlan.jobType === jobType,
                      )
                      .map((applicationPlan, index) => (
                        <React.Fragment key={index}>
                          <ListItem
                            button
                            component={RouterLink}
                            to={`/application_plans/${applicationPlan.uid}`}
                          >
                            <ListItemText
                              gu
                              primary={
                                <>
                                  <Typography variant="h5" gutterBottom>
                                    {applicationPlan.name}
                                  </Typography>
                                  <Typography gutterBottom>
                                    <strong>
                                      {applicationPlan.windowDescription}
                                    </strong>{" "}
                                    -&nbsp;
                                    {applicationPlan.startedAtFormatted()}{" "}
                                    to&nbsp;
                                    {applicationPlan.endedAtFormatted()}
                                  </Typography>
                                </>
                              }
                              secondary={
                                <>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    gutterBottom
                                  >
                                    {applicationPlan.description}
                                  </Typography>

                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    gutterBottom
                                  >
                                    {organizationsByApplicationUid[
                                      applicationPlan.uid
                                    ]
                                      ?.slice(0, 3)
                                      ?.map(
                                        (organization) =>
                                          organization.organizationName,
                                      )
                                      ?.join(", ") || ""}
                                  </Typography>

                                  <Typography
                                    variant="caption"
                                    color="primary"
                                    gutterBottom
                                  >
                                    {applicationPlan.assessmentDescription ? (
                                      <>
                                        Requires{" "}
                                        {applicationPlan.assessmentDescription}
                                      </>
                                    ) : null}
                                  </Typography>
                                </>
                              }
                              disableTypography
                            />
                          </ListItem>
                          { index + 1 < applicationPlans.length && <Divider /> }

                        </React.Fragment>
                      ))}
                  </List>
                </Paper>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Layout>
    );
  }
}

export default withContext(UserRoleContext, SnackbarContext, ApplicationPlans);
