import { ListItem, withStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import {
  Card,
  CardHeader,
  Avatar,
  ListItemAvatar,
  ListItemText,
  CardContent,
  Typography,
  Chip,
} from "@material-ui/core";
import { Clear as ClearIcon } from "@material-ui/icons";
import format from "Lib/Format/format";
import log from "Lib/Log/log";
import React from "react";

class ApplicationListItem extends React.Component {
  render() {
    const { application, classes, isLast } = this.props;

    return (
      <>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              className={classes.avatar}
              color="primary"
              src={application.organizationProfileImageUrl}
            />
          </ListItemAvatar>
          <ListItemText disableTypography>
            <Typography variant="h5" className={classes.title}>
              <Link
                component={RouterLink}
                to={`/opportunities/${application.opportunityUid}`}
              >
                {application.opportunityTitle}
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link
                component={RouterLink}
                className={classes.organizationLink}
                to={`/companies/${application.organizationUid}`}
              >
                {application.organizationName}
              </Link>{" "}
              -&nbsp;
              {application.opportunityLocations}
              &nbsp;
            </Typography>

            <Typography variant="body2" gutterBottom>
              {application.applicationPlanName}
            </Typography>
            {/* <Typography variant="caption" gutterBottom>
            {application.opportunityDescription.slice(0, 150) + "..."}
          </Typography> */}

            {/* <Typography variant="caption" color="primary">
            Posted: {application.opportunityPublishedAt?.toLocaleString()}
          </Typography> */}

            <Typography variant="caption" color="primary">
              Applied: {application.appliedAt.toLocaleString()}
            </Typography>
          </ListItemText>
        </ListItem>
      </>
    );
  }
}

const styles = (theme) => ({
  noData: {
    textAlign: "center",
    padding: 25,
    color: "#777",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

ApplicationListItem.defaultProps = {
  // isLast: false,
  // total: 0,
  // page: 0,
  // opportunities: [],
  // loading: true,
  // rowsPerPage: 50,
  // rowsPerPageOptions: [50],
  // changePage: (p) => {
  //   log("page", p);
  // },
  // changeRowsPerPage: (rowsPerPage) => {
  //   log("rows per page", rowsPerPage);
  // },
};

export default withStyles(styles)(ApplicationListItem);
