import { useState } from "react";
import { Grid, Button, TextField, Paper } from "@material-ui/core";

export function StepBase({ goBackStep, goNextStep, nextButton, children }) {
  return (
    <>
      <Paper style={{ marginBottom: 16, padding: 16 }}>
        <Grid container spacing={2}>{children}</Grid>
      </Paper>
      <ChangeStepButtons
        goBackStep={goBackStep}
        goNextStep={goNextStep}
        nextButton={nextButton}
      />
    </>
  );
}


export function ChangeStepButtons({ goBackStep, goNextStep, nextButton }) {
  if (!nextButton) {
    nextButton = (
      <Button
        variant="outlined"
        size="small"
        onClick={goNextStep}
        style={{ display: "block", marginLeft: "auto" }}
      >
        NEXT
      </Button>
    );
  }

  return (
    <Grid item sm={12} style={{ marginBottom: 32 }}>
      <Grid container direction="row">
        <Grid item xs={6}>
          {goBackStep ? (
            <Button variant="outlined"
            size="small"
            onClick={goBackStep}>
              Back
            </Button>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={6}>
          {nextButton}
        </Grid>
      </Grid>
    </Grid>
  );
}

export function DateTextField({ value, onChange, ...props }) {
  const [textValue, setTextValue] = useState(
    () => value.toISOString().split("T")[0],
  );

  return (
    <TextField
      {...props}
      type="date"
      value={textValue}
      onChange={(e) => setTextValue(e.target.value)}
      onBlur={() => onChange(new Date(textValue))}
    />
  );
}
