import React, { Component } from 'react';
import axios from 'axios';
import OpportunityModel from './OpportunityModel';
import OpportunityCard from './CandidateOpportunities/OpportunityCard';
import { Grid, withStyles, Button, Typography, Paper, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Layout from 'Layout/Layout';


const OPPORTUNITY_COUNT = 5;

const JOB_TYPE_INTERNSHIP = 'Internship';
const JOB_TYPE_FULL_TIME = 'Full Time';
const JOB_TYPE_APPRENTICESHIP = 'Apprenticeship';
const JOB_TYPE_PROGRAM = 'Program';

export const JOB_TYPES = [
  JOB_TYPE_INTERNSHIP,
  JOB_TYPE_FULL_TIME,
  JOB_TYPE_APPRENTICESHIP,
  JOB_TYPE_PROGRAM,
];

class PublicOpportunities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobTypesWithOpportunities: {},
      loading: true,
      error: null
    };
  }

  componentDidMount() {
    axios.get('/api/tb/opportunities/published')
      .then(response => {
        const opportunities = response.data.opportunities.map((o) =>
          OpportunityModel.fromJSON(o)
        );

        let jobTypesWithOpportunities = {};
        const key = 'applicationPlanJobType';

        opportunities.forEach(o => {
          const key = o.applicationPlanJobType;
          jobTypesWithOpportunities[key] = jobTypesWithOpportunities[key] || {
            key,
            applicationPlanJobType: o.applicationPlanJobType,
            opportunities: [],
          };

          jobTypesWithOpportunities[key].opportunities.push(o);
        });

        console.log(jobTypesWithOpportunities);

        this.setState({
          jobTypesWithOpportunities,
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: error.message
        });
      });
  }

  render() {
    const { classes } = this.props;
    const { jobTypesWithOpportunities, loading, error } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error: {error}</div>;
    }


    return (

      <Layout title="One Application, Endless Opportunities">
          <Grid container>
          <Grid item xs={12}>

          <Paper className={classes.paper}>
            <Typography variant="h3" gutterBottom>
              One Application, Endless Opportunities
            </Typography>
            <Typography variant="h5" gutterBottom>
              Hero subtitle
            </Typography>
          </Paper>

          </Grid>
            <Grid item xs={12}> {


              JOB_TYPES.map(j => {
                const jt = jobTypesWithOpportunities[j];

                if (!jt) {
                  return null;
                }

                return (
                  <div>
                    <h1 gutterBottom={false}>{jt.key} Opportunities</h1>
                    <RouterLink component={Link} to={`/opportunities?jobTypes=${jt.key}`}>View all {jt.key.toLowerCase()} opportunities</RouterLink>
                    <ul>
                      {jt.opportunities.slice(0, OPPORTUNITY_COUNT).map(o => (
                        <OpportunityCard key={o.id} opportunity={o} />
                      ))}
                    </ul>
                  </div>
                )
              })
            }
            </Grid>
          </Grid>
      </Layout>

    );
  }
}

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center', // This will center the background image,
    minHeight: 300,
    // backgroundOpacity: 0.5,
    // backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.80), rgba(255, 255, 255, 0.80)), url(${process.env.PUBLIC_URL + '/hero_unit.webp'})`,
    // backgroundImage: `url(${process.env.PUBLIC_URL + '/hero_unit.webp'})`, // Add your image path here
  },


});


export default withStyles(styles)(PublicOpportunities);


