import Alert from "Alert/Alert";
import React from "react";
import Axios from "axios";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PeopleIcon from "@material-ui/icons/People";

import log from "Lib/Log/log";
import withContext from "Lib/WithContext/withContext";
import { CandidateRoleContext } from "CandidateRole/CandidateRoleProvider";
import { AnonymousRoleContext } from "AnonymousRoleProvider";
import { withStyles } from "@material-ui/core/styles";
import AppNavigation from "AppNavigation/AppNavigation";
import OpportunityModel from "../../Opportunities/OpportunityModel";
import OrganizationModel from "OrganizationRole/OrganizationModel";
import OrganizationProfileModel from "Settings/Organizations/OrganizationProfile/OrganizationProfileModel";
import ApplicationModel from "Applications/ApplicationModel";
import SmallChip from "SmallChip/SmallChip";
import MaxWidth from "Lib/MaxWidth/MaxWidth";
import { toHeaderCase } from "Lib/JsConvertCase/JsConvertCase";
import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Button,
  CardContent,
  Typography,
  CardActions,
  Chip,
  Grid,
  Divider,
  Link,
  ListItem,
  ListItemIcon,
  List,
  ListItemText,
  LinearProgress,
} from "@material-ui/core";
import { Breadcrumbs } from "@material-ui/core";
import moment from "moment/moment";
import {
  School,
  CheckCircle,
  Room,
  Timer,
  Code,
  CheckBox,
  CheckBoxOutlineBlank,
  HighlightOff,
  Highlight,
} from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import ApplicationPlanModel from "ApplicationPlans/ApplicationPlanModel";
import CandidateAssessmentModel from "CandidateRole/CandidateAssessmentModel";
import history from "history.js";

class CandidateOpportunityDetail extends React.Component {
  state = {
    loading: true,
    application: {},
    opportunity: {},
    organization: {},
    organizationProfile: {},
    applicationPlan: {},
    candidateAssessment: {},
    total: 0,
    tabIndex: 0,
  };

  componentDidMount = async () => {
    await this.fetchOpportunity();
    if (this.props.currentUserRole?.id) {
      await this.fetchApplication();
      await this.fetchAssessments();
    } else {
      await this.setState({
        application: null,
        candidateAssessment: null,
        loading: false,
      });
    }
  };

  fetchOpportunity = () => {
    const { uid } = this.props.match.params;
    log("uid", uid);

    return Axios.get(`/api/tb/opportunities/${uid}`).then((response) => {
      log("fetchOpportunity", response.data);

      const { data } = response;

      const opportunity = OpportunityModel.fromJSON(data.opportunity);
      const organization = OrganizationModel.fromJSON(data.organization);
      const organizationProfile = OrganizationProfileModel.fromJSON(
        data.organization_profile,
      );
      const applicationPlan = ApplicationPlanModel.fromJSON(
        data.application_plan,
      );

      this.setState({
        opportunity,
        organization,
        organizationProfile,
        applicationPlan,
      });
    });
  };

  fetchApplication = () => {
    const { uid } = this.props.match.params;
    log("uid", uid);

    return Axios.get(`/api/tb/applications/opportunities/${uid}`, {
      headers: this.props.getUserRoleAuthHeaders(),
    })
      .then((response) => {
        log("fetchApplication", response.data);

        const { data } = response;

        const application = ApplicationModel.fromJSON(data.application);
        this.setState({ application });
      })
      .catch((error) => {
        const { response } = error;
        if (response && response.status === 404) {
          this.setState({ application: null });
        } else {
          throw error;
        }
      });
  };

  fetchAssessments = () => {
    const { opportunity } = this.state;

    return Axios.get(`/api/tb/candidate_assessments`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchAssessments", response.data);

      const { data } = response;

      const candidateAssessment = data.candidate_assessments
        .map((d) => CandidateAssessmentModel.fromJSON(d))
        .find((d) => d.codeSignalTestId === opportunity.codeSignalTestId);

      this.setState({ candidateAssessment, loading: false });
    });
  };

  // onClickStartAssessment = () => {
  //   const { opportunity } = this.state;
  //   log("onClickStartAssessment", opportunity.uid);

  //   return Axios.put(
  //     `/api/tb/candidate_assessments`,
  //     {
  //       code_signal_test_id: opportunity.codeSignalTestId,
  //     },
  //     { headers: this.props.getUserRoleAuthHeaders() }
  //   ).then((response) => {
  //     this.setState(
  //       {
  //         candidateAssessment: CandidateAssessmentModel.fromJSON(
  //           response.data.candidate_assessment
  //         ),
  //       },
  //       this.openAssessmentPopup
  //     );
  //   });
  // };

  openAssessmentPopup = () => {
    window.open(this.state.candidateAssessment.invitationUrl);
  };

  onClickApply = () => {
    log("onClickApply");

    if (!this.props.getUserRoleAuthHeaders) {
      history.push("/sign_up");
      return;
    }

    return this.postApplicationTransition("applied");
  };

  onClickReapply = () => {
    log("onClickReapply");
    if (window.confirm("Are you sure you want to resubmit your application?")) {
      return this.postApplicationTransition("reapplied");
    }
  };

  onClickWithdraw = () => {
    log("onClickWithdraw");

    const input =
      window.prompt("Are you sure? Type `withdraw` to confirm.") || "";

    if (!input.trim().toLowerCase() === "withdraw") {
      return;
    }

    return this.postApplicationTransition("withdrew");
  };

  postApplicationTransition = (transitionTo) => {
    const { opportunity } = this.state;

    log("postApplicationTransition", transitionTo, opportunity.uid);

    return Axios.put(
      `/api/tb/applications/opportunities/${opportunity.uid}`,
      {
        application: this.state.application,
        transition_to: transitionTo,
      },
      {
        headers: this.props.getUserRoleAuthHeaders
          ? this.props.getUserRoleAuthHeaders()
          : {},
      },
    ).then((response) => {
      this.setState({
        application: ApplicationModel.fromJSON(response.data.application),
      });
    });
  };

  render() {
    const {
      application,
      organization,
      organizationProfile,
      opportunity,
      applicationPlan,
      candidateAssessment,
      loading,
    } = this.state;
    const { classes, candidate } = this.props;

    if (loading) {
      // return "Loading...";
      return <LinearProgress color="primary" />;
    }

    const listItems = [
      {
        icon: <Timer />,
        text: `Priority Deadline on ${moment(
          opportunity.priorityDeadlineAt,
        ).format("MMMM DD, YYYY")}`,
      },
      {
        icon: <Room />,
        text: opportunity.locationsText(),
      },
      {
        icon: <School />,
        text: `${toHeaderCase(
          opportunity.educationLevel.replace(/_/g, " "),
        )} Level Education`,
      },
    ];

    if (opportunity.headcount) {
      listItems.splice(0, 0, {
        icon: <PeopleIcon />,
        text: opportunity.headcount,
      });
    }

    if (opportunity.isAssessmentRequired) {
      listItems.push({
        icon: <CheckCircle />,
        text: opportunity.isAdditionalAssessmentRequired
          ? "Multiple Assessments Required"
          : "Assessment Required",
      });
    }

    if (opportunity.salary) {
      listItems.push({
        icon: <MonetizationOnIcon />,
        text: opportunity.salary,
      });
    }

    return (
      <AppNavigation title="Discover">
        <MaxWidth maxWidth={1200} style={{ padding: 16 }}>
          <Grid container>
            <Grid item xs={12}>
              <Breadcrumbs style={{ marginBottom: 15 }}>
                <Link
                  color="inherit"
                  component={RouterLink}
                  to="/opportunities"
                >
                  Opportunities
                </Link>
                {/* <Link
                  color="inherit"
                  component={RouterLink}
                  to="/application_plans"
                >
                  Application Plans
                </Link>
                <Link
                  color="inherit"
                  component={RouterLink}
                  to={`/application_plans/${applicationPlan.uid}`}
                >
                  {applicationPlan.name}
                </Link> */}
                <Typography color="textPrimary">
                  {opportunity.title} - {organization.name}
                </Typography>
              </Breadcrumbs>
            </Grid>

            <Grid item xs={12}>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <Avatar
                      className={classes.avatar}
                      color="primary"
                      src={organizationProfile.profileImageUrl}
                    />
                  }
                  title={
                    <>
                      <Typography variant="h5" className={classes.title}>
                        {opportunity.title}
                      </Typography>
                      <Typography
                        className={classes.organizationLink}
                        component={RouterLink}
                        variant="body2"
                        to={`/companies/${organization.uid}`}
                      >
                        {organization.name}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {opportunity.applicationPlanName}
                      </Typography>
                      <Typography variant="caption" gutterBottom>
                        Posted {moment(opportunity.publishedAt).fromNow()}{" "}
                        {applicationPlan.name}
                      </Typography>
                    </>
                  }
                  // action={
                  //   <IconButton aria-label="Add to favorites">
                  //     <AddIcon />
                  //   </IconButton>
                  // }
                />
                <Divider />
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <List dense>
                        {listItems.map((l, i) => (
                          <ListItem key={i} className={classes.iconListItem}>
                            <ListItemIcon className={l.className}>
                              {l.icon}
                            </ListItemIcon>
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {l.text}
                                </Typography>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </CardContent>

                <Divider />
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        gutterBottom
                        className={classes.label}
                      >
                        Description
                      </Typography>

                      <Typography
                        variant="body2"
                        className={classes.description}
                        dangerouslySetInnerHTML={{
                          __html: opportunity.description,
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>

                {opportunity.interviewProcessDescription ? (
                  <>
                    <Divider />
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            gutterBottom
                            className={classes.label}
                          >
                            Interview Process
                          </Typography>

                          <Typography
                            variant="body2"
                            className={classes.description}
                            dangerouslySetInnerHTML={{
                              __html: opportunity.interviewProcessDescription,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </>
                ) : null}

                <Divider />
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        gutterBottom
                        className={classes.label}
                      >
                        Application Requirements
                      </Typography>
                      <List>
                        <ProfileTaskListItem
                          isProfileComplete={
                            candidate.isProfileComplete &&
                            candidate.isProfileComplete()
                          }
                          inAnonymousMode={
                            !Boolean(this.props.getUserRoleAuthHeaders)
                          }
                          opportunity={opportunity}
                        />
                        <AssessmentTaskListItem
                          opportunity={opportunity}
                          candidateAssessment={candidateAssessment}
                          inAnonymousMode={
                            !Boolean(this.props.getUserRoleAuthHeaders)
                          }
                        />
                      </List>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      {!application || application.isNoneState() ? (
                        <Button
                          color="primary"
                          variant="contained"
                          // size="small"
                          disabled={
                            candidate.isProfileComplete &&
                            !candidate.isProfileComplete()
                          }
                          onClick={this.onClickApply}
                        >
                          Apply Now
                        </Button>
                      ) : null}

                      {application && application.isWithdrawnState() ? (
                        <Alert
                          warning
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <div style={{ flex: 1, padding: "5px 0px" }}>
                            Application withdrawn{" "}
                            {application.withdrewAt.toLocaleString()}
                          </div>
                          <Button
                            variant="outlined"
                            color="textSecondary"
                            style={{ alignSelf: "end" }}
                            onClick={this.onClickReapply}
                          >
                            Reapply
                          </Button>
                        </Alert>
                      ) : null}

                      {application && application.isAppliedState() ? (
                        <Alert
                          info
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <div style={{ flex: 1, padding: "5px 0px" }}>
                            Application submitted{" "}
                            {application.appliedAt.toLocaleString()}
                          </div>
                          <Button
                            variant="outlined"
                            color="textSecondary"
                            style={{ alignSelf: "end" }}
                            onClick={this.onClickWithdraw}
                          >
                            Withdraw
                          </Button>
                        </Alert>
                      ) : null}
                    </Grid>
                  </Grid>
                </CardContent>

                {application && application.isAppliedState() ? (
                  <>
                    <Divider />
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            gutterBottom
                            className={classes.label}
                          >
                            What's Next?
                          </Typography>
                          <Typography variant="body2">
                            <ul>
                          <li>We send your Talentboard Application to companies you expressed an interest in.</li>
<li>Recruiters from those companies will reach out to you with next steps if they want to move forward.</li>
<li>You complete the interview process with interested companies.</li>
<li>Get offers and get hired!</li>
</ul>
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </>
                ) : null}

                <CardActions
                  className={classes.actions}
                  disableActionSpacing
                ></CardActions>
              </Card>
            </Grid>
          </Grid>
        </MaxWidth>
      </AppNavigation>
    );
  }
}

const styles = (theme) => ({
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: "center",
  },
  label: {
    opacity: 0.7,
    textTransform: "uppercase",
  },
  description: {
    fontSize: "1em",
    marginBottom: 15,
  },
  chips: {
    marginBottom: 10,
  },
  avatar: {
    border: "1px solid whitesmoke",
    margin: 10,
    width: 60,
    height: 60,
  },
  organizationLink: {
    fontWeight: "bold",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  iconListItem: {
    paddingTop: 6,
    paddingBottom: 6,
  },
});

export default withStyles(styles)(
  withContext(
    AnonymousRoleContext,
    CandidateRoleContext,
    CandidateOpportunityDetail,
  ),
);

const AssessmentTaskListItem = (props) => {
  const { opportunity, candidateAssessment, inAnonymousMode } = props;

  let icon;
  let content;
  let isComplete = false;

  if (!opportunity.isAssessmentRequired) {
    icon = <CheckCircle color="textPrimary" />;
    content = "No assessment required";
    isComplete = true;
  } else if (!candidateAssessment) {
    icon = <HighlightOff color="textSecondary" />;
    content = "Complete the assessment";
  } else if (candidateAssessment.isPending()) {
    icon = <HighlightOff color="primary" />;
    content = "Finish your assessment";
  } else if (candidateAssessment.isComplete()) {
    icon = <CheckCircle color="primary" />;
    content = "Complete the Assessment";
    isComplete = true;
  }

  const listItemProps = isComplete
    ? {}
    : {
        button: true,
        target: "_blank",
        component: "a",
        href: `/candidate_assessments/opportunities/${opportunity.uid}/start`,
        style: inAnonymousMode ? { pointerEvents: "none" } : {},
      };

  return (
    <ListItem {...listItemProps}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText disableTypography>
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: "bold",
            textDecoration: isComplete ? "line-through" : undefined,
          }}
        >
          {content}
        </Typography>
      </ListItemText>
    </ListItem>
  );
};

const ProfileTaskListItem = (props) => {
  const { isProfileComplete, opportunity, inAnonymousMode } = props;

  let icon = isProfileComplete ? (
    <CheckCircle color="primary" />
  ) : (
    <HighlightOff color="textSecondary" />
  );
  let content = (
    <>
      Incomplete Candidate Profile -{" "}
      <RouterLink to={`/candidate-profile?opportunity-uid=${opportunity.uid}`}>
        Complete it now
      </RouterLink>
    </>
  );

  const listItemProps = isProfileComplete
    ? {}
    : {
        // style: inAnonymousMode ? { pointerEvents: "none" } : {},
      };

  return (
    <ListItem {...listItemProps}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText disableTypography>
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: "bold",
            textDecoration: isProfileComplete ? "line-through" : undefined,
          }}
        >
          {content}
        </Typography>
      </ListItemText>
    </ListItem>
  );
};
