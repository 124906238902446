import React from "react";
import Axios from "axios";
import withContext from "Lib/WithContext/withContext";
import log from "Lib/Log/log";
import TableToolbar from "Lib/TableToolbar/TableToolbar";
import { SnackbarContext } from "Lib/Snackbar/SnackbarProvider";
import { withStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  TextField,
  Typography,
  Paper,
  Toolbar,
  IconButton,
  Tooltip,
  Grid,
} from "@material-ui/core";
import {
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Person, Add, Remove } from "@material-ui/icons";

import { UserRoleContext } from "UserRoles/UserRoleProvider";
import Layout from "Layout/Layout";
import UserRoleModel from "UserRoles/UserRoleModel";
import * as Ability from "Ability/Ability";
import NewOrganizationUserRoleDialog from "./NewOrganizationUserRoleDialog/NewOrganizationUserRoleDialog";
import history from "history.js";
import paperPadding from "paperPadding";

class UserDetails extends React.Component {
  state = {
    loading: true,
    user: null,
    userRoles: [],
    userRoleDialogOpen: false,
  };

  componentDidMount = () => {
    log("props", this.props);
    this.fetchUserRoles().finally(() => this.setState({ loading: false }));
  };

  fetchUserRoles = () => {
    const userId = this.props.match.params.id;
    return Axios.get(`/api/core/users/${userId}/user_roles`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchUserRoles", response);
      const userRoles = response.data.user_roles.map((r) =>
        UserRoleModel.fromJSON(r),
      );
      this.setState({ userRoles });
    });
  };

  textField = (label, value, classes) => (
    <TextField
      disabled
      label={label}
      defaultValue={value}
      className={classes.textField}
      inputProps={{
        className: classes.blackText,
      }}
      margin="normal"
      variant="filled"
    />
  );

  startImpersonatingRole = (userRole) => {
    this.props.startImpersonatingRole(userRole);
    history.push("/");
  };

  deleteRole = (role) => {
    log("deleteRole", role);
    Axios.delete(`/api/core/user_roles/${role.id}`, {
      headers: this.props.getUserRoleAuthHeaders(),
    })
      .then((response) => {
        log("deleteRole response", response);
        this.refreshUserRoles();
        this.props.showSnackbar("Role deleted.");
      })
      .catch((error) => {
        log("deleteRole error", error);
        this.props.showSnackbar("Something went wrong.");
      });
  };

  refreshUserRoles = () => {
    return this.props.fetchUserRoles().then(this.fetchUserRoles);
  };

  render() {
    const { classes, currentUserRole } = this.props;
    const { loading, userRoles, userRoleDialogOpen } = this.state;
    const userId = this.props.match.params.id;
    const cur = this.props.currentUserRole;

    if (loading) {

      return <div style={{ textAlign: 'center' }}><CircularProgress /> </div>;
    }

    const firstRole = userRoles[0] || {};

    return (
      <Layout title="User Details">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">User Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paperRoot}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">User Info</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    value={firstRole.userFirstName}
                    label="First Name"
                    readOnly
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    value={firstRole.userLastName}
                    label="Last Name"
                    readOnly
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    value={firstRole.userEmail}
                    label="Email"
                    readOnly
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper>
              {currentUserRole.isOrganizationRole() ? (
                <NewOrganizationUserRoleDialog
                  userId={userId}
                  open={userRoleDialogOpen}
                  onClose={() => this.setState({ userRoleDialogOpen: false })}
                  onUserRoleAdded={this.refreshUserRoles}
                  onInviteUser={this.onInviteUser}
                />
              ) : null}

              <TableToolbar
                title="Roles"
                actions={
                  <Tooltip enterDelay={200} title="Add Role">
                    <IconButton
                      color="secondary"
                      onClick={(e) =>
                        this.setState({ userRoleDialogOpen: true })
                      }
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                }
              />
              <List>
                {userRoles.map((r) => (
                  <ListItem key={r.id}>
                    <ListItemText
                      primary={r.roleTypeName}
                      secondary={r.roleName}
                    />
                    <ListItemSecondaryAction>
                      <Toolbar>
                        {currentUserRole.roleHasAbility(Ability.IMPERSONATE) &&
                        //Disallow impersonation on the current role
                        !(
                          r.userId === cur.userId &&
                          r.roleTypeId === cur.roleTypeId &&
                          r.roleId === cur.roleId
                        ) &&
                        // Disallow impersonation on the current organization (if applicable)
                        !(
                          cur.isOrganizationRole() &&
                          r.isOrganizationRole() &&
                          r.roleTypeId === cur.roleTypeId
                        ) ? (
                          <Tooltip enterDelay={200} title="Sign In as Role">
                            <IconButton
                              onClick={() => this.startImpersonatingRole(r)}
                            >
                              <Person />
                            </IconButton>
                          </Tooltip>
                        ) : null}

                        {currentUserRole.roleHasAtLeastOneAbility(
                          Ability.EDIT_ORGANIZATION_USERS,
                        ) && userRoles.length > 1 ? (
                          <Tooltip enterDelay={200} title="Delete Role">
                            <IconButton onClick={() => this.deleteRole(r)}>
                              <Remove />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </Toolbar>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Layout>
    );
  }
}

const styles = (theme) => ({
  paperRoot: {
    padding: paperPadding,
  },
  textField: {
    width: "100%",
  },
  blackText: {
    color: "black",
  },
});

export default withStyles(styles)(
  withContext(UserRoleContext, SnackbarContext, UserDetails),
);
