import React from "react";
import Axios from "axios";
import log from "Lib/Log/log";
import withContext from "Lib/WithContext/withContext";
import { Grid, Typography, Link, LinearProgress } from "@material-ui/core";
import { Breadcrumbs } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { CandidateRoleContext } from "CandidateRole/CandidateRoleProvider";
import { withStyles } from "@material-ui/core/styles";
import ApplicationPlanModel from "ApplicationPlans/ApplicationPlanModel";
import CandidateOpportunities from "Opportunities/CandidateOpportunities/CandidateOpportunities";
import Layout from "Layout/Layout";

class CandidateApplicationPlanDetail extends React.Component {
  state = {
    loading: true,
    applicationPlan: [],
    opportunities: [],
    total: 0,
    rowsPerPage: 10,
    page: 0,
    tabIndex: 0,
    query: "",
    orderBy: "published_at",
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    this.fetchApplicationPlan();
  };

  fetchApplicationPlan = () => {
    const { uid } = this.props.match.params;
    log("applicationPlanUid", uid);
    log(`/api/tb/application_plans/${uid}`);
    console.log(this.props);
    return Axios.get(`/api/tb/application_plans/${uid}`, {
      headers: this.props.currentUserRole?.id
        ? this.props.getUserRoleAuthHeaders()
        : {},
    }).then((response) => {
      log("fetchData", response.data);

      const applicationPlan = ApplicationPlanModel.fromJSON(
        response.data.application_plan,
      );
      this.setState({ applicationPlan, loading: false });
    });
  };

  render() {
    const { classes } = this.props;
    const { loading, applicationPlan } = this.state;

    if (loading) {
      // return "Loading...";
      return <LinearProgress color="primary" />;
    }

    return (
      <Layout>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs style={{ marginBottom: 15 }}>
              <Link
                color="inherit"
                component={RouterLink}
                to="/application_plans"
              >
                Application Plans
              </Link>
              <Typography color="textPrimary">
                {applicationPlan.name}
              </Typography>
            </Breadcrumbs>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              {applicationPlan.name}
            </Typography>
            <Typography variant="body2">
              {applicationPlan.description}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CandidateOpportunities
              applicationPlanUid={applicationPlan.uid}
              {...this.props}
            />
          </Grid>
        </Grid>
      </Layout>
    );

    // return JSON.stringify(applicationPlan);

    // return (
    //   <Grid container spacing={2}>
    //     {this.props.showFilters ? (
    //       <>
    //         <Grid item xs={12} sm={4}>
    //           <form onSubmit={this.onSubmitSearch}>
    //             <TextField
    //               fullWidth
    //               id="query"
    //               label="Search"
    //               placeholder=""
    //               variant="outlined"
    //               margin="dense"
    //               style={{ backgroundColor: "white" }}
    //             />
    //           </form>
    //         </Grid>
    //         <Grid item xs={12} sm={4}>
    //           <TextField
    //             fullWidth
    //             select
    //             variant="outlined"
    //             margin="dense"
    //             label="Sort"
    //             SelectProps={{ native: true }}
    //             style={{ backgroundColor: "white" }}
    //             onChange={this.onChangeOrderBy}
    //             value={this.state.orderBy}
    //           >
    //             <option value="published_at">Most recent</option>
    //             <option value="priority_deadline_at">Closing soon</option>
    //           </TextField>
    //         </Grid>
    //         <Grid item xs={12} sm={4} />
    //       </>
    //     ) : null}

    //     {opportunities.length ? (
    //       <>
    //         <Grid item xs={12}>
    //           <Paper>
    //           <List>
    //             {opportunities.map((o, i) => (
    //               <>
    //               <OpportunityCard opportunity={o} />
    //                 <Divider/>
    //               </>
    //               ))}
    //             {/* {opportunities.map((o, i) => (
    //                 <OpportunityCard opportunity={o} />
    //               </>
    //             ))} */}
    //           </List>
    //           </Paper>
    //         </Grid>
    //         <Grid
    //           item
    //           xs={12}
    //           style={{ display: "flex", justifyContent: "center" }}
    //         >
    //           <table>
    //             <tbody>
    //               <tr>
    //                 <TablePagination
    //                   style={{ borderBottom: 0 }}
    //                   page={this.state.page}
    //                   rowsPerPage={this.state.rowsPerPage}
    //                   rowsPerPageOptions={[6, 9, 12, 24]}
    //                   onChangeRowsPerPage={(e) =>
    //                     this.setState(
    //                       { rowsPerPage: e.target.value },
    //                       this.fetchData
    //                     )
    //                   }
    //                   count={this.state.total}
    //                   onChangePage={(_, page) =>
    //                     this.setState({ page }, this.fetchData)
    //                   }
    //                   labelRowsPerPage="Per page"
    //                 />
    //               </tr>
    //             </tbody>
    //           </table>
    //         </Grid>
    //       </>
    //     ) : (
    //       <Grid item xs={12} style={{ textAlign: "center" }}>
    //         <div className={classes.noOpportunities}>
    //           <Typography variant="body2">No current opportunities.</Typography>
    //         </div>
    //       </Grid>
    //     )}
    //   </Grid>
    // );
  }
}

const styles = (theme) => ({
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: "center",
  },
  noOpportunities: {
    padding: 50,
    backgroundColor: "#f9f9f9",
    borderRadius: 5,
  },
});

CandidateApplicationPlanDetail.defaultProps = {
  showFilters: true,
};

export default withStyles(styles)(
  withContext(CandidateRoleContext, CandidateApplicationPlanDetail),
);
