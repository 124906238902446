import { useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  withStyles,
  LinearProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Link,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import withContext from "Lib/WithContext/withContext";
import TableToolbar from "Lib/TableToolbar/TableToolbar";
import format from "Lib/Format/format";
import Layout from "Layout/Layout";
import { OrganizationRoleContext } from "OrganizationRole/OrganizationRoleProvider";

import { useTalents } from "./api";

function TalentPool({ getUserRoleAuthHeaders, classes }) {
  const [page, setPage] = useState(0);
  const { loading, talents, total } = useTalents({
    getUserRoleAuthHeaders,
    page,
  });

  return (
    <Layout title="Talent Pool">
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Paper>
            {loading ? (
              <LinearProgress />
            ) : (
              <TableToolbar title={`${format.commas(total)} Applicants`} />
            )}
            {talents.length ? (
              <div className={classes.tableWrapper}>
                <Table
                  className={classes.table}
                  style={{ opacity: loading ? 0.5 : 1 }}
                >
                  <TableHead id="opportunities-table-head">
                    <TableRow>
                      <TableCell className={classes.cell}>Name</TableCell>
                      <TableCell className={classes.cell}>
                        Job Roles List
                      </TableCell>
                      <TableCell className={classes.cell}>
                        Affiliate Group List
                      </TableCell>
                      <TableCell className={classes.cell}>
                        Code Signal Score
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {talents.map((talent) => {
                      return (
                        <TableRow key={talent}>
                          <TableCell>
                            <Link
                              component={RouterLink}
                              to={`/candidate-profile/${talent.uid}`}
                            >
                              {talent.last_name}, {talent.first_name}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {JSON.parse(talent.job_role_data).join(", ")}
                          </TableCell>
                          <TableCell>
                            {JSON.parse(talent.affiliation_data).join(", ")}
                          </TableCell>
                          <TableCell>
                            {talent.code_signal_score === null
                              ? "N/A"
                              : talent.code_signal_score}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        count={total}
                        page={page}
                        onPageChange={(_, page) => setPage(page)}
                        rowsPerPage={100}
                        rowsPerPageOptions={[100]}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            ) : !loading ? (
              <Typography variant="caption" className={classes.noData}>
                No Talents.
              </Typography>
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
}
const styles = (theme) => ({
  noData: {
    textAlign: "center",
    padding: 25,
    color: "#777",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

export default withStyles(styles)(
  withContext(OrganizationRoleContext, TalentPool),
);
