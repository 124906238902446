import "./OrganizationOpportunities/OrganizationOpportunityDetail";
import { Switch, Route } from "react-router-dom";
import { UserRoleContext } from "../UserRoles/UserRoleProvider";
import withContext from "Lib/WithContext/withContext";
import CandidateOpportunities from "./CandidateOpportunities/CandidateOpportunities";
import OrganizationOpportunities from "./OrganizationOpportunities/OrganizationOpportunities";
import OrganizationOpportunityDetail from "./OrganizationOpportunities/OrganizationOpportunityDetail";
import EditOpportunity from "./OrganizationOpportunities/EditOpportunity";
import React from "react";
import CandidateApplication from "../Applications/CandidateApplications/EditCandidateApplication";
import OrganizationsWithOpportunities from "./CandidateOpportunities/OrganizationsWithOpportunities";
import CandidateOpportunityDetail from "./CandidateOpportunities/CandidateOpportunityDetail";
import OpportunitySearch from "./OpportunitySearch";

const opportunities = (props) =>
  !props.currentUserRole.id || props.currentUserRole.isCandidateRole() ? (
    <Switch>
      {/* <Route exact path="/opportunities" component={CandidateOpportunities} /> */}
      <Route exact path="/opportunities" component={OpportunitySearch} />
      <Route
        path="/opportunities/:uid"
        component={CandidateOpportunityDetail}
      />
      <Route
        exact
        path="/companies"
        component={OrganizationsWithOpportunities}
      />
    </Switch>
  ) : (
    <Switch>
      <Route
        exact
        path="/opportunities"
        component={OrganizationOpportunities}
      />

      <Route exact path="/opportunities/new" component={EditOpportunity} />
      <Route
        exact
        path="/opportunities/:uid/edit"
        component={EditOpportunity}
      />

      <Route
        exact
        path="/opportunities/:uid"
        component={OrganizationOpportunityDetail}
      />
    </Switch>
  );

export default withContext(UserRoleContext, opportunities);
