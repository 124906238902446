import React from "react";
import Axios from "axios";
import withContext from "Lib/WithContext/withContext";
import log from "Lib/Log/log";
import { LinearProgress } from "@material-ui/core";

import OrganizationModel from "./OrganizationModel";
import { UserRoleContext } from "UserRoles/UserRoleProvider";

const DEFAULT_STATE = {
  loading: true,
  organization: {},
  plans: [],
  familyPlans: [],
};

export const OrganizationRoleContext = React.createContext(DEFAULT_STATE);

// When you switch roles, we need to load the properties of the role you just signed in as...

class OrganizationRoleProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchData();
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.currentUserRole.id !== this.props.currentUserRole.id) {
      this.setState({ loading: true }, this.fetchData);
    }
  };

  fetchData = () => {
    this.fetchOrganization().finally(() => this.setState({ loading: false }));
  };

  fetchOrganization = () => {
    const { currentUserRole } = this.props;
    console.log(
      "OrganizationRoleProvider.fetchOrganization",
      currentUserRole.roleTypeId,
    );
    return Axios.get(`/api/core/organizations/${currentUserRole.roleTypeId}`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("OrganizationRoleProvider.fetchOrganization response", response);
      const organization = OrganizationModel.fromJSON(
        response.data.organization,
      );
      this.setState({ organization });
    });
  };

  render() {
    const { loading } = this.state;

    if (loading) {
      return <LinearProgress />;
    }

    return (
      <OrganizationRoleContext.Provider
        value={{
          organization: this.state.organization,
          trueUserRole: this.props.trueUserRole,
          currentUserRole: this.props.currentUserRole,
          getUserRoleAuthHeaders: this.props.getUserRoleAuthHeaders,
          plans: this.state.plans,
          familyPlans: this.state.familyPlans,
        }}
      >
        {this.props.children}
      </OrganizationRoleContext.Provider>
    );
  }
}

export default withContext(UserRoleContext, OrganizationRoleProvider);
