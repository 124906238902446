import React from "react";
import withContext from "Lib/WithContext/withContext";
import log from "Lib/Log/log";
import { withStyles, Grid } from "@material-ui/core";
import { OrganizationRoleContext } from "OrganizationRole/OrganizationRoleProvider";
import OrganizationUserCard from "../OrganizationUserCard/OrganizationUserCard";
import OrganizationUserInvitationCard from "Settings/UserInvitations/OrganizationUserInvitations/OrganizationUserInvitationCard/OrganizationUserInvitationCard";

class UserIndex extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid spacing={2} container>
          <Grid item xs={12}>
            <OrganizationUserCard
              organization={this.props.organization}
              showDescendantsInfo={true}
            />
          </Grid>

          <Grid item xs={12}>
            <OrganizationUserInvitationCard
              organization={this.props.organization}
              newInvitationPath="/users/user_invitations/new"
              showDescendantsInfo={true}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    padding: 16,
  },
});

export default withStyles(styles)(
  withContext(OrganizationRoleContext, UserIndex),
);
