import splitLocationToCityAndState from "Lib/Location/splitLocationToCityAndState.js";

export default function (location, options) {
  const parsedLocation = splitLocationToCityAndState(location);
  const newParsedLocation = {
    ...parsedLocation,
    ...options,
  };

  let newLocation = newParsedLocation.city;

  if (newLocation.length && newParsedLocation.state.length) {
    newLocation = `${newLocation}, `;
  }

  if (newParsedLocation.state.length) {
    newLocation = `${newLocation}${newParsedLocation.state}`;
  }

  return newLocation;
}
