import Alert from "Alert/Alert";
import React from "react";
import Axios from "axios";
import log from "Lib/Log/log";
import withContext from "Lib/WithContext/withContext";
import { CandidateRoleContext } from "CandidateRole/CandidateRoleProvider";
import { withStyles } from "@material-ui/core/styles";
import AppNavigation from "AppNavigation/AppNavigation";
import OpportunityModel from "../../Opportunities/OpportunityModel";
import OrganizationModel from "OrganizationRole/OrganizationModel";
import OrganizationProfileModel from "Settings/Organizations/OrganizationProfile/OrganizationProfileModel";
import ApplicationModel from "Applications/ApplicationModel";
import SmallChip from "SmallChip/SmallChip";
import MaxWidth from "Lib/MaxWidth/MaxWidth";
import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Button,
  CardContent,
  Typography,
  CardActions,
  Chip,
  Grid,
  Divider,
  LinearProgress,
} from "@material-ui/core";
import {
  LocationOn as LocationOnIcon,
  Wifi as WifiIcon,
  School as SchoolIcon,
  Build as BuildIcon,
} from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import history from "history.js";
import ApplicationPlanModel from "ApplicationPlans/ApplicationPlanModel";

const tabs = ["Opportunities", "Companies"];

class EditCandidateApplication extends React.Component {
  state = {
    loading: true,
    application: {},
    opportunity: {},
    organization: {},
    organizationProfile: {},
    applicationPlan: {},
    total: 0,
    tabIndex: 0,
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    const { uid } = this.props.match.params;
    console.log("uid", uid);

    return Axios.get(`/api/tb/applications/${uid}`, {
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchData", response.data);

      const { data } = response;

      const application = ApplicationModel.fromJSON(data.application);
      const opportunity = OpportunityModel.fromJSON(data.opportunity);
      const organization = OrganizationModel.fromJSON(data.organization);
      const organizationProfile = OrganizationProfileModel.fromJSON(
        data.organization_profile,
      );
      const applicationPlan = ApplicationPlanModel.fromJSON(
        data.application_plan,
      );

      this.setState({
        application,
        opportunity,
        organization,
        organizationProfile,
        applicationPlan,
        loading: false,
      });
    });
  };

  // onClickSubmit = () => {
  //   const { opportunity } = this.state;
  //   console.log("onClickSubmit", opportunity.uid);

  //   return Axios.put(
  //     `/api/tb/applications/opportunities/${opportunity.uid}`,
  //     {
  //       application: this.state.application,
  //       transition_to: "applied",
  //     },
  //     { headers: this.props.getUserRoleAuthHeaders() }
  //   ).then((response) => {
  //     this.setState({
  //       application: ApplicationModel.fromJSON(response.data.application),
  //     });
  //   });
  // };

  render() {
    const {
      application,
      organization,
      organizationProfile,
      opportunity,
      applicationPlan,
      loading,
    } = this.state;
    const { classes, candidate } = this.props;

    if (loading) {
      // return "Loading...";
      return <LinearProgress color="primary" />;
    }

    const isProfileComplete = candidate.isProfileComplete();
    const isAssessmentComplete = candidate.codeSignalScore;

    return (
      <AppNavigation title="Discover">
        <MaxWidth maxWidth={1200} style={{ padding: 16 }}>
          <Grid container>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <Avatar
                      className={classes.avatar}
                      color="primary"
                      src={organizationProfile.profileImageUrl}
                    />
                  }
                  action={null}
                  title={
                    <>
                      <Typography variant="body2">
                        {organization.name}
                      </Typography>
                      <Typography variant="h5">{opportunity.title}</Typography>
                    </>
                  }
                />
                <CardContent style={{ paddingTop: 0 }}>
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography
                        variant="body2"
                        className={classes.description}
                        dangerouslySetInnerHTML={{__html: opportunity.description}}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h6" gutterBottom>
                        Priority Deadline: <br />
                        {opportunity.priorityDeadlineAt.toLocaleString()}
                      </Typography>
                    </Grid>
                  </Grid>
                  {application.applied ? (
                    <div>
                      <SmallChip
                        label={`Applied ${application.appliedFormatted()}`}
                      />
                    </div>
                  ) : null}

                  <Divider style={{ marginTop: 15, marginBottom: 15 }} />

                  <Typography variant="h6" gutterBottom>
                    Opportunity Details:
                  </Typography>

                  <div className={classes.chips}>
                    {!opportunity.locations ? (
                      <SmallChip
                        icon={<LocationOnIcon />}
                        label={opportunity.locations || "Location"}
                      />
                    ) : null}

                    {!opportunity.isFullyRemote ? (
                      <SmallChip icon={<WifiIcon />} label="Fully Remote" />
                    ) : null}

                    {opportunity.isRemoteEligible ? (
                      <SmallChip icon={<WifiIcon />} label="Remote Eligible" />
                    ) : null}
                  </div>

                  <Typography variant="body1" gutterBottom>
                    Posted: {opportunity.publishedAt.toLocaleString()}
                  </Typography>

                  <Typography variant="body1" gutterBottom>
                    Application Plan: {applicationPlan.name}
                  </Typography>

                  <Typography variant="body1" gutterBottom>
                    Minimum Education:{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {opportunity.educationLevel
                        .replace(/_/g, " ")
                        .toLowerCase()}
                    </span>
                  </Typography>

                  <Divider style={{ marginTop: 15, marginBottom: 15 }} />

                  <Typography variant="h6" gutterBottom>
                    How to apply:
                  </Typography>

                  <Typography variant="caption" gutterBottom>
                    Assessment
                  </Typography>

                  {opportunity.isAssessmentRequired && isAssessmentComplete ? (
                    // <Alert info>
                    <strong>
                      Your assessment is complete - you're ready to apply!
                    </strong>
                  ) : // </Alert>
                  null}

                  {opportunity.isAssessmentRequired && !isAssessmentComplete ? (
                    <Alert warning>
                      <strong>You haven't completed the assessment</strong>
                      <p>
                        <RouterLink to="/candidate-profile">
                          Complete your profile
                        </RouterLink>{" "}
                        to submit your application.
                      </p>
                    </Alert>
                  ) : null}

                  {!opportunity.isAssessmentRequired ? (
                    <p>
                      <strong>
                        No assessment required. You're ready to apply!
                      </strong>
                    </p>
                  ) : null}

                  <Typography variant="caption" gutterBottom>
                    Interview Process
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {opportunity.interviewProcessDescription}
                  </Typography>

                  <Typography variant="caption" gutterBottom>
                    Candidate Profile
                  </Typography>

                  {!isProfileComplete ? (
                    <Alert warning>
                      <strong>Your Candidate Profile isn't Complete</strong>
                      <p>
                        <RouterLink to="/candidate-profile">
                          Complete your profile
                        </RouterLink>{" "}
                        to submit your application.
                      </p>
                    </Alert>
                  ) : (
                    // <Alert info>
                    <p>
                      <strong>Your profile is complete.</strong>&nbsp;
                      <RouterLink to="/candidate-profile">
                        Edit Profile
                      </RouterLink>
                    </p>
                  )}

                  {!application.applied ? (
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={!isProfileComplete}
                      onClick={this.onClickSubmit}
                    >
                      Submit
                    </Button>
                  ) : null}

                  {application.applied ? (
                    <Alert info>
                      Application submitted{" "}
                      {application.appliedAt.toLocaleString()}
                      <IconButton></IconButton>
                    </Alert>
                  ) : null}
                </CardContent>

                <CardActions
                  className={classes.actions}
                  disableActionSpacing
                ></CardActions>
              </Card>
            </Grid>
          </Grid>
        </MaxWidth>
        {/*
        <div style={{ padding: 16 }}>
          {JSON.stringify(application)}
          <br />

          {JSON.stringify(candidate)}
          <br />
          {JSON.stringify(opportunity)}
          <br />
          {JSON.stringify(applicationPlan)}
        </div> */}
      </AppNavigation>
    );
  }
}

const styles = (theme) => ({
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: "center",
  },
  description: {
    fontSize: "1em",
    marginBottom: 15,
  },
  chips: {
    marginBottom: 10,
  },
});

export default withStyles(styles)(
  withContext(CandidateRoleContext, EditCandidateApplication),
);
