import React from "react";
import storageAvailable from "storage-available";
import Axios from "axios";
import withContext from "Lib/WithContext/withContext";
import log from "Lib/Log/log";
import { AuthContext } from "Lib/Auth/AuthProvider";
import { CircularProgress, LinearProgress } from "@material-ui/core";

import UserRoleModel from "./UserRoleModel";
import SelectUserRole from "./SelectUserRole";
import ImpersonatingFooter from "./ImpersonatingFooter";
import history from "../history";

const localStorageAvailable = storageAvailable("localStorage");
const TRUE_USER_ROLE_ID_KEY = "trueUserRoleId";
const CURRENT_USER_ROLE_KEY = "currentUserRole";

export const removeStoredCurrentUserRoleId = () => {
  if (localStorageAvailable) {
    localStorage.removeItem(TRUE_USER_ROLE_ID_KEY);
    localStorage.removeItem(CURRENT_USER_ROLE_KEY);
  }
};

const DEFAULT_STATE = {
  loading: true,
  didCheckLocalStorage: false,
  userRoles: [],
  trueUserRole: {},
  currentUserRole: {},
};

export const UserRoleContext = React.createContext(DEFAULT_STATE);

// When you switch roles, we need to load the properties of the role you just signed in as...

class UserRoleProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    if (this.inAnonymousMode()) {
      this.setState({ loading: false });
      return;
    }

    this.fetchUserRoles().then(this.loadStoredState);
  };

  inAnonymousMode() {
    return this.props.allowAnonymous && !this.props.hasToken();
  }

  fetchUserRoles = () => {
    return Axios.get("/api/core/user_roles", {
      headers: this.props.getAuthHeaders(),
    }).then((response) => {
      const userRoles = response.data.user_roles.map((u) =>
        UserRoleModel.fromJSON(u),
      );
      log("fetchUserRoles", userRoles);
      this.setState({ loading: false, userRoles });
    });
  };

  loadStoredState = () => {
    return new Promise((resolve) => {
      log("calling loadStoredState");

      if (!localStorageAvailable) {
        this.setState({ didCheckLocalStorage: true });
        return resolve(true);
      }

      const trueUserRoleId = +localStorage.getItem(TRUE_USER_ROLE_ID_KEY);
      log("trueUserRoleId", trueUserRoleId);
      let currentUserRole = localStorage.getItem(CURRENT_USER_ROLE_KEY);
      log("currentUserRole", currentUserRole);

      let newState = {
        didCheckLocalStorage: true,
      };

      if (trueUserRoleId) {
        const { userRoles } = this.state;
        const trueUserRole = userRoles.find((u) => u.id === trueUserRoleId);
        newState = {
          ...newState,
          trueUserRole,
          currentUserRole: trueUserRole,
        };
      }

      if (currentUserRole) {
        newState = {
          ...newState,
          currentUserRole: UserRoleModel.fromJSON(JSON.parse(currentUserRole)),
        };
      }

      this.setOnWindow(newState);
      this.setState(newState);

      return resolve(true);
    });
  };

  setUserRole = (userRole, redirectTo = "/") => {
    const newState = { currentUserRole: userRole, trueUserRole: userRole };
    this.setOnWindow(newState);
    this.setState(newState);

    if (localStorageAvailable) {
      localStorage.setItem(TRUE_USER_ROLE_ID_KEY, userRole.id);
      localStorage.setItem(CURRENT_USER_ROLE_KEY, JSON.stringify(userRole));
    }

    if (redirectTo) {
      history.push(redirectTo);
    }
  };

  setOnWindow = (props) => {
    if (window.location.hostname.endsWith("localhost")) {
      Object.keys(props).forEach((k) => (window[k] = props[k]));
    }
  };

  getUserRoleAuthHeaders = () => {
    return {
      ...this.props.getAuthHeaders(),
      "current-user-role-id": this.state.currentUserRole.id,
      "true-user-role-id": this.state.trueUserRole.id,
    };
  };

  hasRole = () => {
    const { currentUserRole } = this.state;
    return currentUserRole && currentUserRole.id;
  };

  isImpersonatingRole = () => {
    const { currentUserRole, trueUserRole } = this.state;
    return this.hasRole() && currentUserRole.id !== trueUserRole.id;
  };

  startImpersonatingRole = (userRole) => {
    const newState = { currentUserRole: userRole };
    localStorage.setItem(CURRENT_USER_ROLE_KEY, JSON.stringify(userRole));
    this.setOnWindow(newState);
    this.setState(newState);
  };

  stopImpersonatingRole = () => {
    const { currentUserRole } = this.state;
    log("stopImpersonatingRole", currentUserRole);

    const newState = { currentUserRole: this.state.trueUserRole };
    this.setOnWindow(newState);
    this.setState(newState);
    this.setUserRole(this.state.trueUserRole);
    history.push(`/users/${currentUserRole.userId}`);
  };

  updateUserProfile = (updatedAttributes) => {
    const id = this.state.currentUserRole.userId;
    const user = new UserRoleModel(updatedAttributes);
    return Axios.put(
      `/api/core/users/${id}`,
      { user },
      { headers: this.getUserRoleAuthHeaders() },
    )
      .then((response) => {
        const mapping = {
          firstName: "userFirstName",
          lastName: "userLastName",
          email: "userEmail",
        };

        let currentUserRole = { ...this.state.currentUserRole };

        log("updatedAttributes", updatedAttributes);

        Object.keys(updatedAttributes).forEach((attr) => {
          if (attr in mapping) {
            currentUserRole[mapping[attr]] = updatedAttributes[attr];
          }
        });

        log("currentUserRole", currentUserRole);
        this.setUserRole(currentUserRole);

        // this.setState({ currentUserRole });
      })
      .catch((error) => {
        throw error;
      });
  };

  wrapImpersonatingFooter = (content) => {
    const { currentUserRole } = this.state;
    return (
      <div style={{ marginBottom: 60 }}>
        {content}
        <ImpersonatingFooter
          loggedInAs={`${currentUserRole.userEmail}, ${currentUserRole.roleName} - ${currentUserRole.roleTypeName}`}
          stopImpersonating={this.stopImpersonatingRole}
        />
      </div>
    );
  };

  downloadFile = (url) => {
    const newWindow = window.open("_blank");
    return Axios({
      url: url + "?" + Date.now(),
      method: "GET",
      responseType: "blob",
      headers: this.getUserRoleAuthHeaders(),
    }).then((response) => {
      newWindow.location = URL.createObjectURL(response.data);
    });
  };

  render() {
    const { loading, userRoles, currentUserRole, didCheckLocalStorage } =
      this.state;

    if (loading) {
      return <LinearProgress />;
    }

    let content;
    if (this.hasRole() || this.inAnonymousMode()) {
      const RoleProvider = this.props.roleProvider(currentUserRole);
      content = <RoleProvider>{this.props.children}</RoleProvider>;
    } else {
      if (didCheckLocalStorage) {
        content = (
          <SelectUserRole
            userRoles={userRoles}
            getAuthHeaders={this.props.getAuthHeaders}
            setUserRole={this.setUserRole}
          />
        );
      } else {
        content = <CircularProgress />;
      }
    }

    return (
      <UserRoleContext.Provider
        value={{
          userRoles: this.state.userRoles,
          trueUserRole: this.state.trueUserRole,
          currentUserRole: this.state.currentUserRole,
          fetchUserRoles: this.fetchUserRoles,
          setUserRole: this.setUserRole,
          getUserRoleAuthHeaders: this.getUserRoleAuthHeaders,
          isImpersonatingRole: this.isImpersonatingRole,
          startImpersonatingRole: this.startImpersonatingRole,
          stopImpersonatingRole: this.stopImpersonatingRole,
          removeStoredCurrentUserRoleId: this.removeStoredCurrentUserRoleId,
          updateUserProfile: this.updateUserProfile,
          downloadFile: this.downloadFile,
        }}
      >
        {this.isImpersonatingRole()
          ? this.wrapImpersonatingFooter(content)
          : content}
      </UserRoleContext.Provider>
    );
  }
}

export default withContext(AuthContext, UserRoleProvider);
