import React from "react";
import PropTypes from "prop-types";
import Axios from "axios";
import * as d3 from "d3";
import { withStyles } from "@material-ui/core/styles";
import withContext from "Lib/WithContext/withContext";
import log from "Lib/Log/log";
import { Link as RouterLink } from "react-router-dom";
import {
  IconButton,
  Tooltip,
  Typography,
  LinearProgress,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Add, Search, Clear } from "@material-ui/icons";

import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import { UserRoleContext } from "UserRoles/UserRoleProvider";
import UserModel from "../UserModel";
import TableToolbar from "Lib/TableToolbar/TableToolbar";
import SmallTablePagination from "Lib/SmallTablePagination/SmallTablePagination";

class UserTable extends React.Component {
  state = {
    users: [],
    userRolesByUserId: {},
    page: 0,
    rowsPerPage: 10,
    count: 0,
    loading: true,
    refreshing: false,
    query: "",
  };

  componentDidMount = () => {
    this.fetchUserRoles();
  };

  componentDidUpdate = (newProps) => {
    if (this.props.roleTypeId !== newProps.roleTypeId) {
      this.fetchUserRoles();
    }
  };

  fetchUserRoles = () => {
    log("fetchUserRoles");
    const { userRolesUrl, showDescendantsInfo } = this.props;
    const { rowsPerPage, page, query } = this.state;
    return Axios.get(userRolesUrl, {
      params: {
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        include_descendants: showDescendantsInfo
          ? showDescendantsInfo
          : undefined,
        query: query ? query : undefined,
      },
      headers: this.props.getUserRoleAuthHeaders(),
    }).then((response) => {
      log("fetchUserRoles response", response);

      const users = response.data.users.map((u) => UserModel.fromJSON(u));
      const userRoles = response.data.user_roles.map((uR) =>
        UserModel.fromJSON(uR),
      );

      const userRolesByUserId = this.collateUserRoleByUserId(userRoles);

      this.setState({
        users,
        userRolesByUserId,
        count: response.data.count,
        loading: false,
        refreshing: false,
      });
    });
  };

  collateUserRoleByUserId = (userRoles) => {
    const userRolesByUserId = {};

    userRoles.forEach((r) => {
      const roles = userRolesByUserId[r.userId] || [];
      roles.push(r);
      userRolesByUserId[r.userId] = roles;
    });

    return userRolesByUserId;
  };

  listItem = (user) => {
    const userRoles = this.state.userRolesByUserId[user.id];

    const roleNamesAndRoles = userRoles
      ? userRoles
          .map(
            (r) =>
              `${this.props.showDescendantsInfo ? r.roleTypeName + " - " : ""}${
                r.roleName
              }`,
          )
          .join(", ")
      : null;
    return (
      <ListItem
        key={user.id}
        button
        component={RouterLink}
        to={`/users/${user.id}`}
        name={`user-${user.id}`}
      >
        <ListItemText
          primary={`${user.lastName}, ${user.firstName}`}
          secondary={
            <>
              {user.email}
              <br />
              <small>{roleNamesAndRoles}</small>
            </>
          }
        />
      </ListItem>
    );
  };

  onSubmitSearch = (e) => {
    e.preventDefault();
    this.setState(
      { refreshing: true, loading: true, page: 0 },
      this.fetchUserRoles,
    );
  };

  onClickClear = () => {
    this.setState(
      { query: "", refreshing: true, loading: true, page: 0 },
      this.fetchUserRoles,
    );
  };

  changeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage, refreshing: true }, this.fetchUserRoles);
  };

  changePage = (page) => {
    this.setState({ page, refreshing: true }, this.fetchUserRoles);
  };

  render() {
    const { classes, showDescendantsInfo } = this.props;
    const caption = showDescendantsInfo ? (
      <>
        Users with roles at{" "}
        <span className={classes.primary}>{this.props.roleTypeName}</span> and
        its suborganizations.
      </>
    ) : (
      <>
        Users with roles at{" "}
        <span className={classes.primary}>{this.props.roleTypeName}.</span>
      </>
    );

    return (
      <Paper>
        <TableToolbar title="Users" caption={caption} />
        {this.state.loading ? <LinearProgress color="primary" /> : null}

        <form onSubmit={this.onSubmitSearch}>
          <TextField
            placeholder="Search Users..."
            value={this.state.query}
            onChange={(e) => this.setState({ query: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment className={classes.searchIcon} position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: this.state.query ? (
                <InputAdornment position="end">
                  <IconButton onClick={this.onClickClear}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            inputProps={{
              className: classes.search,
            }}
            fullWidth
          />
          <button style={{ display: "none" }} />
        </form>

        {!this.state.loading && !this.state.users.length ? (
          <Typography paragraph variant="caption" className={classes.noData}>
            No users.
          </Typography>
        ) : (
          <>
            <List style={{ opacity: this.state.refreshing ? 0.5 : 1 }}>
              {this.state.users.map((u) => this.listItem(u))}
              <Divider />
            </List>
            <SmallTablePagination
              count={this.state.count || 0}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangeRowsPerPage={(e) =>
                this.changeRowsPerPage(e.target.value)
              }
              onChangePage={(e, p) => this.changePage(p)}
              rowsPerPageOptions={[5, 10, 25, 50]}
            />
          </>
        )}
      </Paper>
    );
  }
}

UserTable.propTypes = {
  roleTypeName: PropTypes.string.isRequired,
};

UserTable.defaultProps = {
  showDescendantsInfo: false,
};

const styles = (theme) => ({
  tableWrapper: {
    overflowX: "auto",
  },
  table: {
    minWidth: 500,
  },
  noData: {
    textAlign: "center",
    padding: 25,
    color: "#777",
  },
  primary: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  search: {
    paddingTop: 10,
    paddingBottom: 14,
  },
  searchIcon: {
    marginLeft: 24,
    color: "#A7A7A7",
  },
});

export default withStyles(styles)(withContext(UserRoleContext, UserTable));
