import { withStyles } from "@material-ui/core";
import React from "react";

const footer = (props) => {
  const { classes } = props;
  return (
    <footer className={classes.footer}>
      Talentboard
      <div className={classes.copyright}>
        Copyright © {new Date().getFullYear()} Talentboard. All rights
        reserved.
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    flex: 0,
    // backgroundColor: 'rgba(0,76,69,1)',
    backgroundColor: "#fafafa",
    height: 125,
    color: "#777",
    padding: 25,
    zIndex: 1000,
    fontSize: '0.8rem'
  },
  copyright: {
    textAlign: "left",
  },
};

export default withStyles(styles)(footer);
