import { Grid, Typography } from "@material-ui/core";
import { useState, useEffect } from "react";

import Layout from "Layout/Layout";
import withContext from "Lib/WithContext/withContext";
import { OrganizationRoleContext } from "OrganizationRole/OrganizationRoleProvider";
import CandidateOpportunities from "Opportunities/CandidateOpportunities/CandidateOpportunities";
import { fetchOrganizationProfile } from "Settings/Organizations/OrganizationProfile/api";

import RecentApplicants from "./RecentApplicants";

function OrganizationDashboard({ getUserRoleAuthHeaders, organization }) {
  const [profile, setProfile] = useState(null);
  useEffect(
    () =>
      fetchOrganizationProfile(getUserRoleAuthHeaders(), organization.uid).then(
        ({ organizationProfile }) => setProfile(organizationProfile),
      ),
    [getUserRoleAuthHeaders, organization],
  );

  return (
    <Layout title="Dashboard">
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <img
            src={profile?.profileImageUrl}
            alt=""
            style={{ width: "30px", marginRight: "10px" }}
          />
          <Typography variant="h4" gutterBottom style={{ display: "inline" }}>
            {organization.name}
          </Typography>
        </Grid>

        <Grid item xs={12} md={7}>
          <Typography variant="h6" gutterBottom color="primary">
            Published Opportunities
          </Typography>

          <CandidateOpportunities
            publicOpportunities
            organizationUid={organization.uid}
            showFilters={false}
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <Typography variant="h6" gutterBottom color="primary">
            Recent Applicants
          </Typography>

          <RecentApplicants />
        </Grid>
      </Grid>
    </Layout>
  );
}

export default withContext(OrganizationRoleContext, OrganizationDashboard);
