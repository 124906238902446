import React from "react";
import Axios from "axios";
import log from "Lib/Log/log";
import AutoError from "Lib/AutoError/AutoError";
import formHelpers from "Lib/FormHelpers/formHelpers";
import qs from "qs";
import MaxWidth from "Lib/MaxWidth/MaxWidth";
import withContext from "Lib/WithContext/withContext";
import { Grid, Button, Typography, Paper, Link } from "@material-ui/core";
import { CandidateRoleContext } from "CandidateRole/CandidateRoleProvider";
import { withStyles } from "@material-ui/core/styles";
import Layout from "Layout/Layout";
import {
  Checkbox,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  TextField,
  MenuItem,
  Divider,
  Select,
} from "@material-ui/core";
import history from "history.js";
import { Redirect } from "react-router-dom";
import CandidateModel from "CandidateRole/CandidateModel";
import CandidateEducationModel from "CandidateRole/CandidateEducationModel";
import FileUpload from "Upload/FileUpload";
import { SnackbarContext } from "Lib/Snackbar/SnackbarProvider";
import EditCandidateEducation from "./EditCandidateEducation";
import PaddedPaper from "PaddedPaper/PaddedPaper";

function PersonalInformationSection({
  currentUserRole,
  onChange,
  phoneNumber,
}) {
  return (
    <>
      <Grid item sm={12}>
        <Typography variant="subtitle" color="textSecondary">
          Personal Information
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <Typography variant="h5">
          {currentUserRole.userFirstName} {currentUserRole.userLastName}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {currentUserRole.userEmail}
        </Typography>

        <FormControl margin="normal" component="fieldset" fullWidth>
          <Typography>Phone Number</Typography>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            value={phoneNumber}
            onChange={(e) =>
              onChange({ phoneNumber: e.target.value, isDirty: true })
            }
          />
        </FormControl>
      </Grid>
    </>
  );
}

function EducationSection({
  candidateEducations,
  setState,
  onChangeCandidateEducation,
}) {
  return (
    <>
      <Grid item sm={12}>
        <Typography variant="subtitle" color="textSecondary">
          Education
        </Typography>
      </Grid>
      {candidateEducations.map((ce, i) => {
        return (
          <React.Fragment key={i}>
            <EditCandidateEducation
              key={i}
              index={i}
              candidateEducation={ce}
              onChange={onChangeCandidateEducation}
            />
            <Divider
              style={{ width: "100%", marginLeft: 8, margingRight: 8 }}
            />
          </React.Fragment>
        );
      })}
      <Grid item sm={12}>
        <Button
          variant="contained"
          onClick={() =>
            setState({
              isDirty: true,
              candidateEducations: [
                ...candidateEducations,
                new CandidateEducationModel(),
              ],
            })
          }
        >
          Add another education
        </Button>
      </Grid>
    </>
  );
}

class EditCandidateProfile extends React.Component {
  constructor(props) {
    super(props);

    const { raceData, affiliationData, positionTypeData, jobRoleData } =
      props.candidate;

    console.log(props.candidate);

    this.state = {
      loading: true,
      isDirty: false,
      phoneNumber: props.candidate.phoneNumber,
      gender: props.candidate.gender,
      resume: props.candidate.resume,
      linkedIn: props.candidate.linkedIn,
      github: props.candidate.github,
      veteranStatus: props.candidate.veteranStatus,
      experience: props.candidate.experience,
      isEligilbleToWorkInUs: props.candidate.isEligilbleToWorkInUs,
      isRequireVisaSponsorship: props.candidate.isRequireVisaSponsorship,
      preferredName: props.candidate.preferredName,
      resumeName: props.candidate.resumeName || "",
      resumeFile: undefined,
      raceData: [...(raceData || [])],
      affiliationData: [...(affiliationData || [])],
      positionTypeData: [...(positionTypeData || [])],
      jobRoleData: [...(jobRoleData || [])],
      candidateEducations: props.candidateEducations.map(
        (ce) => new CandidateEducationModel(ce),
      ),
    };
  }

  componentDidMount = () => {
    // setTimeout(() => this.props.showSnackbar('error.message'), 1000)
  };

  onChange = (o) => {
    o.isDirty = true;
    this.setState(o);
  };

  onClickCancel = () => {
    if (!this.state.isDirty) {
      return history.push("/");
    }

    if (
      window.confirm(
        "You have unsaved changes. Are you sure you want to cancel?",
      )
    ) {
      history.push("/");
    }
  };

  validate = () => {
    let validations = {
      phoneNumber: {
        presence: { message: "Please enter your phone number." },
      },
      gender: {
        presence: { message: "Please select your gender." },
      },
      raceData: {
        length: {
          atLeast: 1,
          message: "Please select your race(s).",
        },
      },
      positionTypeData: {
        length: {
          atLeast: 1,
          message: "Please indicate the position type(s) you're interested in.",
        },
      },
      jobRoleData: {
        length: {
          atLeast: 1,
          message: "Please select the job role(s) you're seeking.",
        },
      },
      candidateEducations: {
        length: {
          atLeast: 1,
          message: "Please include your education history.",
        },
      },
    };

    if (!this.state.resumeName) {
      validations.resumeFile = {
        presence: { message: "Please upload your resume." },
      };
    }

    return formHelpers.validate(this.state, validations);
  };

  onClickSave = () => {
    const candidateAttributes = this.getCandidateAttributesFromState();
    const { candidateEducations, resumeFile } = this.state;

    const queryParams = qs.parse(window.location.search.slice(1));
    const opportuityUid = queryParams["opportunity-uid"];

    return this.validate()
      .then((isValid) => {
        if (!isValid) return;

        let promise = Promise.resolve();
        if (resumeFile) {
          promise = promise.then(() =>
            this.props.putCandidateResume(resumeFile),
          );
        }

        return promise
          .then(this.props.putCandidateAttributes(candidateAttributes))
          .then(this.props.putCandidateEducations(candidateEducations))
          .then(() => {
            let message = "Candidate profile updated.";
            if (opportuityUid) {
              message += " Let's complete your application!";
            }

            this.props.showSnackbar(message);

            if (opportuityUid) {
              history.push(`/opportunities/${opportuityUid}`);
            }
          });
      })
      .catch(AutoError.catch.bind(this));
  };

  onValidationError = (error) => {
    log("onValidationError", error);
    this.props.showSnackbar(error.message);
  };

  onError = (error) => {
    log("error", error);
    const { response } = error;

    let message;
    if (response.data.message) {
      message = response.data.message;
    } else {
      message = `${response.status} - ${response.statusText}`;
    }

    this.props.showSnackbar(message);
  };

  getCandidateAttributesFromState = () => {
    const { state } = this;
    return {
      phoneNumber: state.phoneNumber,
      gender: state.gender,
      resume: state.resume,
      linkedIn: state.linkedIn,
      github: state.github,
      raceData: state.raceData,
      affiliationData: state.affiliationData,
      positionTypeData: state.positionTypes,
      jobRoleData: state.jobRoleData,
      veteranStatus: state.veteranStatus,
      preferredName: state.preferredName,
      isEligilbleToWorkInUs: state.isEligilbleToWorkInUs,
      isRequireVisaSponsorship: state.isRequireVisaSponsorship,
      experience: state.experience,
    };
  };

  onChangeCandidateEducation = (candidateEducation, index) => {
    log("onChangeCandidateEducation", candidateEducation, index);
    const candidateEducations = [...this.state.candidateEducations];
    candidateEducations[index] = candidateEducation;
    this.setState({ candidateEducations });
  };

  downloadResume = () => {
    const authHeaders = this.props.getUserRoleAuthHeaders();

    const downloadFile = (url, success) => {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);

      Object.keys(authHeaders).forEach((k) => {
        xhr.setRequestHeader(k, authHeaders[k]);
      });

      xhr.responseType = "blob";
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (success) success(xhr.response);
        }
      };
      xhr.send(null);
    };

    const resumeUrl = window.location.origin + "/api/tb/candidates/resume";

    var win = window.open("_blank");

    downloadFile(resumeUrl, function (blob) {
      console.log("blob", blob);
      var url = URL.createObjectURL(blob);
      win.location = url;
    });
  };

  render() {
    const { currentUserRole, classes, theme } = this.props;

    if (!currentUserRole.isCandidateRole()) {
      return <Redirect to="/" />;
    }

    return (
      <Layout title="Candidate Profile">
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item sm={12}>
            <Typography variant="h4">Candidate Profile</Typography>
            <Typography variant="caption" gutterBottom>
              Your candidate profile is shared with companies that you apply to.
            </Typography>
          </Grid>
        </Grid>

        <PaddedPaper>
          <Grid container spacing={2}>
            <PersonalInformationSection
              currentUserRole={currentUserRole}
              phoneNumber={this.state.phoneNumber}
              onChange={this.onChange}
            />
            <EducationSection
              candidateEducations={this.state.candidateEducations}
              setState={this.setState}
              onChangeCandidateEducation={this.onChangeCandidateEducation}
            />
            <Grid item xs={12} style={{ textAlign: "center" }}>
              {this.state.resumeName && !this.state.resumeFile ? (
                // <Link
                //   onClick={this.downloadResume}
                // >
                //   {this.state.resumeName}
                // </Link>
                <Link
                  onClick={() =>
                    this.props.downloadFile("/api/tb/candidates/resume")
                  }
                >
                  {this.state.resumeName}
                </Link>
              ) : (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Resume"
                  value={
                    this.state.resumeFile
                      ? this.state.resumeFile.name
                      : this.state.resumeName || ""
                  }
                  disabled
                  helperText="Upload your resume."
                />
              )}

              <FileUpload
                onChangeFile={(resumeFile) => {
                  console.log("resumeFile", resumeFile);
                  this.setState({ resumeFile, isDirty: true });
                }}
                message={
                  this.state.resumeName && !this.state.resumeFile
                    ? "Upload a new resume"
                    : ""
                }
              />
            </Grid>
            <Grid item sm={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Position Type</FormLabel>
                <FormHelperText>Select all that apply.</FormHelperText>
                {CandidateModel.POSITION_TYPES.map((c) => (
                  <FormControlLabel
                    key={c}
                    control={
                      <Checkbox
                        checked={this.state.positionTypeData.includes(c)}
                        onChange={(e) => {
                          this.onChange({
                            positionTypeData: e.target.checked
                              ? [...this.state.positionTypeData, c]
                              : this.state.positionTypeData.filter(
                                  (r) => r !== c,
                                ),
                          });
                        }}
                        name={c}
                      />
                    }
                    label={c}
                  />
                ))}
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <Typography
                variant="subtitle"
                textColor="textSecondary"
              >
                Self Identification
              </Typography>
              <Typography variant="caption" paragraph style={{ color: "gray" }}>
                Please share with us some additional information about yourself
                (e.g. your race, ethnicity, sexual orientation, gender identity,
                and disability information). This information will be used to
                help us know more about people who use us and ensure
                representation in our efforts to provide an equal opportunity to
                everyone who wants to use Talentboard.
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <FormControl margin="normal" component="fieldset">
                <FormLabel component="legend">Race / Ethnicity</FormLabel>
                <FormHelperText>Check all that apply.</FormHelperText>
                {CandidateModel.RACES_WITHOUT_UNDISCLOSED.map((c) => (
                  <FormControlLabel
                    key={c}
                    control={
                      <Checkbox
                        checked={this.state.raceData.includes(c)}
                        onChange={(e) => {
                          const { raceData } = this.state;
                          if (!e.target.checked) {
                            return this.onChange({
                              raceData: raceData.filter((r) => r !== c),
                            });
                          }

                          let newRaceData;
                          if (
                            this.state.raceData.includes(
                              CandidateModel.RACE_UNDISCLOSED,
                            )
                          ) {
                            newRaceData = [c];
                          } else {
                            newRaceData = [...raceData, c];
                          }

                          this.onChange({ raceData: newRaceData });
                        }}
                        name={c}
                      />
                    }
                    label={c}
                  />
                ))}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.raceData.includes(
                        CandidateModel.RACE_UNDISCLOSED,
                      )}
                      onChange={(e) => {
                        this.onChange({
                          raceData: e.target.checked
                            ? [CandidateModel.RACE_UNDISCLOSED]
                            : [],
                        });
                      }}
                      name="I prefer not to say"
                    />
                  }
                  label="I prefer not to say"
                />
              </FormControl>

              <FormControl component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender"
                  value={this.state.gender}
                  onChange={(e) => this.onChange({ gender: e.target.value })}
                >
                  {CandidateModel.GENDERS.map((g) => (
                    <FormControlLabel
                      key={g}
                      value={g}
                      control={<Radio />}
                      label={g}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl margin="normal" component="fieldset" fullWidth>
                <FormLabel component="legend" color="textPrimary">
                  Veteran Status
                </FormLabel>
                <Checkbox
                  checked={this.state.veteranStatus}
                  onChange={(e) => {
                    this.onChange({
                      veteranStatus: e.target.checked,
                      isDirty: true,
                    });
                  }}
                  name={"f"}
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <Typography
                variant="subtitle"
                textColor="textSecondary"
              >
                Additional Info
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <FormControl margin="normal" component="fieldset" fullWidth>
                <FormLabel component="legend" color="textPrimary">
                  Preferred Name
                </FormLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={this.state.preferredName}
                  placeholder={`${currentUserRole.userFirstName} ${currentUserRole.userLastName}`}
                  onChange={(e) =>
                    this.onChange({ preferredName: e.target.value })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">LinkedIn Profile</FormLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={this.state.linkedIn}
                  placeholder="https://linkedin.com/in/profile"
                  onChange={(e) => this.onChange({ linkedIn: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">GitHub Profile</FormLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={this.state.github}
                  placeholder="https://github.com/profile/example"
                  onChange={(e) => this.onChange({ github: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <Divider />
              <Typography
                variant="body1"
                style={{padding: 25, color: "gray" }}
              >
                Work Authorization
              </Typography>
              <Divider />
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Are you currently eligible to work in the United States?
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Select
                    value={this.state.isEligilbleToWorkInUs}
                    onChange={(event) =>
                      this.setState({
                        isEligilbleToWorkInUs: event.target.value,
                        isDirty: true,
                      })
                    }
                  >
                    <MenuItem value={true}>
                      Yes, I am currently eligible to work in the United States
                    </MenuItem>
                    <MenuItem value={false}>
                      No, I am not currently eligible to working in the United
                      States
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <Typography>
                    Do you require visa sponsorship, now or in the future, to
                    continue working in the United States?
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Select
                    value={this.state.isRequireVisaSponsorship}
                    onChange={(event) =>
                      this.setState({
                        isRequireVisaSponsorship: event.target.value,
                        isDirty: true,
                      })
                    }
                  >
                    <MenuItem value={true}>
                      Yes, I will require visa sponsorship now or in the future
                      to continue working in the United States
                    </MenuItem>
                    <MenuItem value={false}>
                      No, I do not require visa sponsorship now or in the future
                      to continue working in the United States
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
            <Grid item sm={12}>
              <FormControl margin="normal" component="fieldset" fullWidth>
                <Typography>Experience:</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={this.state.experience}
                  multiline
                  minRows={2}
                  onChange={(e) =>
                    this.setState({ experience: e.target.value, isDirty: true })
                  }
                />
              </FormControl>
              <Divider />
            </Grid>
            <Grid item sm={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Affiliations</FormLabel>
                <FormHelperText>Select all that apply.</FormHelperText>
                {CandidateModel.AFFILIATIONS.map((c) => (
                  <FormControlLabel
                    key={c}
                    control={
                      <Checkbox
                        checked={this.state.affiliationData.includes(c)}
                        onChange={(e) => {
                          this.onChange({
                            affiliationData: e.target.checked
                              ? [...this.state.affiliationData, c]
                              : this.state.affiliationData.filter(
                                  (r) => r !== c,
                                ),
                          });
                        }}
                        name={c}
                      />
                    }
                    label={c}
                  />
                ))}
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <Typography variant="body1">Opportunity Preferences</Typography>
              <Divider />
            </Grid>
            <Grid item sm={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Job Roles</FormLabel>
                <FormHelperText>Select all that apply.</FormHelperText>
                {CandidateModel.JOB_ROLES.map((c) => (
                  <FormControlLabel
                    key={c}
                    control={
                      <Checkbox
                        checked={this.state.jobRoleData.includes(c)}
                        onChange={(e) => {
                          this.onChange({
                            jobRoleData: e.target.checked
                              ? [...this.state.jobRoleData, c]
                              : this.state.jobRoleData.filter((r) => r !== c),
                          });
                        }}
                        name={c}
                      />
                    }
                    label={c}
                  />
                ))}
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" onClick={this.onClickCancel}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.onClickSave}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </PaddedPaper>
      </Layout>
    );
  }
}

const styles = (theme) => ({
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: "center",
  },
});

export default withStyles(styles)(
  withContext(CandidateRoleContext, SnackbarContext, EditCandidateProfile),
);
