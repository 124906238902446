import { Switch, Route } from "react-router-dom";
import withContext from "Lib/WithContext/withContext";
import React from "react";
import log from "Lib/Log/log";
import { Link as RouterLink } from "react-router-dom";
import Layout from "Layout/Layout";
import { UserRoleContext } from "UserRoles/UserRoleProvider";
import {
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@material-ui/core";
import { Contacts as ContactsIcon } from "@material-ui/icons";
import DashboardHome from "./DashboardHome";
import DashboardApplications from "./DashboardApplications";
import MyCompanies from "./MyCompanies";
import { CandidateRoleContext } from "CandidateRole/CandidateRoleProvider";
import Alert from "Alert/Alert";

const tabs = [
  { pathname: "/dashboard", label: "Dashboard" },
  { pathname: "/dashboard/applications", label: "Applications" },
  { pathname: "/dashboard/my-companies", label: "My Companies" },
];

const dashboard = (props) => {
  return (
    <Layout title="Dashboard">
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h4" gutterBottom>
            Dashboard
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Welcome back Talentboard, {props.currentUserRole.userFirstName}!
            Here you'll find your applications, your recent activity, and the
            companies you follow.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {props.candidate.isProfileComplete() ? (
            <Alert info icon={ContactsIcon}>
              <Link component={RouterLink} to="/candidate-profile">
                Build your profile
              </Link>
              &nbsp;to apply to opportunities.
            </Alert>
          ) : null}
        </Grid>

        <Grid item xs={12} md={8}>
          <DashboardApplications />
        </Grid>

        <Grid item xs={12} md={4}>
          <MyCompanies />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default withContext(UserRoleContext, CandidateRoleContext, dashboard);
