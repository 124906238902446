import React from "react";
import withContext from "Lib/WithContext/withContext";
import log from "Lib/Log/log";
import { withStyles, Grid } from "@material-ui/core";
import { OrganizationRoleContext } from "OrganizationRole/OrganizationRoleProvider";
import OrganizationTable from "../OrganizationTable/OrganizationTable";
import Layout from "Layout/Layout";

class OrganizationIndex extends React.Component {
  render() {
    const { organization, classes } = this.props;

    return (
      <Layout title="Organizations">
        <Grid spacing={2} container>
          <Grid item xs={12}>
            <OrganizationTable
              organization={organization}
              showDescendantsInfo={true}
            />
          </Grid>
        </Grid>
      </Layout>
    );
  }
}

const styles = (theme) => ({});

export default withStyles(styles)(
  withContext(OrganizationRoleContext, OrganizationIndex),
);
