import React from "react";
import Axios from "axios";
import log from "Lib/Log/log";
import withContext from "Lib/WithContext/withContext";
import {
  Grid,
  Typography,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@material-ui/core";
import { CandidateRoleContext } from "CandidateRole/CandidateRoleProvider";
import { withStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import ApplicationPlanModel from "ApplicationPlans/ApplicationPlanModel";
import Layout from "Layout/Layout";
import OrganizationModel from "OrganizationRole/OrganizationModel";
import CandidateModel from "CandidateRole/CandidateModel";

import FeaturedOpportunities from "./FeaturedOpportunities";

class CandidateApplicationPlans extends React.Component {
  state = {
    loading: true,
    applicationPlans: [],
    total: 0,
    rowsPerPage: 10,
    page: 0,
    tabIndex: 0,
    query: "",
    orderBy: "name",
    activePositionType: new URL(window.location).searchParams.get(
      "positionType",
    ),
  };

  componentDidMount = () => {
    this.fetchApplicationPlans();
  };

  fetchApplicationPlans = () => {
    return Axios.get(`/api/tb/application_plans/active`, {
      params: {
        limit: this.state.rowsPerPage,
        page: this.state.page,
        query: this.state.query || undefined,
        order_by: this.state.orderBy,
        organization_uid: this.props.organizationUid || undefined,
      },
      headers:
        this.props.currentUserRole && this.props.currentUserRole.id
          ? this.props.getUserRoleAuthHeaders()
          : {},
    }).then((response) => {
      log("fetchData", response.data);

      const applicationPlans = response.data.application_plans.map((p) =>
        ApplicationPlanModel.fromJSON(p),
      );
      const total = response.data.count;

      log("applicationPlans", applicationPlans);

      const activeOrganizations = response.data.active_organizations.map((o) =>
        OrganizationModel.fromJSON(o),
      );

      const byUid = {};

      activeOrganizations.forEach((o) => {
        const uid = o.applicationPlanUid;
        byUid[uid] = byUid[uid] || [];
        byUid[uid].push(o);
      });

      if (!this.state.activePositionType) {
        const activePositionTypes = CandidateModel.POSITION_TYPES.filter(
          (positionType) =>
            applicationPlans.filter((p) => p.jobType === positionType).length,
        );
        const activePositionType = activePositionTypes.length
          ? activePositionTypes[0]
          : CandidateModel.POSITION_TYPES[0];
        const link = new URL(window.location);
        link.searchParams.set("positionType", activePositionType);
        window.history.pushState({}, "", link);
        this.setState({ activePositionType });
      }

      this.setState({
        applicationPlans,
        total,
        activeOrganizationsByApplicaitonUid: byUid,
        loading: false,
      });
    });
  };

  onSubmitSearch = (e) => {
    e.preventDefault();
    const query = e.target.querySelector("input#query").value.trim();
    this.setState({ query }, this.fetchData);
  };

  onChangeOrderBy = (e) => {
    this.setState({ orderBy: e.target.value }, this.fetchData);
  };

  render() {
    const { classes } = this.props;
    const { activeOrganizationsByApplicaitonUid } = this.state;
    const applicationPlans = this.state.applicationPlans.filter(
      (applicationPlan) =>
        applicationPlan.jobType === this.state.activePositionType,
    );

    return (
      <Layout title="">
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Typography variant="h4" gutterBottom>
              Application Plans
            </Typography>
            <Typography variant="body2" gutterBottom>
              At Talentboard, we have several upcoming application plans to
              choose from. Review each plan below and get started with the plans
              that are the best option for you.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item style={{ width: "100%", paddingLeft: 0, paddingRight: 0 }}>
            <Grid
              container
              spacing={2}
              style={{ width: "100%" }}
              justifyContent="center"
            >
              {CandidateModel.POSITION_TYPES.map((positionType) => {
                return (
                  <Grid
                    item
                    key={positionType}
                    style={{ padding: "clamp(2px, 0.9vw, 10px)" }}
                  >
                    <Button
                      className={
                        classes.positionTypeButton +
                        " " +
                        (positionType === this.state.activePositionType
                          ? classes.activePositionTypeButton
                          : "")
                      }
                      onClick={() => {
                        const link = new URL(window.location);
                        link.searchParams.set("positionType", positionType);
                        window.history.pushState({}, "", link);
                        this.setState({
                          activePositionType: positionType,
                          loading: true,
                          page: 0,
                        });
                      }}
                    >
                      {positionType === this.state.activePositionType ? (
                        <>
                          <div
                            className={
                              classes.activePositionTypeCircle +
                              " " +
                              classes.activePositionTypeInnerCircle
                            }
                          ></div>
                          <div
                            className={
                              classes.activePositionTypeCircle +
                              " " +
                              classes.activePositionTypeOuterCircle
                            }
                          ></div>
                        </>
                      ) : null}
                      {positionType}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Grid container>
                  {applicationPlans.length ? (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h5">Application Plans</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Paper>
                          <List>
                            {applicationPlans.map((p, i) => (
                              <React.Fragment key={i}>
                                <ListItem
                                  button
                                  component={RouterLink}
                                  to={`/application_plans/${p.uid}`}
                                >
                                  <ListItemText
                                    gu
                                    primary={
                                      <>
                                        <Typography variant="h5" gutterBottom>
                                          {p.name}
                                        </Typography>
                                        <Typography gutterBottom>
                                          <strong>{p.windowDescription}</strong>{" "}
                                          -&nbsp;
                                          {p.startedAtFormatted()} to&nbsp;
                                          {p.endedAtFormatted()}
                                        </Typography>
                                      </>
                                    }
                                    secondary={
                                      <>
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                          gutterBottom
                                        >
                                          {p.description}
                                        </Typography>

                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                          gutterBottom
                                        >
                                          {activeOrganizationsByApplicaitonUid[
                                            p.uid
                                          ]
                                            .slice(0, 3)
                                            .map((o) => o.organizationName)
                                            .join(", ")}
                                        </Typography>

                                        <Typography
                                          variant="caption"
                                          color="primary"
                                          gutterBottom
                                        >
                                          {p.assessmentDescription ? (
                                            <>
                                              Requires {p.assessmentDescription}
                                            </>
                                          ) : null}
                                        </Typography>
                                      </>
                                    }
                                    disableTypography
                                  />
                                </ListItem>
                               { i + 1 < applicationPlans.length && <Divider /> }
                              </React.Fragment>
                            ))}
                          </List>
                        </Paper>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <Grid
                        container
                        style={{ width: "100%" }}
                        justifyContent="center"
                      >
                        <Grid item style={{ width: "100%" }}>
                          <Typography
                            variant="body2"
                            className={classes.noData}
                          >
                            There are no application plans here yet
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} md={4}>
                <FeaturedOpportunities
                  currentUserRole={this.props.currentUserRole}
                  getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
                  applicationPlans={applicationPlans}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    );
  }
}

const styles = (theme) => ({
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: "center",
  },
  noOpportunities: {
    padding: 50,
    backgroundColor: "#f9f9f9",
    borderRadius: 5,
  },
  positionTypeButton: {
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: theme.palette.primary.main,
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding:
      "min(2.3vw, 24px) clamp(10px, 2.3vw, 24px) min(2.3vw, 24px) clamp(10px, 2.3vw, 24px)",
    "& span": {
      width: "112px",
    },
  },
  activePositionTypeButton: {
    backgroundColor: "#C2D7D1",
  },
  activePositionTypeCircle: {
    content: '""',
    position: "absolute",
    borderRadius: "50%",
    background: theme.palette.primary.main,
  },
  activePositionTypeInnerCircle: {
    width: "9px",
    height: "9px",
    top: "calc(9px/ -2)",
    left: "calc(9px / -2)",
  },
  activePositionTypeOuterCircle: {
    width: "18px",
    height: "18px",
    top: "calc(18px / -2)",
    left: "calc(18px / -2)",
    animation: "$hotspot-expand 2s infinite",
  },
  "@keyframes hotspot-expand": {
    "0%": { transform: "scale(.5)", opacity: 1 },
    "100%": { transform: "scale(1.5)", opacity: 0 },
  },
  noData: {
    padding: 50,
    backgroundColor: "#f9f9f9",
    borderRadius: 5,
    textAlign: "center",
  },
});

CandidateApplicationPlans.defaultProps = {
  showFilters: true,
};

export default withStyles(styles)(
  withContext(CandidateRoleContext, CandidateApplicationPlans),
);
