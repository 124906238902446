import React from "react";
import { List, ListItem, ListItemText } from "@material-ui/core";

const userRoleList = (props) => (
  <List>
    {props.userRoles.map((r) => (
      <ListItem button key={r.id} onClick={() => props.setUserRole(r)}>
        <ListItemText primary={r.roleTypeName} secondary={r.roleName} />
      </ListItem>
    ))}
  </List>
);

export default userRoleList;
